import IconButton from "@material-ui/core/IconButton";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import { useDispatch } from "react-redux";

import { deleteFromCart, addRemoveQuantity } from "../../../redux/actions/Products";
import { Image } from "../../../shared/atoms";

const CartItem = ({ image, title, price, isCheckout, quantity, item, description, currencySymbol, taxes }) => {
  const dispatch = useDispatch();

  const deleteItem = (item, event) => {
    dispatch(deleteFromCart(item));
  };

  const addRemoveItem = (item, event, operation) => {
    dispatch(addRemoveQuantity({ item, operation }));
  };
  return (
    <div className="item-cart">
      <div className="item-image-title">
        <div className="image-wrap">
          <Image
            {...image}
            lazyProps={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <div style={{ width: "65%" }}>
          <div className="item-title-cart">{title}</div>
          <div className="item-description-cart">{description}</div>
        </div>
      </div>

      <div className="item-price-button item">
        <div className="item-cart-price">
          {currencySymbol}
          {price.toFixed(2)}
        </div>
        <div className="wrap-add-remove-delete">
          {isCheckout ? (
            <div className="item-quantity-checkout">{quantity}</div>
          ) : (
            <div className={description ? "item-quantity-none" : "item-quantity"}>
              <div>
                {quantity !== 1 ? (
                  <IconButton component="div" className="add-remove-item" onClick={(event) => addRemoveItem(item, event, "remove")}>
                    <RemoveOutlinedIcon />
                  </IconButton>
                ) : (
                  <div className="add-remove-item-empty" />
                )}
              </div>
              <div>{quantity}</div>
              <div>
                <IconButton component="div" className="add-remove-item" onClick={(event) => addRemoveItem(item, event, "add")}>
                  <AddOutlinedIcon />
                </IconButton>
              </div>
            </div>
          )}

          {isCheckout ? null : (
            <IconButton color="secondary" className="delete-button" component="div" onClick={(event) => deleteItem(item, event)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          )}
        </div>
        {taxes ? (
          <div className="total-taxes-sum">
            <span>Taxes:</span>
            <span> ${parseFloat(taxes).toFixed(2)}</span>
          </div>
        ) : null}
        <div className="total-item-sum">
          <span>Total:</span>
          <span>
            {" "}
            {currencySymbol}
            {taxes ? parseFloat(quantity * price + +taxes).toFixed(2) : parseFloat(quantity * price).toFixed(2)}
          </span>
        </div>

        <div className="total-item-sum-addiction" style={{ fontSize: 12 }}>
          {taxes ? null : "(without tax)"}
        </div>
      </div>
    </div>
  );
};
export default CartItem;

export const POST_PAYMENT = "POST_PAYMENT";

export const SUCCESS_PAYMENT_SUCCESS = "SUCCESS_PAYMENT_SUCCESS";
export const SUCCESS_PAYMENT_FAILED = "SUCCESS_PAYMENT_FAILED";

export const FAILED_PAYMENT_SUCCESS = "FAILED_PAYMENT_SUCCESS";
export const FAILED_PAYMENT_FAILED = "FAILED_PAYMENT_FAILED";

export const POST_PAYMENT_SUCCESS = "POST_PAYMENT_SUCCESS";
export const POST_PAYMENT_FAILED = "POST_PAYMENT_FAILED";

export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHOD_FAILED = "GET_PAYMENT_METHOD_FAILED";

export const REMOVE_PAYMENT_METHOD = "REMOVE_PAYMENT_METHOD";
export const REMOVE_PAYMENT_METHOD_SUCCESS = "REMOVE_PAYMENT_METHOD_SUCCESS";
export const REMOVE_PAYMENT_METHOD_FAILED = "REMOVE_PAYMENT_METHOD_FAILED";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_FAILED = "UPDATE_PAYMENT_METHOD_FAILED";

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";
export const ADD_PAYMENT_METHOD_FAILED = "ADD_PAYMENT_METHOD_FAILED";

export const CHECK_TAXES = "CHECK_TAXES";
export const CHECK_TAXES_SUCCESS = "CHECK_TAXES_SUCCESS";
export const CHECK_TAXES_FAILED = "CHECK_TAXES_FAILED";

import { useEffect, useState } from "react";

import { useMediaQuery, Grid, Typography, TextField, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import validate from "validate.js";

import { handleFormState, handleFormStateUseEffect } from "../../../helpers/HandleFormState";
import { changePassword } from "../../../redux/actions/Account";
import { schema } from "./validationSchema";
import "./Security.css";

const Security = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
  };

  const handleSave = () => {
    formState.isValid && dispatch(changePassword(formState.values))
  };

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textPrimary" className="input-title-security">
            New password
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="New password"
            variant="outlined"
            size="medium"
            name="newPassword"
            fullWidth
            helperText={hasError("newPassword") ? formState.errors.newPassword[0] : null}
            error={hasError("newPassword")}
            onChange={handleChange}
            type="password"
            value={formState.values.newPassword || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textPrimary" className="input-title-security">
            Repeat password
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Repeat password"
            variant="outlined"
            size="medium"
            name="repeatPassword"
            fullWidth
            helperText={hasError("repeatPassword") ? formState.errors.repeatPassword[0] : null}
            error={hasError("repeatPassword")}
            onChange={handleChange}
            type="password"
            value={formState.values.repeatPassword || ""}
          />
        </Grid>
        <Grid item container justify="space-between" xs={12}>
          <Button type="submit" color="secondary" size="large" onClick={() => history.push("/account/settings")}>
            cancel
          </Button>
          <Button variant="contained" type="submit" color="primary" size="large" onClick={handleSave}>
            save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Security;

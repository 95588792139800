import { useState } from "react";

import { useMediaQuery, Grid, Button, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./AccountSettings.css";
import {
  getAccountBalance,
  getAccountPlanCancelStatus,
  getAccountPlans,
  getAccountSettingsInfo,
  getCurrencyAccountSymbol,
  getEventLog,
  getIsOpenModal,
} from "../../../helpers/Selector";
import visa from "../../../images/visa.png";
import {
  closeModal,
  getRates,
} from "../../../redux/actions/Account";
import LoaderComponent from "../../../shared/LoaderComponent/LoaderComponent";
import ModalWindow from "../../../shared/ModalWindow/ModalWindow";
import TableBillingInvoice from "../BillingInvoice/TableBillingInvoice";
import Notifications from "../Notifications/Notifications";

const AccountSettings = () => {
  const accountSymbol = useSelector(getCurrencyAccountSymbol)
  const accountSettingsInfo = useSelector(getAccountSettingsInfo);
  const accountPlanCancelStatus = useSelector(getAccountPlanCancelStatus);
  const isOpenModal = useSelector(getIsOpenModal);
  const allAccountPlans = useSelector(getAccountPlans);
  const accountBalance = useSelector(getAccountBalance);
  const eventLogs = useSelector(getEventLog);
  const stop = useSelector((state) => state.account.cancelPlanAccount.stop);
  const history = useHistory();

  const dispatch = useDispatch();

  const [preCancelPlan, setPreCancelPlan] = useState(0);
  const [isOpenNotifications, setIsOpenNotification] = useState(false);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const onCancelPlan = (planNo) => {
    dispatch(closeModal(true));
    // setTimeout(() => {
    setPreCancelPlan(planNo);
    // }, 2000);
  };

  const handleChangePlan = (
    planNo,
    instanceNo,
    currentRate,
    currency,
    supplementalIndex
  ) => {
    dispatch(
      getRates(
        planNo,
        currency,
        supplementalIndex,
        instanceNo,
        currentRate,
        history
      )
    );
  };

  const handleClickRedirect = (path) => {
    history.push(path);
  };

  const handleManageNotifications = () => {
    if (isOpenNotifications) {
      setIsOpenNotification(false);
    } else {
      // dispatch(getEventLogs());
      setIsOpenNotification(true);
    }
  };
  let updatedEventLogs;
  if (eventLogs && eventLogs.length) {
    updatedEventLogs = eventLogs.slice();
    updatedEventLogs.splice(5);
  }

  const message = "Are you sure you want to cancel this plan?";
  return (
    <div className="container">
      {isOpenModal ? (
        <ModalWindow
          status="statusAsk"
          message={message}
          cancelPlan={preCancelPlan}
        />
      ) : null}
      {accountPlanCancelStatus.status && !isOpenModal ? (
        <ModalWindow
          message={accountPlanCancelStatus.message}
          status={accountPlanCancelStatus.status}
        />
      ) : null}
      {accountSettingsInfo ? (
        <Grid container spacing={isMd ? 4 : 2} className="container">
          <div className="wrap-blocks">
            <div className="wrap-block first">
              <div className="name-block">
                <div>membership & billing</div>
                <div></div>
              </div>
              <div className="wrap-acc-content-block">
                <div className="acc">
                  <div className="acc-title">Total Balance</div>
                  {accountBalance && accountBalance.acct_balance ? (
                    <div className="payment-price">
                      {accountSymbol}{parseInt(accountBalance.acct_balance, 10).toFixed(2)}
                    </div>
                  ) : null}
                  {allAccountPlans && allAccountPlans.statement ? (
                    <div className="acc-due">
                      Due:{" "}
                      {moment(allAccountPlans.statement.due_date).format(
                        "MMMM Do YYYY"
                      )}
                    </div>
                  ) : null}

                  <Button
                    color="primary"
                    variant="contained"
                    style={{ width: "100%" }}
                  >
                    Pay Now
                  </Button>
                </div>
                <div className="content-block">
                  <div className="wrap-options">
                    <div className="option">
                      <div className="value">{accountSettingsInfo.email}</div>
                      <Button
                        color="primary"
                        onClick={() => handleClickRedirect("/account/email")}
                      >
                        Change email
                      </Button>
                    </div>
                    <div className="option">
                      <div></div>
                      <Button
                        color="primary"
                        onClick={() => handleClickRedirect("/account/security")}
                      >
                        Change password
                      </Button>
                    </div>
                    <div className="option">
                      <div></div>
                      <Button
                        color="primary"
                        onClick={() => handleClickRedirect("/account/general")}
                      >
                        Personal Information
                      </Button>
                    </div>
                  </div>
                  <div className="wrap-options border">
                    <div className="option">
                      <div className="wrap-card-data-option">
                        {accountSettingsInfo.account_payment_methods ? (
                          <div>
                            <div
                              style={{ backgroundImage: `url(${visa})` }}
                              className="image-billing-invoice"
                            ></div>
                            <div>
                              •••• •••• ••••{" "}
                              {
                                accountSettingsInfo.account_payment_methods[0]
                                  .suffix
                              }
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <Button
                        color="primary"
                        onClick={() =>
                          handleClickRedirect("/account/paymentInfo")
                        }
                      >
                        Manage payment Info
                      </Button>
                    </div>
                    <div className="option">
                      <div></div>
                      <Button
                        color="primary"
                        onClick={() =>
                          handleClickRedirect("/account/billing-invoice")
                        }
                      >
                        Billing and Invoice History
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="wrap-block">
              <div className="name-block">
                <div>plan details</div>
              </div>
              <div className="content-block">
                <div className="wrap-options">
                  {accountSettingsInfo.master_plans_info
                    ? accountSettingsInfo.master_plans_info.map((item, idx) => (
                        <div className="option" key={idx}>
                          <div className="wrap-plans-option">
                            <div className="wrap-plan-pulse">
                              <div className="plan-name-option">
                                {item.plan_name}
                              </div>
                              <div className="wrap-rate-option-circles">
                                <div className="rate-name-option">
                                  {item.rate_schedule_name}
                                </div>
                                {item.plan_instance_status_label === "Active" ||
                                item.plan_instance_status_label === "Trial" ||
                                item.plan_instance_status_label ===
                                  "Active Non-Billable" ? (
                                  <Tooltip
                                    title={item.plan_instance_status_label}
                                  >
                                    <div
                                      className={
                                        preCancelPlan ===
                                          item.plan_instance_no && stop
                                          ? "circle pulse green"
                                          : "circle green"
                                      }
                                    ></div>
                                  </Tooltip>
                                ) : item.plan_instance_status_label.includes(
                                    "Pending"
                                  ) ? (
                                  <Tooltip
                                    title={item.plan_instance_status_label}
                                  >
                                    <div
                                      className={
                                        preCancelPlan ===
                                          item.plan_instance_no && stop
                                          ? "circle pulse yellow"
                                          : "circle yellow"
                                      }
                                    ></div>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title={item.plan_instance_status_label}
                                  >
                                    <div
                                      className={
                                        preCancelPlan ===
                                          item.plan_instance_no && stop
                                          ? "circle pulse red"
                                          : "circle red"
                                      }
                                    ></div>
                                  </Tooltip>
                                )}
                              </div>
                            </div>

                            <div className="wrap-button-plan-option">
                              <Button
                                disabled={
                                  item.plan_instance_status_label ===
                                    "Cancelled" ||
                                  item.plan_instance_status_label.includes(
                                    "Pending"
                                  )
                                    ? true
                                    : false
                                }
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  onCancelPlan(item.plan_instance_no)
                                }
                              >
                                Cancel plan
                              </Button>
                            </div>
                          </div>
                          <Button
                            color="primary"
                            className="link-text"
                            onClick={() =>
                              handleChangePlan(
                                item.plan_no,
                                item.plan_instance_no,
                                item.rate_schedule_no,
                                item.currency_cd,
                                item.supp_plan_ind
                              )
                            }
                          >
                            Change plan
                          </Button>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>

            <div className="wrap-block">
              <div className="name-block">
                <div>linked accounts</div>
              </div>
              <div className="option">
                <div>No Linked Accounts</div>
                <div></div>
              </div>
            </div>

            <div className="wrap-block">
              <div className="name-block">
                <div>Notifications</div>
              </div>
              <div className="wrap-options">
                <div className="option">
                  <div></div>
                  <Button color="primary" onClick={handleManageNotifications}>
                    {isOpenNotifications ? "show less" : "show more"}
                  </Button>
                </div>
              </div>
            </div>
            {eventLogs && eventLogs.length && !isOpenNotifications ? (
              <TableBillingInvoice
                accountSymbol = {accountSymbol}
                history={updatedEventLogs}
                eventLog={true}
                notification={true}
              />
            ) : (
              <Notifications />
            )}
            {/* {isOpenNotifications ? <Notifications /> : null} */}
          </div>
        </Grid>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};

export default AccountSettings;

import { useState } from "react";

import { Button, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";

import { getAllRates, getCurrencyAccountSymbol, getIsOpenModal, getStatusChangePlan } from "../../../helpers/Selector";
import { closeModal } from "../../../redux/actions/Account";
import ModalWindow from "../../../shared/ModalWindow/ModalWindow";
import CardJobMinimal from "../../../shared/organisms/CardJobMinimal/CardJobMinimal";

import "./ChangePlan.css";

const ChangePlanAccount = () => {
  const accountSymbol = useSelector(getCurrencyAccountSymbol)
  const rates = useSelector(getAllRates);
  const isOpenModal = useSelector(getIsOpenModal);
  const statusChangePlan = useSelector(getStatusChangePlan);
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentRate, instanceNo } = history.location.state;
  const [nameRate, setNameRate] = useState("");
  const [scheduleForChange, setScheduleForChange] = useState("");

  const handleChangePlan = (schedule, name) => {
    setNameRate(name);
    setScheduleForChange(+schedule);

    dispatch(closeModal(true));
  };

  const message = `Please confirm you want to change your existing plan to ${nameRate}`;

  return (
    <div className="wrap-change-plan-account">
      {statusChangePlan.status ? (
        <ModalWindow status={statusChangePlan.status} message={statusChangePlan.message} path={"/account/settings"} />
      ) : null}

      {isOpenModal ? (
        <ModalWindow
          status="statusAskChangePlan"
          message={message}
          path={"/account/settings"}
          plan_instance_no={instanceNo}
          schedule_no={scheduleForChange}
        />
      ) : null}

      {rates ? (
        <Grid container>
          <div className="wrap-title-header-change-plan">
            <div className="categories-plans-title">{rates.plan_name}</div>
            <div className="categories-plans-description">{rates.description}</div>
          </div>

          <div className="wrap-plan-list-account">
            {rates.rate_sched.details.map((item, idx) => (
              <div className="plan-list-account" key={idx}>
                <CardJobMinimal
                  onClick={() => handleChangePlan(item.schedule_no, item.schedule_name)}
                  title={
                    <div className="cart-job-title-wrap-change-rate">
                      {currentRate === item.schedule_no ? (
                        <div className="cart-job-title-change-rate">
                          <span className="cart-job-text-current-change-rate">Current rate: </span>
                          {item.schedule_name}
                        </div>
                      ) : (
                        <div className="cart-job-title-change-rate">{item.schedule_name}</div>
                      )}
                    </div>
                  }
                  subtitle={<div className="wrap-subtitle-plan-account cart-job-subtitle-change-rate">{`${accountSymbol}${item.amount}/month`}</div>}
                  showArrow
                />
              </div>
            ))}
          </div>
        </Grid>
      ) : (
        <Redirect to="/account/settings" />
      )}
      <Grid item container justify="space-between" xs={12}>
        <Button type="submit" color="secondary" size="large" onClick={() => history.push("/account/settings")}>
          back
        </Button>
      </Grid>
    </div>
  );
};
export default ChangePlanAccount;

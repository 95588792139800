export const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 32,
    },
    format: {
      pattern: /^[a-z ,.'-]+$/i,
    },
  },
  middleName: {
    // presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 0,
      maximum: 2,
    },
    format: {
      pattern: /^[a-z ,.'-]+$|^$/i,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 32,
    },
    format: {
      pattern: /^[a-z ,.'-]+$/i,
    },
  },
  streetAddress: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 300,
    },
  },
  addressSecond: {
    // presence: { allowEmpty: false, message: "is required" },
    // length: {
    //   minimum: 10,
    // },
  },
  addressThird: {
    // presence: { allowEmpty: false, message: "is required" },
    // length: {
    //   minimum: 10,
    // },
  },
  city: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 32,
    },
    format: {
      pattern: "^[a-zA-Z]+(?:(?:\\s+|-)[a-zA-Z]+)*$",
    },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
    },
  },
  zipCode: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 5,
      maximum: 15,
    },
    format: {
      pattern: "^[0-9]{5}(?:-[0-9]{4})?$",
    },
  },
  stateProvince: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 30,
    },
  },
  companyName: {
    // presence: { allowEmpty: false, message: "is required" },
    // length: {
    //   minimum: 3,
    // },
  },
  phoneNumber: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 9,
      maximum: 15,
    },
    format: {
      pattern: /^[0-9\-\+]{9,15}$/,
    },
  },
  phoneExt: {
    length: {
      maximum: 10,
    },
  },
  phoneNumberCell: {
    // presence: { allowEmpty: false, message: "is required" },
    // length: {
    //   minimum: 9,
    // },
    format: {
      pattern: /^[0-9\-\+]{9,15}|^$/,
    },
  },
  phoneNumberWork: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 9,
      maximum: 15,
    },
    format: {
      pattern: /^[0-9\-\+]{9,15}$/,
    },
  },

  phoneWorkExt: {
    // presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 10,
    },
  },
};

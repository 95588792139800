import axios from "axios";

import { urlBackend } from "../url/url";

export async function callRegister(
  method,
  path,
  data,
  url = urlBackend.apiUser
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    credentials: "same-origin",
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callRegisterSpecial(
  method,
  path,
  data,
  header,
  url = urlBackend.apiUser
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      "aria-token": header,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    credentials: "same-origin",
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callEventLogs(
  method,
  path,
  data,
  header,
  url = urlBackend.apiEventLogs
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      "aria-token": header,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    credentials: "same-origin",
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callLogout(
  method,
  path,
  data,
  header,
  url = urlBackend.apiUser
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      "aria-token": header,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callProfile(
  method,
  path,
  data,
  header,
  url = urlBackend.apiProfile
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      "aria-token": header,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callPlan(
  method,
  path,
  data,
  header,
  url = urlBackend.apiPlan
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      "aria-token": header,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callPayment(
  method,
  path,
  data,
  url = urlBackend.apiPayment
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    credentials: "same-origin",
    data,
  });
  return await response;
}

export async function callGetPaymentMethod(
  method,
  path,
  data,
  header,
  url = urlBackend.apiBothPayment
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      "aria-token": header,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callBothPayment(
  method,
  path,
  data,
  url = urlBackend.apiBothPayment
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    credentials: "same-origin",
    withCredentials: true,
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callTest(method, path, data, url = urlBackend.apitest) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callProfileChangeInfo(
  method,
  path,
  data,
  header,
  url = urlBackend.apiProfile
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      "aria-token": header,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    credentials: "same-origin",
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callPlanGroups(
  method,
  path,
  data,
  url = urlBackend.apiGroups
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callBillingInvoice(
  method,
  path,
  data,
  header,
  url = urlBackend.apiBillingInvoice
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      "aria-token": header,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    credentials: "same-origin",
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callAllAccountPlans(
  method,
  path,
  data,
  header,
  url = urlBackend.apiAccountPlans
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      "aria-token": header,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    credentials: "same-origin",
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

export async function callConfigure(
  method,
  path,
  data,
  url = urlBackend.apiConfigure
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}
export async function callConfigureImages(
  method,
  path,
  data,
  url = urlBackend.apiConfigure
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return await response;
}

export async function callBasicInfo(
  method,
  path,
  data,
  url = urlBackend.apiBasicInfo
) {
  const response = await axios(`${url}/${path}`, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Header": "*",
    },
    data: !(method === "GET") ? JSON.stringify(data) : null,
  });
  return await response;
}

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        background: theme.palette.primary.dark,
    },
    textWhite: {
        color: 'white',
        paddingLeft: 65,
        '@media (max-width:540px)': {
            fontSize: 16,
            paddingLeft: 20,
        },
    },
    title: {
        fontWeight: 'bold',
        '@media (max-width:540px)': {
            fontSize: 30,
        },
    },
    sectionWrap: {
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

import { useEffect, useState } from "react";

import { Grid, Button, TextField } from "@material-ui/core";
import validate from "validate.js";

import { handleFormState, handleFormStateUseEffect } from "../../../../helpers/HandleFormState";
import { schema } from "./validationSchema";
import "./Form.css";

const Form = () => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      window.location.replace("/");
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div className="root-reset-password">
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className="height-for-valid"
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError("email") ? formState.errors.email[0] : null}
              error={hasError("email")}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Button size="large" variant="contained" type="submit" color="primary" fullWidth>
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;

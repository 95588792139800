import AccountSignInSignUp from "../StepperData/Account/AccountSignInSignUp";
import PaymentDetails from "../StepperData/PaymentDetails/PaymentDetails";
import ReviewOrder from "../StepperData/ReviewOrder/ReviewOrder";
import ShippingAddress from "../StepperData/ShippingAddress/ShippingAddress";

export function getSteps() {
  return ["Account", "Shipping Address", "Billing / Payment", "Order complete"];
}

export function getStepContent(
  step,
  handleBack,
  handleNext,
  handleFinish,
  errorMessageRegister,
  errorMessageLogin,
  authorizedUser
) {
  switch (step) {
    case 0 && !authorizedUser:
      return (
        <AccountSignInSignUp
          onNext={handleNext}
          errorMessageRegister={errorMessageRegister}
          errorMessageLogin={errorMessageLogin}
        />
      );
    case 1:
      return <ShippingAddress onNext={handleNext} onBack={handleBack} />;
    case 2:
      return <PaymentDetails onNext={handleNext} onBack={handleBack} />;
    case 3:
      return <ReviewOrder onFinish={handleFinish} onBack={handleBack} />;
    default:
      return "Unknown step";
  }
}

import { useEffect, useState } from "react";

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "./PaymentDetails.css";
import { useHistory } from "react-router";
import validate from "validate.js";

import { handleFormStateUseEffect } from "../../../../helpers/HandleFormState";
import {
  getBillingMethod,
  getEmailRegister,
  getLoggedPaymentSuccess,
  getLoginUserInfo,
  getPaymentInfo,
} from "../../../../helpers/Selector";
import amex from "../../../../images/amex.png";
import discover from "../../../../images/discover.png";
import mastercard from "../../../../images/mastercard.png";
import visa from "../../../../images/visa.png";
import { loggedPlaceOrder } from "../../../../redux/actions/Checkout";
import { checkTaxes } from "../../../../redux/actions/Payment";
import LoaderComponent from "../../../../shared/LoaderComponent/LoaderComponent";
import ModalWindow from "../../../../shared/ModalWindow/ModalWindow";
import CardJobMinimal from "../../../../shared/organisms/CardJobMinimal/CardJobMinimal";
import FormPaymentDetails from "./Form/Form";
import { schema } from "./Form/validationSchema";

const cardsData = [amex, discover, mastercard, visa];

const PaymentDetails = ({ onNext, onBack }) => {
  const billingMethod = useSelector(getBillingMethod);
  const cardInfo = useSelector(getPaymentInfo);
  const { email, session_id } = useSelector(getLoginUserInfo);
  const paymentSuccess = useSelector(getLoggedPaymentSuccess);
  const emailPreRegister = useSelector(getEmailRegister);
  const history = useHistory();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [value, setValue] = useState(
    session_id && cardInfo && cardInfo.payment_methods_summary
      ? +cardInfo.payment_methods_summary[0].payment_method_no
      : ""
  );
  const [month, setMonth] = useState(1);
  const [date, setDate] = useState(2021);
  const [isAddCard, setIsAddCard] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  useEffect(() => {
    if (isOpenSelect && value) {
      const currentBilling = cardInfo.billing_group_details.find(
        (item) => item.primary_payment_method_no === value
      );
      if (currentBilling) {
        dispatch(
          checkTaxes({
            payMethod: "Billing",
            billing_group_no: currentBilling.billing_group_no,
          })
        );
      }
    }
    if (isAddCard && session_id) {
      dispatch(checkTaxes({ payMethod: "Direct" }));
    }
  }, [isOpenSelect, isAddCard, value]);

  const handleChange = (event) => {
    setValue(+event.target.value);
  };
  const handleNext = () => {
    if (session_id && isOpenSelect) {
      const currentBilling = cardInfo.billing_group_details.find(
        (item) => item.primary_payment_method_no === value
      );
      if (currentBilling) {
        dispatch(
          loggedPlaceOrder(
            {
              payment_method_no: value,
              payMethod: "Billing",
              billing_group_no: currentBilling.billing_group_no,
            },
            history
          )
        );
        setIsOpenError(false);
      } else {
        setIsOpenError(true);
      }
    } else if (session_id && isAddCard) {
      dispatch(
        loggedPlaceOrder(
          {
            payment_method_no: value,
            payMethod: "Direct",
          },
          history
        )
      );
    } else {
      onNext("payment", { ...formState, month, date });
    }
  };
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;
  return (
    <div className="wrap-payment-details">
      {paymentSuccess.status && paymentSuccess.status !== 200 ? (
        <ModalWindow
          status={paymentSuccess.status}
          message={paymentSuccess.message}
        />
      ) : null}
      {(session_id && cardInfo) || emailPreRegister ? (
        <>
          <div className="billing-title-text">Billing Address</div>
          <div className="wrap-data-credentials">
            <div>
              {billingMethod.firstName} {billingMethod.lastName}
            </div>
            <div>
              {billingMethod.streetAddress} {billingMethod.zipCode}
            </div>
            <div>{emailPreRegister || email}</div>
            <div>{billingMethod.phoneNumber}</div>
            <div className="edit-button" onClick={onBack}>
              Edit
            </div>
          </div>

          <div className="wrap-almost-there-credit-cards">
            <div className="almost-there-wrap">
              Almost there, how would you like to pay?
            </div>
            <CardJobMinimal
              title={"CREDIT"}
              onClick={() => {
                setIsOpenSelect(!isOpenSelect);
                setIsAddCard(false);
              }}
              subtitle={
                <div className="card-wrap-images">
                  {cardsData.map((item, idx) => (
                    <div
                      key={idx}
                      style={{ backgroundImage: `url(${item})` }}
                      className={"image-payment"}
                    ></div>
                  ))}
                </div>
              }
              showArrow
            />
            {session_id && cardInfo.payment_methods_summary && isOpenSelect ? (
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="payment"
                    name="payment"
                    value={+value}
                    onChange={handleChange}
                  >
                    {cardInfo.payment_methods_summary.map((item) => (
                      <FormControlLabel
                        key={item.payment_method_no}
                        value={+item.payment_method_no}
                        control={<Radio />}
                        label={
                          <div className="wrap-card-data-select-payment">
                            <div
                              style={{ backgroundImage: `url(${visa})` }}
                              className="image-billing-invoice"
                            ></div>
                            <div>•••• •••• •••• {item.suffix}</div>
                          </div>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            ) : isAddCard ? (
              <FormPaymentDetails
                onNext={onNext}
                setFormState={setFormState}
                formState={formState}
                month={month}
                setMonth={setMonth}
                date={date}
                setDate={setDate}
                hasError={hasError}
              />
            ) : null}
            {isOpenSelect || isAddCard ? (
              <Button
                variant={isAddCard ? "text" : "contained"}
                color={isAddCard ? "secondary" : "primary"}
                style={{ marginTop: 20 }}
                onClick={() => {
                  setIsAddCard(!isAddCard);
                  setIsOpenSelect(!isOpenSelect);
                }}
              >
                {isAddCard ? "cancel" : "Add New"}
              </Button>
            ) : null}
          </div>

          <div className="wrap-buttons">
            {isOpenError ? (
              <div className="text-error">
                Something went wrong. Try to choose another cart or add new
              </div>
            ) : null}
            <Button variant="contained" color="primary" onClick={handleNext}>
              Place Order
            </Button>
          </div>
        </>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};
export default PaymentDetails;

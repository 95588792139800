import { useState } from "react";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import SearchIcon from "@material-ui/icons/Search";

import FilterContent from "./FilterContent/FilterContent";

export default function Filter() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button aria-describedby={id} color="primary" onClick={handleClick}>
        <SearchIcon /> Filter
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FilterContent onClose={handleClose} />
      </Popover>
    </div>
  );
}

import { createGlobalStyle } from "styled-components";

import { api } from "./service/url/url";

const GlobalStyle = createGlobalStyle`
  div {
      color: ${(props) => props.styles.font_color}
  }
  li {
      color: ${(props) => props.styles.font_color} !important
  }
  .makeStyles-title-54 {
    color: ${(props) => props.styles.font_color} !important
  }
  .MuiInputBase-input {
    color: ${(props) => props.styles.font_color} !important
  }
  .MuiStepLabel-label {
    color: ${(props) => props.styles.font_color} !important
  }
  .MuiStepLabel-active {
    color: ${(props) => props.styles.font_color} !important
  }
  .MuiStepLabel-label {
    .MuiStepLabel-active {
        color: ${(props) => props.styles.font_color} !important
      }
  }
  .item-title-cart {
    color: ${(props) => props.styles.font_color}
  }
  .shipping-title-text {
    color: ${(props) => props.styles.font_color}
  }
  .link-text {
    color: ${(props) => props.styles.font_color}
  }
  .makeStyles-title-55 {
    color: ${(props) => props.styles.font_color}
  }
  .MuiTypography-colorTextPrimary {
    color: ${(props) => props.styles.font_color}
  }
  .title-checkout-create {
    color: ${(props) => props.styles.font_color}
  }
  .title-checkout-have-an-account {
    color: ${(props) => props.styles.font_color}
  }
  .text-sign-in {
    color: ${(props) => props.styles.font_color}
  }
  .title-checkout-login {
    color: ${(props) => props.styles.font_color}
  }
  .billing-title-text {
    color: ${(props) => props.styles.font_color}
  }
  .almost-there-wrap {
    color: ${(props) => props.styles.font_color}
  }

  .MuiIconButton-colorPrimary {
    color: ${(props) => props.styles.icon_color}
  }
  .MuiStepIcon-active {
    color: ${(props) => props.styles.icon_color}!important
  }
  .MuiStepIcon-completed {
    color: ${(props) => props.styles.icon_color}!important
  }
  .far {
    color: ${(props) => props.styles.icon_color}!important
  }
  .MuiIconButton-label {
    div {
      color: ${(props) => props.styles.icon_color}
    }
  }

  .wrap-plan-select-group {
    .MuiInputLabel-outlined {
        color: ${(props) => props.styles.navigation_color}
    }
  }
  .wrap-links-web {
    .MuiButton-textPrimary {
      color: ${(props) => props.styles.cancel_button_color} 
    }
  }  
  .selector-currency-select {
    .MuiSelect-selectMenu {
        color: ${(props) => props.styles.navigation_color} !important
    }
  }
  .wrap-links-web {
    .MuiButton-textPrimary:hover {
      color: ${(props) => props.styles.cancel_button_color};
      background : ${(props) => props.styles.navigation_color + "14"} 
    }
  }  
  .links-mobile {
    .MuiButton-textPrimary {
      color: ${(props) => props.styles.cancel_button_color} 
    }
  }  
  .links-mobile {
    .MuiButton-textPrimary:hover {
      color: ${(props) => props.styles.cancel_button_color};
      background : ${(props) => props.styles.navigation_color + "14"} 
    }
  }  
  .link-navigation {
    color:${(props) => props.styles.navigation_color}
  }
  .MuiButton-textPrimary {
    .link-navigation {
        color:${(props) => props.styles.navigation_color}
    }
   }
   .wrap-logout {
    .Mui-focused {
      border: 1px solid ${(props) => props.styles.navigation_color}
    } 
  }
  .wrap-logout {
    .selector-currency-select :hover {
      border-color:${(props) => props.styles.navigation_color}
    }
  }
  .MuiSelect-icon {
    color: ${(props) => props.styles.navigation_color}
  }

  .option {
    .MuiButton-textPrimary {
      color: ${(props) => props.styles.link_color}
    }
  }
  .sign-text {
    color: ${(props) => props.styles.link_color}
  }
  .button-forgot-password {
    .link-navigation {
      color: ${(props) => props.styles.link_color}
    }
  }
  .MuiTypography-subtitle2 {
    .link-navigation {
      color: ${(props) => props.styles.link_color}
    }
  }
  .option {
    .MuiButton-textPrimary:hover {
        color: ${(props) => props.styles.link_color}
    }
  }
  .option {
    .MuiButton-outlinedPrimary:hover {
      color: ${(props) => props.styles.link_color};
      border: 1px solid ${(props) => props.styles.link_color}
    }
  }
  .card-job-minimal:hover {
    background: ${(props) => props.styles.link_color}
  }
  .Toastify__progress-bar--default {
    background: ${(props) => props.styles.link_color}
  }
  .button-notification {
    color: ${(props) => props.styles.link_color}
  }
  .wrap-add-remove-delete {
    .MuiIconButton-colorSecondary {
      color: ${(props) => props.styles.link_color}
    }
  }
  .item-quantity {
    border-color: ${(props) => props.styles.link_color}
  }
  .add-remove-item {
    .MuiIconButton-label {
      color: ${(props) => props.styles.link_color}
    } 
  }
  .item-quantity :nth-child(2) {
    color: ${(props) => props.styles.link_color}
  }
  .MuiBadge-badge {
    background: ${(props) => props.styles.link_color}
  }
  .wrap-configure {
    .PrivateTabIndicator-colorSecondary-9 {
      background-color: ${(props) => props.styles.link_color}
    }
  }

    .MuiTextField-root {
      .Mui-focused{
        .MuiOutlinedInput-notchedOutline {
          border: none
        }
      }
    }
    .MuiFormControl-root{
      p {
        border: none !important
      }
    }
    .form-control {
      .Mui-focused{
        .MuiOutlinedInput-notchedOutline {
          border: none
        }
      }
    }
    .wrap-logout {
      .Mui-focused{
        .MuiOutlinedInput-notchedOutline {
          border: none
        }
      }
    }

    .section-header {
      .MuiGrid-root {
        .MuiTypography-alignLeft {
          color: ${(props) => props.styles.account_header_font_color}
        }
      }
    } 

  .categories-plans-title {
    color: ${(props) => props.styles.headers_font_color}
  }
  
  .root-hero {
        background-color: ${(props) => props.styles.header_bar_color}
  }

 
  .wrap-plan-select-group {
    .plan-select-group {
        .MuiOutlinedInput-root {
            .MuiSelect-outlined{
                color: ${(props) => props.styles.navigation_color} !important
            }
           
        }
    }
  }
  .wrap-configure {
    .MuiTab-wrapper {
      color: ${(props) => props.styles.navigation_color}
    }
  }

  .wrap-logout {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.styles.navigation_color}
    }
  }

  .MuiButton-containedSecondary {
    background: ${(props) => props.styles.cancel_button_color}
  }
  .MuiButton-containedSecondary:hover {
    background: ${(props) => props.styles.cancel_button_color + "14"}
  }
  .MuiButton-outlinedSecondary {
    color: ${(props) => props.styles.cancel_button_color};
    border: 1px solid ${(props) => props.styles.cancel_button_color}
  }
  .MuiButton-outlinedSecondary:hover {
    color: ${(props) => props.styles.cancel_button_color};
    border: 1px solid ${(props) => props.styles.cancel_button_color + "14"}
  }
  .MuiButton-textSecondary {
    color: ${(props) => props.styles.cancel_button_color};
  }
  .MuiButton-textSecondary:hover {
    color: ${(props) => props.styles.cancel_button_color};
    background-color: ${(props) => props.styles.cancel_button_color + "14"}
  }


  .MuiButton-outlinedPrimary {
    color: ${(props) => props.styles.submit_button_color};
    border: 1px solid ${(props) => props.styles.submit_button_color}
  }
  .MuiButton-outlinedPrimary:hover {
    color: ${(props) => props.styles.submit_button_color};
    border: 1px solid ${(props) => props.styles.submit_button_color};
    background: ${(props) => props.styles.submit_button_color + "14"};
  }
  .MuiButton-containedPrimary {
    background: ${(props) => props.styles.submit_button_color}
  }
  .MuiButton-containedPrimary:hover {
    background: ${(props) => props.styles.submit_button_color}
  }
  .MuiButton-textPrimary {
    color: ${(props) => props.styles.submit_button_color};
  }
  .MuiButton-textPrimary:hover {
    color: ${(props) => props.styles.submit_button_color};
    background: ${(props) => props.styles.submit_button_color + "14"};
  }
  .MuiTextField-root {
    .Mui-focused {
      border: 2px solid ${(props) => props.styles.submit_button_color}
    }
  }
  .form-control {
      .Mui-focused {
        border: 2px solid ${(props) => props.styles.submit_button_color}
      } 
  }
 
  .selector-currency-select .MuiSelect-selectMenu {
    background: inherit !important
  }
  .wrap-plan-select-group .plan-select-group .MuiOutlinedInput-root .MuiSelect-outlined {
    background: inherit !important
  }

  .wrap-filter-content {
    .Mui-focused {
      border: none
    }
  }






  .main-main{
    .background-wrap-section {
      background: url(${api}/content/0_f4ps7oG8imhDG4YY.jpg) no-repeat center center fixed;
     
    }
  }
  .main-main{
    .background-wrap-section {
      background: url(${api}/images/${(props) =>
  props.images.background_image}) no-repeat center center fixed;
    }
  }
  .bg-config {
    background-image: url(${api}/images/${(props) =>
  props.images.background_image}) ;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
  }

  .container-logo {
    .logo-wrap-section {
      background-image: url(${api}/images/${(props) => props.images.logo_image})
    }
  }


  .logo-config {
    background-image: url(${api}/images/${(props) => props.images.logo_image});
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
  }

  .main-navigation {
    background : ${(props) => props.styles.navigation_bar_color}
  }
  .links-mobile {
    background: ${(props) => props.styles.navigation_bar_color}
  }
  .wrap-configure {
    .MuiAppBar-colorPrimary {
      background-color: ${(props) => props.styles.navigation_bar_color}
    }
  }

  .section-header__title-wrapper {
    .MuiTypography-h3 {
      color: ${(props) => props.styles.headers_font_color}
    }
  }

  .container-plans-description {
    color: ${(props) => props.styles.helped_text_color}
  }
  .categories-plans-description {
    color: ${(props) => props.styles.helped_text_color}
  }
  .categories-plans-subtitle {
    color: ${(props) => props.styles.helped_text_color}
  }
  .empty-cart-text {
    color: ${(props) => props.styles.helped_text_color}
  }
  .container-plans-price-description {
    color: ${(props) => props.styles.helped_text_color};
  }
  .MuiTypography-subtitle1 {
    color: ${(props) => props.styles.helped_text_color} !important
  }
  .item-description-cart {
    color: ${(props) => props.styles.helped_text_color}
  }
  .description-supplemental-plan {
    color: ${(props) => props.styles.helped_text_color}
  }

  .name-block {
    background: ${(props) => props.styles.title_account_bar_color}
  }
  .name-block {
    div {
      color: ${(props) => props.styles.title_account_text_color}
    }
  }
  .table-cell-billing-invoice {
    color: ${(props) => props.styles.title_account_text_color};
    background: ${(props) => props.styles.title_account_bar_color} 

  }

`;

export default GlobalStyle;

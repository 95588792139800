import React, { useEffect, useState } from "react";

import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReorderOutlinedIcon from "@material-ui/icons/ReorderOutlined";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import CartDetails from "../../components/Cart/CartDetails/CartDetails";
import LinksMobile from "../../components/NavigationPanel/LinksMobile/LinksMobile";
import { getIsOpen, getItemsInCart } from "../../helpers/Selector";
import { isOpenCart } from "../../redux/actions/Notification";
import { StyledBadge } from "./style";

import "./SideBar.css";

export default function TemporaryDrawer({ burgerMenu, checkout, menuCheckout }) {
  const isOpen = useSelector(getIsOpen);
  const itemsInCart = useSelector(getItemsInCart);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    right: false,
  });
  const [quantity, setQuantity] = useState("");

  useEffect(() => {
    let currentQuantity = 0;
    itemsInCart.forEach((item) => {
      currentQuantity = currentQuantity + item.quantity;
    });
    setQuantity(currentQuantity);
  }, [itemsInCart]);

  useEffect(() => {
    return () => {
      toggle("right", isOpen);
    };
  }, [isOpen]);

  const toggle = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    dispatch(isOpenCart(false));
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const classNameList = burgerMenu ? "list-burger-menu" : checkout ? "list-checkout" : "list";
  const list = (anchor) => (
    <div
      className={clsx(classNameList, {
        fullList: anchor === "top" || anchor === "bottom",
      })}
      style={{ flex: 1, display: "flex" }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {burgerMenu ? (
        <LinksMobile onClose={toggleDrawer(anchor, false)} />
      ) : (
        <CartDetails onClose={toggleDrawer(anchor, false)} checkout={checkout} menuCheckout={menuCheckout} />
      )}
    </div>
  );

  return (
    <div>
      {burgerMenu ? (
        <React.Fragment key={"left"}>
          <IconButton color="primary" component="div" onClick={toggleDrawer("left", true)}>
            <ReorderOutlinedIcon />
          </IconButton>
          <Drawer anchor={"left"} open={state["left"]} onClose={toggleDrawer("left", false)}>
            {list("left")}
          </Drawer>
        </React.Fragment>
      ) : menuCheckout ? (
        <React.Fragment key={"top"}>
          <IconButton color="primary" component="div" onClick={toggleDrawer("top", true)}>
            <ExpandMoreIcon />
          </IconButton>
          <Drawer anchor={"top"} open={state["top"]} onClose={toggleDrawer("top", false)}>
            {list("top")}
          </Drawer>
        </React.Fragment>
      ) : (
        <React.Fragment key={"right"}>
          <IconButton color="primary" component="div" onClick={toggleDrawer("right", true)}>
            <StyledBadge badgeContent={quantity}>
              <ShoppingCartIcon />
            </StyledBadge>
          </IconButton>
          <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)}>
            {list("right")}
          </Drawer>
        </React.Fragment>
      )}
    </div>
  );
}

import { useDispatch } from "react-redux";

import { isOpenCart } from "../../redux/actions/Notification";

const ContentToast = ({ title, schedule_name }) => {
  const dispatch = useDispatch();
  const openCart = () => {
    dispatch(isOpenCart(true));
  };
  return (
    <>
      <div className="title-notification">{title}</div>
      <div className="description-notification">{schedule_name}</div>

      <div className="description-notification padding-notification">
        Has been added to{" "}
        <span className="button-notification" onClick={openCart}>
          your cart
        </span>
      </div>
    </>
  );
};
export default ContentToast;

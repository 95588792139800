import { useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import "./Configure.css";
import { useDispatch, useSelector } from "react-redux";

import { getStatusConfigureActions } from "../../helpers/Selector";
import { getApiKeys, getEventListenerKeys, getPostMethod, getStyles } from "../../redux/actions/Configure";
import ModalWindow from "../../shared/ModalWindow/ModalWindow";
import EventLogKeys from "./EventLogKeys/EventLogKeys";
import KeysCredentials from "./KeysCredentials/KeysCredentials";
import LogoBackground from "./LogoBackground/LogoBackground";
import PaymentMethod from "./PaymentMethod/PaymentMethod";
import SiteStyles from "./SiteStyles/SiteStyles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const Configure = () => {
  const statusActions = useSelector(getStatusConfigureActions);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getApiKeys());
    dispatch(getPostMethod());
    dispatch(getStyles());
    dispatch(getEventListenerKeys());
  }, []);

  return (
    <div style={{ width: "100%", padding: 20 }} className="wrap-configure">
      {statusActions.status ? <ModalWindow status={statusActions.status} message={statusActions.message} /> : null}
      <AppBar>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" centered>
          <Tab label="Site Styles" style={{ maxWidth: 500 }} />
          <Tab label="API keys / credentials" style={{ maxWidth: 500 }} />
          <Tab label="Logo and background images" style={{ maxWidth: 500 }} />
          <Tab label="Payment methods" style={{ maxWidth: 500 }} />
          <Tab label="Event Log Keys" style={{ maxWidth: 500 }} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SiteStyles />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <KeysCredentials />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LogoBackground />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PaymentMethod />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <EventLogKeys />
      </TabPanel>
    </div>
  );
};
export default Configure;

import { useEffect, useState } from "react";

import { Button, Grid, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import validate from "validate.js";

import { handleFormState, handleFormStateUseEffect } from "../../../helpers/HandleFormState";
import { getStylesSite } from "../../../helpers/Selector";
import { updateStyles } from "../../../redux/actions/Configure";
import LoaderComponent from "../../../shared/LoaderComponent/LoaderComponent";
import { schema } from "./validationSchema";

const SiteStyles = () => {
  const styles = useSelector(getStylesSite);
  const dispatch = useDispatch();
  const [color, setColor] = useState({});
  const [onVisible, setOnVisible] = useState({
    font_color: false,
    navigation_color: false,
    icon_color: false,
    link_color: false,
    header_bar_color: false,
    headers_font_color: false,
    submit_button_color: false,
    cancel_button_color: false,
    helped_text_color: false,
    account_header_font_color: false,
    navigation_bar_color: false,
    title_account_text_color: false,
    title_account_bar_color: false,
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: styles.site_title ? { site_title: styles.site_title } : { site_title: "Aria Self Service (Demo)" },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  useEffect(() => {
    if (styles) {
      setColor({
        font_color: styles.font_color,
        navigation_color: styles.navigation_color,
        icon_color: styles.icon_color,
        link_color: styles.link_color,
        header_bar_color: styles.header_bar_color,
        headers_font_color: styles.headers_font_color,
        submit_button_color: styles.submit_button_color,
        cancel_button_color: styles.cancel_button_color,
        helped_text_color: styles.helped_text_color || "#e2e2e2",
        account_header_font_color: styles.account_header_font_color || "#e2e2e2",
        navigation_bar_color: styles.navigation_bar_color || "#e2e2e2",
        title_account_bar_color: styles.title_account_bar_color || "#333333",
        title_account_text_color: styles.title_account_text_color || "#ffffff",
      });
    }
  }, [styles]);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const handleSave = () => {
    formState.isValid && dispatch(updateStyles({ ...color, site_title: formState.values.site_title }))
  };

  const handleOpen = (name) => {
    setOnVisible((prevState) => ({ ...prevState, [name]: !onVisible[name] }));
  };
  const handleColor = (color, name) => {
    setColor((prevState) => ({ ...prevState, [name]: color }));
  };

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
  };

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div style={{ padding: 20 }}>
      {styles ? (
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Font Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.font_color}</div>
              <div onClick={() => handleOpen("font_color")} style={{ background: color.font_color }} className="block-fontColor"></div>
            </div>

            {onVisible.font_color ? (
              <SketchPicker color={color.font_color} onChangeComplete={(color) => handleColor(color.hex, "font_color")} />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Helped Text Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.helped_text_color}</div>
              <div
                onClick={() => handleOpen("helped_text_color")}
                style={{ background: color.helped_text_color }}
                className="block-fontColor"
              ></div>
            </div>

            {onVisible.helped_text_color ? (
              <SketchPicker color={color.helped_text_color} onChangeComplete={(color) => handleColor(color.hex, "helped_text_color")} />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Navigation Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.navigation_color}</div>
              <div
                onClick={() => handleOpen("navigation_color")}
                style={{ background: color.navigation_color }}
                className="block-fontColor"
              ></div>
            </div>
            {onVisible.navigation_color ? (
              <SketchPicker color={color.navigation_color} onChangeComplete={(color) => handleColor(color.hex, "navigation_color")} />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Navigation Bar Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.navigation_bar_color}</div>
              <div
                onClick={() => handleOpen("navigation_bar_color")}
                style={{ background: color.navigation_bar_color }}
                className="block-fontColor"
              ></div>
            </div>
            {onVisible.navigation_bar_color ? (
              <SketchPicker
                color={color.navigation_bar_color}
                onChangeComplete={(color) => handleColor(color.hex, "navigation_bar_color")}
              />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Icon Color
            </Typography>

            <div className="wrap-fontColors">
              <div>{color.icon_color}</div>
              <div onClick={() => handleOpen("icon_color")} style={{ background: color.icon_color }} className="block-fontColor"></div>
            </div>
            {onVisible.icon_color ? (
              <SketchPicker color={color.icon_color} onChangeComplete={(color) => handleColor(color.hex, "icon_color")} />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Link Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.link_color}</div>
              <div onClick={() => handleOpen("link_color")} style={{ background: color.link_color }} className="block-fontColor"></div>
            </div>
            {onVisible.link_color ? (
              <SketchPicker color={color.link_color} onChangeComplete={(color) => handleColor(color.hex, "link_color")} />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Header Bar Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.header_bar_color}</div>
              <div
                onClick={() => handleOpen("header_bar_color")}
                style={{ background: color.header_bar_color }}
                className="block-fontColor"
              ></div>
            </div>
            {onVisible.header_bar_color ? (
              <SketchPicker color={color.header_bar_color} onChangeComplete={(color) => handleColor(color.hex, "header_bar_color")} />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Account Header Font Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.account_header_font_color}</div>
              <div
                onClick={() => handleOpen("account_header_font_color")}
                style={{ background: color.account_header_font_color }}
                className="block-fontColor"
              ></div>
            </div>
            {onVisible.account_header_font_color ? (
              <SketchPicker
                color={color.account_header_font_color}
                onChangeComplete={(color) => handleColor(color.hex, "account_header_font_color")}
              />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Headers Font Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.headers_font_color}</div>
              <div
                onClick={() => handleOpen("headers_font_color")}
                style={{ background: color.headers_font_color }}
                className="block-fontColor"
              ></div>
            </div>
            {onVisible.headers_font_color ? (
              <SketchPicker color={color.headers_font_color} onChangeComplete={(color) => handleColor(color.hex, "headers_font_color")} />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Title Account Text Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.title_account_text_color}</div>
              <div
                onClick={() => handleOpen("title_account_text_color")}
                style={{ background: color.title_account_text_color }}
                className="block-fontColor"
              ></div>
            </div>
            {onVisible.title_account_text_color ? (
              <SketchPicker
                color={color.title_account_text_color}
                onChangeComplete={(color) => handleColor(color.hex, "title_account_text_color")}
              />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Title Account Bar Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.title_account_bar_color}</div>
              <div
                onClick={() => handleOpen("title_account_bar_color")}
                style={{ background: color.title_account_bar_color }}
                className="block-fontColor"
              ></div>
            </div>
            {onVisible.title_account_bar_color ? (
              <SketchPicker
                color={color.title_account_bar_color}
                onChangeComplete={(color) => handleColor(color.hex, "title_account_bar_color")}
              />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Site Title
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Site Title"
              variant="outlined"
              size="medium"
              name="site_title"
              fullWidth
              type="text"
              helperText={hasError("site_title") ? formState.errors.site_title[0] : null}
              error={hasError("site_title")}
              value={formState.values.site_title || ""}
              onChange={(event) => handleChange(event)}
            />
          </Grid>
          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Submit Button Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.submit_button_color}</div>
              <Button
                onClick={() => handleOpen("submit_button_color")}
                style={{
                  background: color.submit_button_color,
                  marginLeft: 15,
                  color: "white",
                }}
              >
                Submit
              </Button>
            </div>
            {onVisible.submit_button_color ? (
              <SketchPicker color={color.submit_button_color} onChangeComplete={(color) => handleColor(color.hex, "submit_button_color")} />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Cancel Button Color
            </Typography>
            <div className="wrap-fontColors">
              <div>{color.cancel_button_color}</div>
              <Button
                onClick={() => handleOpen("cancel_button_color")}
                style={{
                  marginLeft: 15,
                  color: color.cancel_button_color,
                }}
              >
                Cancel
              </Button>
            </div>
            {onVisible.cancel_button_color ? (
              <SketchPicker color={color.cancel_button_color} onChangeComplete={(color) => handleColor(color.hex, "cancel_button_color")} />
            ) : null}
          </Grid>
        </Grid>
      ) : (
        <LoaderComponent />
      )}
      <Grid style={{ marginTop: 30 }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save changes
        </Button>
      </Grid>
    </div>
  );
};

export default SiteStyles;

import { all } from "redux-saga/effects";

import { accountSaga } from "./Account";
import { authSaga } from "./Auth";
import { billingInvoiceSaga } from "./BillingInvoice";
import { checkoutSaga } from "./Checkout";
import { configureSaga } from "./Configure";
import { countriesSaga } from "./Countries";
import { currencySaga } from "./Currency";
import { paymentSaga } from "./Payment";
import { planSaga } from "./Plan";

export function* rootSaga() {
  yield all([
    authSaga(),
    checkoutSaga(),
    accountSaga(),
    planSaga(),
    paymentSaga(),
    billingInvoiceSaga(),
    configureSaga(),
    currencySaga(),
    countriesSaga(),
  ]);
}

import { SectionHeader } from "../../../shared/molecules";
import { Section } from "../../../shared/organisms";
import Form from "./Form/Form";
import { useStyles } from "./style";

const SigninSimple = () => {
  const classes = useStyles();

  return (
    <div className="background-wrap-section">
      <Section className={classes.section}>
        <div className={classes.formWrap}>
          <div className={classes.formContainer}>
            <SectionHeader
              title="Sign in"
              titleProps={{
                variant: "h3",
              }}
            />
            <Form />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default SigninSimple;

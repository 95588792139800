import { useEffect, useState } from "react";

import { useMediaQuery, Grid, Typography, TextField, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import validate from "validate.js";

import { handleFormState, handleFormStateUseEffect } from "../../../helpers/HandleFormState";
import "./Email.css";
import { getAccountSettingsInfo } from "../../../helpers/Selector";
import { changeEmail } from "../../../redux/actions/Account";
import { schema } from "./validationSchema";

const Email = () => {
  const { email } = useSelector(getAccountSettingsInfo);
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: email ? { email: email } : {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  const handleSave = () => {
    formState.isValid && dispatch(changeEmail(formState.values))
  };

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
  };

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);
  return (
    <div className="wrap-block-email">
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" color="textPrimary" className="input-title">
            E-mail
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your e-mail address"
            variant="outlined"
            size="medium"
            name="email"
            fullWidth
            type="email"
            helperText={hasError("email") ? formState.errors.email[0] : null}
            error={hasError("email")}
            value={formState.values.email || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>

        <Grid item container justify="space-between" xs={12}>
          <Button type="submit" color="secondary" size="large" onClick={() => history.push("/account/settings")}>
            cancel
          </Button>
          <Button className="button-save-email" variant="contained" type="submit" color="primary" size="large" onClick={handleSave}>
            save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Email;

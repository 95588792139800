import { CLEAR_STATUSES } from "../actionNames/Auth";
import {
  GET_BILLING_INVOICE_SUCCESS,
  GET_BILLING_INVOICE_FAILED,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_FAILED,
  GET_ALL_ACCOUNT_PLANS_SUCCESS,
  GET_ALL_ACCOUNT_PLANS_FAILED,
} from "../actionNames/BillingInvoice";

export const initialState = {
  billingInvoice: null,
  invoiceDetail: null,
  allAccountPlans: null,
  accountBalance: null,
};

export function billingInvoiceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BILLING_INVOICE_SUCCESS: {
      console.log(action);
      let accBalance = action.data.find((item) => item.acct_balance);

      return {
        ...state,
        accountBalance: accBalance.acct_balance,
        billingInvoice: action.data.sort(function (a, b) {
          return new Date(b.create_date) - new Date(a.create_date);
        }),
      };
    }
    case GET_BILLING_INVOICE_FAILED: {
      console.log(action);
      return {
        ...state,
      };
    }

    case GET_INVOICE_DETAILS_SUCCESS: {
      console.log(action);
      return {
        ...state,
        invoiceDetail: action.data,
      };
    }
    case GET_INVOICE_DETAILS_FAILED: {
      console.log(action);
      return {
        ...state,
      };
    }

    case GET_ALL_ACCOUNT_PLANS_SUCCESS: {
      console.log(action);
      return {
        ...state,
        allAccountPlans: action.data,
      };
    }
    case GET_ALL_ACCOUNT_PLANS_FAILED: {
      console.log(action);
      return {
        ...state,
      };
    }

    case CLEAR_STATUSES: {
      return {
        ...state,
        invoiceDetail: null,
      };
    }

    default:
      return state;
  }
}
export const billingInvoice = (state) => state.billingInvoice;

import { CLEAR_STATUSES } from "../actionNames/Auth";
import {
  GET_API_KEYS_FAILED,
  GET_API_KEYS_SUCCESS,
  GET_EVENT_LISTENER_KEYS_FAILED,
  GET_EVENT_LISTENER_KEYS_SUCCESS,
  GET_IMAGES_FAILED,
  GET_IMAGES_SUCCESS,
  GET_POST_METHOD_FAILED,
  GET_POST_METHOD_SUCCESS,
  GET_STYLES_FAILED,
  GET_STYLES_SUCCESS,
  SYNC_PLANS_FAILED,
  SYNC_PLANS_SUCCESS,
  UPDATE_API_KEYS_FAILED,
  UPDATE_API_KEYS_SUCCESS,
  UPDATE_EVENT_LISTENER_KEYS_FAILED,
  UPDATE_EVENT_LISTENER_KEYS_SUCCESS,
  UPDATE_POST_METHOD_FAILED,
  UPDATE_POST_METHOD_SUCCESS,
  UPDATE_STYLES_FAILED,
  UPDATE_STYLES_SUCCESS,
  UPLOAD_BACKGROUND_IMAGE_FAILED,
  UPLOAD_BACKGROUND_IMAGE_SUCCESS,
  UPLOAD_LOGO_IMAGE_FAILED,
  UPLOAD_LOGO_IMAGE_SUCCESS,
} from "../actionNames/Configure";

export const initialState = {
  apiKeys: {
    auth_key: null,
    client_number: null,
  },
  statusActions: {
    status: "",
    message: "",
  },
  styles: null,
  images: {
    background_image: null,
    logo_image: null,
  },
  postMethod: null,
  eventListenerId: null,
};

export function configureReducer(state = initialState, action) {
  switch (action.type) {
    case GET_API_KEYS_SUCCESS: {
      console.log(action);
      return {
        ...state,
        apiKeys: {
          auth_key: action.data.authorization_key,
          client_number: action.data.client_number,
        },
      };
    }
    case GET_API_KEYS_FAILED: {
      return {
        ...state,
        apiKeys: {
          auth_key: "",
          client_number: "",
        },
      };
    }

    case UPDATE_API_KEYS_SUCCESS: {
      console.log(action);
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }
    case UPDATE_API_KEYS_FAILED: {
      console.log(action);
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case SYNC_PLANS_SUCCESS: {
      console.log(action);
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }
    case SYNC_PLANS_FAILED: {
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case GET_STYLES_SUCCESS: {
      console.log(action);
      return {
        ...state,
        styles: action.data,
      };
    }
    case GET_STYLES_FAILED: {
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case UPDATE_STYLES_SUCCESS: {
      console.log(action);
      return {
        ...state,
        styles: action.styles,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }
    case UPDATE_STYLES_FAILED: {
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case UPLOAD_BACKGROUND_IMAGE_SUCCESS: {
      console.log(action);
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }
    case UPLOAD_BACKGROUND_IMAGE_FAILED: {
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case UPLOAD_LOGO_IMAGE_SUCCESS: {
      console.log(action);
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }
    case UPLOAD_LOGO_IMAGE_FAILED: {
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case UPDATE_POST_METHOD_SUCCESS: {
      console.log(action);
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }
    case UPDATE_POST_METHOD_FAILED: {
      console.log(action);
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case GET_POST_METHOD_SUCCESS: {
      console.log(action);
      return {
        ...state,
        postMethod: action.data.payment_method,
      };
    }
    case GET_POST_METHOD_FAILED: {
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case GET_IMAGES_SUCCESS: {
      console.log(action);
      return {
        ...state,
        images: {
          background_image: action.data.background_image,
          logo_image: action.data.logo_image,
        },
      };
    }
    case GET_IMAGES_FAILED: {
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case GET_EVENT_LISTENER_KEYS_SUCCESS: {
      console.log(action);
      return {
        ...state,
        eventListenerId: action.data.event_listener_id,
      };
    }
    case GET_EVENT_LISTENER_KEYS_FAILED: {
      return {
        ...state,
        eventListenerId: "",
      };
    }

    case UPDATE_EVENT_LISTENER_KEYS_SUCCESS: {
      console.log(action);
      return {
        ...state,
        eventListenerId: action.eventListenerKey,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }
    case UPDATE_EVENT_LISTENER_KEYS_FAILED: {
      console.log(action);
      return {
        ...state,
        statusActions: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case CLEAR_STATUSES: {
      return {
        ...state,
        statusActions: {
          status: "",
          message: "",
        },
      };
    }

    default:
      return state;
  }
}
export const configure = (state) => state.configure;

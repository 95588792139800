import { useEffect } from "react";

import { Button } from "@material-ui/core";
import "./ChangePlan.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { getGroupsPlans, getPlans, getCurrencySymbol } from "../../helpers/Selector";
import { getAllPlans } from "../../redux/actions/Plan";
import { images } from "../../shared/data/imagesForPlans";
import Filter from "../../shared/Filter/Filter";
import LoaderComponent from "../../shared/LoaderComponent/LoaderComponent";

const ChangePlan = () => {
  const plans = useSelector(getPlans);
  const groups = useSelector(getGroupsPlans);
  const currency = useSelector(getCurrencySymbol);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    dispatch(getAllPlans());
  }, []);

  let path = location.pathname.split("/")[2];
  let currentPlans = [];
  let currentGroup = [];
  if (plans && groups) {
    currentGroup = groups.filter((i) => i.group_no === +path);
    currentGroup[0].plans.forEach((i) => {
      plans.forEach((item) => {
        if (i.plan_no === item.plan_no) {
          item.rate_sched.details.sort((a, b) => a.amount - b.amount);
          currentPlans.push(item);
        }
      });
    });
  }

  const handleViewPlan = (id) => {
    history.push(`/choose-plan/${path}/${id}`);
  };

  const isPlansAvailable = plans ? currentPlans.find((i) => i.rate_sched.details[0]) : false

  return (
    <div className="wrap-plans">
      <div className="wrap-title-change-plan">
        <div className="categories-plans-title">{currentGroup[0] ? currentGroup[0].group_name : ""}</div>
      </div>
      {/* {!!isPlansAvailable && ( */}
        <div className="wrap-filter-container-plans">
          <Filter />
        </div>
      {/* )} */}
      <div className="wrap-container-plans">
        {plans ? (
          !!isPlansAvailable ? (
            currentPlans.filter(i => i.rate_sched.details[0]).map((item, idx) => (
              <div className="container-plans" key={idx + item.plan_name}>
              <div className="container-plans-texts">
                <div>
                  <div className="container-plans-title">{item.plan_name}</div>
                  <div className="container-plans-description">{item.description}</div>
                  {item.rate_sched.details[0] ? (
                    <div className="container-plans-price">
                      {item.rate_sched.details.length === 1
                        ? `${currency}${item.rate_sched.details[0].amount.toFixed(2)}`
                        : `${currency}${item.rate_sched.details[0].amount.toFixed(2)} - ${currency}${item.rate_sched.details[
                            item.rate_sched.details.length - 1
                          ].amount.toFixed(2)}`}
                    </div>
                  ) : (
                    <div className="container-plans-price without-rate">
                      Sorry, but there are no plans offered within the currency you selected
                    </div>
                  )}

                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className="container-plans-button"
                    onClick={() => handleViewPlan(item.plan_no)}
                  >
                    View plan rates & options
                  </Button>
                  <div className="container-plans-price-description">*Price based on choice of service options</div>
                </div>
              </div>
              <div className="container-plans-image">
                {/* <div style={{ backgroundImage: `url(${item.img})` }} className="image-plan"></div> */}
                <img alt="plan" src={item.img || images["Linksys_WRT32X_Lease"]} className="image-plan" />
              </div>
            </div>
            ))
          ) : <div className="categories-plans-error centered-header">No Plans Available</div>
        ) : (
          <LoaderComponent />
        )}
      </div>
    </div>
  );
};

export default ChangePlan;

import { useEffect } from "react";

import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { getAccountPlanCancelStatus, getLoginUserInfo, getStatusAccountInfo, getStatusChangePlan } from "../../helpers/Selector";
import { getAccountInfo, getEventLogs } from "../../redux/actions/Account";
import { getAllAccountPlans, getBillingInvoice } from "../../redux/actions/BillingInvoice";
import ModalWindow from "../../shared/ModalWindow/ModalWindow";
import CardBase from "../../shared/organisms/CardBase/CardBase";
import SectionAlternate from "../../shared/organisms/SectionAlternate/SectionAlternate";
import Hero from "./Hero/Hero";
import { useStyles } from "./style";

const Account = ({ children }) => {
  const loginUserInfo = useSelector(getLoginUserInfo);
  const statusChangePlan = useSelector(getStatusChangePlan);
  const statusAccountInfo = useSelector(getStatusAccountInfo);
  const AccountPlanCancelStatus = useSelector(getAccountPlanCancelStatus);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAccountInfo(loginUserInfo.session_id, history));
    dispatch(getAllAccountPlans());
    dispatch(getBillingInvoice());
    dispatch(getEventLogs());
  }, [statusAccountInfo.status, statusAccountInfo.message, AccountPlanCancelStatus.date, statusChangePlan.date]);

  return (
    <div className={classes.root}>
      {statusAccountInfo.status ? <ModalWindow message={statusAccountInfo.message} status={statusAccountInfo.status} /> : null}
      <Hero />
      <SectionAlternate className={classes.section}>
        <Grid container spacing={4} className={classes.wrapList}>
          <Grid item xs={12} md={9} className={classes.styleGrid}>
            <CardBase withShadow align="left">
              {children}
            </CardBase>
          </Grid>
        </Grid>
      </SectionAlternate>
    </div>
  );
};

export default Account;

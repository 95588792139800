import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    formContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: `calc(100vh - 200px)`,
      maxWidth: 500,
      margin: `0 auto`,
      padding: "0 30px",
    },
    section: {
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      // backgroundColor: 'rgba(255, 255, 255, 0.5)',
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    wrapper: {
      // backgroundImage: `url(${imageBackground})`,
      // backgroundImage: `url(https://accountselfservice.com/static/media/${background})`,
      width: "100vw",
      height: "100vh",
      // backgroundSize: 'cover',
      backgroundSize: "100% 100%",
    },
    formWrap: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backgroundColor: "rgba(255, 255, 255, 0.95)",
    },
  };
});

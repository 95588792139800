import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    list: {
        width: 650,
    },
    fullList: {
        width: 'auto',
    },
}))

export const StyledBadge = withStyles((theme) => ({
    badge: {
        // right: -3,
        // top: 13,
        border: `2px solid white`,
        padding: '0 4px',
        background: '#f50057',
        color: 'white',
        // color: "red",
    },
}))(Badge)

export const handleFormState = (formState, event) => {
  return {
    ...formState,
    values: {
      ...formState.values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.name.includes("phoneNumber") ||
            event.target.name === "cardNumber"
          ? event.target.value.trim().replace(/\D+/g, "")
          : event.target.value,
    },
    touched: {
      ...formState.touched,
      [event.target.name]: true,
    },
  };
};

export const handleFormStateUseEffect = (formState, errors) => ({
  ...formState,
  isValid: errors ? false : true,
  errors: errors || {},
});

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
    },
    section: {
        display: 'flex',
        '& .section-alternate__content': {
            paddingTop: 0,
            marginTop: theme.spacing(-5),
            position: 'relative',
            zIndex: 1,
            // padding: "10px 10px 30px 65px",
        },
        '& .card-base__content': {
            padding: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3),
            },
        },
    },
    menu: {
        height: 'auto',
    },
    list: {
        display: 'inline-flex',
        overflow: 'auto',
        flexWrap: 'nowrap',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginRight: theme.spacing(-3),
            marginLeft: theme.spacing(-3),
        },
    },
    listItem: {
        marginRight: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            borderLeft: '2px solid transparent',
            width: '100%',
        },
    },
    listItemActive: {
        [theme.breakpoints.up('md')]: {
            borderLeft: `2px solid ${theme.palette.primary.dark}`,
            width: '100%',
        },
        '& .menu__item': {
            color: theme.palette.text.primary,
            width: '100%',
        },
    },
    wrapList: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    styleGrid: {
        maxWidth: '100%',
        flexBasis: '100%',
    },
}))

import { useState, useEffect } from "react";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import "./SelectorCurrency.css";
import { useDispatch, useSelector } from "react-redux";

import { getCurrencyList } from "../../helpers/Selector";
import { currencyChange } from "../../redux/actions/Currency";
// import { currency } from "../../shared/data/currency";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));

const SelectorCurrency = () => {
  const currencyList = useSelector(getCurrencyList);
  const classes = useStyles();
  const [currencyValue, setCurrencyValue] = useState(
    currencyList.length ? currencyList[0].currency_cd : "usd"
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (currencyList.length) {
      const currency_symbol = currencyList.find(
        (item) => item.currency_cd === currencyValue
      );
      dispatch(currencyChange(currencyValue, currency_symbol.currency_symbol));
    }
  }, [currencyValue]);

  const handleChange = (event) => {
    setCurrencyValue(event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          value={currencyValue}
          onChange={handleChange}
          className="selector-currency-select"
          inputProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {currencyList.map((item, idx) => (
            <MenuItem
              value={item.currency_cd}
              className="selector-currency-menu-item"
              key={idx + item.currency_cd + Math.floor(Math.random() * 100)}
            >
              {`${item.currency_cd.toUpperCase()} ${item.currency_symbol}`}
              
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default SelectorCurrency;

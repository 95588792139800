import { useState, useRef } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";

import { clearStatuses } from "../../redux/actions/Auth";

const ModalForHtml = ({ invoices }) => {
  const toPrint = useRef();
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  function createMarkup() {
    return { __html: invoices };
  }
  const handleClose = () => {
    setOpen(false);
    dispatch(clearStatuses());
  };
  const handlePrint = useReactToPrint({
    content: () => toPrint.current,
    suppressErrors: false,
  });

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ maxWidth: "100% !important" }}
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          <Button onClick={handlePrint} color="primary" variant="contained">
            Print
          </Button>
        </DialogTitle>
        <DialogContent className="dialog-modal-window">
          <div ref={toPrint} dangerouslySetInnerHTML={createMarkup()} className="invoice-detail"></div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalForHtml;

export const schema = {
  newPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
    format: {
      pattern: "^[a-zA-Z0-9]+$"
    },
  },
  repeatPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
    format: {
      pattern: "^[a-zA-Z0-9]+$"
    },
    equality: "newPassword",
  },
};

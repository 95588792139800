import { LOGOUT_USER } from "../actionNames/Auth";
import { CURRENCY_CHANGE } from "../actionNames/Currency";
import { CHECK_TAXES_FAILED, CHECK_TAXES_SUCCESS } from "../actionNames/Payment";
import { ADD_REMOVE_QUANTITY, ADD_TO_CART, CLEAR_CART, DELETE_FROM_CART } from "../actionNames/Products";

export const initialState = {
  itemsInCart: [],
  totalSum: 0,
  currentAddedItem: {},
  totalTaxes: 0,
};

export function productsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_CART: {
      const newItem = action.data;
      const arrayItems = state.itemsInCart;

      if (newItem.schedule_no) {
        const isSame = arrayItems.find((i) => {
          return i.schedule_no === newItem.schedule_no;
        });
        const isSamePlan = arrayItems.find((i) => i.plan_no === newItem.plan_no);
        if (!isSame && !isSamePlan) {
          arrayItems.push(newItem);
        }
      } else {
        if (state.itemsInCart.length) {
          const isSame = arrayItems.find((i) => {
            return i.id === newItem.id;
          });

          if (!isSame) {
            arrayItems.push(newItem);
          } else {
            arrayItems.forEach((item) => {
              if (item.id === newItem.id) {
                let quantity = item.quantity;
                item.quantity = quantity++;
              }
            });
          }
        } else {
          arrayItems.push(newItem);
        }
      }

      return {
        ...state,
        itemsInCart: [...arrayItems],
        totalSum: arrayItems.reduce((acc, item) => (acc += item.price * item.quantity), 0).toFixed(2),
      };
    }

    case DELETE_FROM_CART: {
      const itemToDelete = action.data;
      const arrayItems = state.itemsInCart;
      let updatedArray;
      if (itemToDelete.schedule_no) {
        updatedArray = arrayItems.filter((item) => item.schedule_no !== itemToDelete.schedule_no);
      } else {
        updatedArray = arrayItems.filter((item) => item.id !== itemToDelete.id);
      }
      return {
        ...state,
        itemsInCart: [...updatedArray],
        totalSum: updatedArray.reduce((acc, item) => (acc += item.price * item.quantity), 0).toFixed(2),
      };
    }

    case ADD_REMOVE_QUANTITY: {
      const operation = action.data.operation;
      const itemToChange = action.data.item;
      const arrayItems = state.itemsInCart.map((item) => {
        if (item.id === itemToChange.id) {
          if (operation === "add") {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          } else {
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          }
        }
        return item;
      });

      return {
        ...state,
        itemsInCart: [...arrayItems],
        totalSum: arrayItems.reduce((acc, item) => (acc += item.price * item.quantity), 0).toFixed(2),
      };
    }

    case CHECK_TAXES_SUCCESS: {
      console.log(action);
      const arrayItems = state.itemsInCart;
      let invoiceItemDetails = action.isUnRegister
        ? action.data.invoice_items.filter((i) => i.client_plan_id)
        : action.data.invoice_item_details.filter((i) => i.client_plan_id);
      const taxDetails = action.data.tax_details;

      console.log("invoiceItemDetails , taxDetails", invoiceItemDetails, taxDetails);

      const updatedArray = invoiceItemDetails.map((item) => {
        let arr = [];
        taxDetails.forEach((i) => {
          if (i.taxed_seq_num === item.invoice_line_no) {
            arr.push(i);
          }
        });
        console.log(arr);
        return {
          ...item,
          taxes: parseFloat(arr.reduce((acc, item) => (acc += +item.debit), 0).toFixed(2)),
        };
      });
      console.log("ArrayUPD", updatedArray);
      let upd = [];

      updatedArray.forEach((i, idx) => {
        if (upd.length) {
          const isSame = upd.find((item) => item.plan_no === i.plan_no);

          if (!isSame) {
            upd.push(i);
          } else {
            upd[upd.length - 1].taxes += +i.taxes;
          }
        } else {
          upd.push(i);
        }
      });
      console.log("UPD", upd);

      let ar = [];
      const newArrayItems = arrayItems.map((item, idx) => {
        upd.forEach((i) => {
          if (item.plan_no === i.plan_no) {
            ar.push(i.taxes);
          }
        });
        console.log(item, upd[idx], ar);
        return {
          ...item,
          taxes: ar[idx],
        };
      });
      return {
        ...state,
        itemsInCart: newArrayItems,
        totalSum: action.data.invoice_charges_after_tax,
        totalTaxes: action.data.invoice_tax_charges,
      };
    }

    case CHECK_TAXES_FAILED: {
      return {
        ...state,
      };
    }

    case LOGOUT_USER: {
      return {
        ...initialState,
        itemsInCart: [],
      };
    }

    case CURRENCY_CHANGE: {
      return {
        ...initialState,
        itemsInCart: [],
      };
    }

    case CLEAR_CART: {
      return {
        ...state,
        itemsInCart: [],
      };
    }

    default:
      return state;
  }
}
export const products = (state) => state.products;

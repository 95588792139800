import {
  CURRENCY_ACCOUNT_CHANGE,
  CURRENCY_CHANGE,
  CURRENCY_GET,
} from "../actionNames/Currency";

export function currencyChange(data, currency_symbol) {
  return { type: CURRENCY_CHANGE, data, currency_symbol };
}

export function currencyGet(data) {
  return { type: CURRENCY_GET, data };
}

export function currencyAccountChange(data) {
  return { type: CURRENCY_ACCOUNT_CHANGE, data };
}

export const GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO";
export const GET_ACCOUNT_INFO_SUCCESS = "GET_ACCOUNT_INFO_SUCCESS";
export const GET_ACCOUNT_INFO_FAILED = "GET_ACCOUNT_INFO_FAILED";

export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_FAILED = "CHANGE_EMAIL_FAILED";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const CHANGE_PERSONAL_INFO = "CHANGE_PERSONAL_INFO";
export const CHANGE_PERSONAL_INFO_SUCCESS = "CHANGE_PERSONAL_INFO_SUCCESS";
export const CHANGE_PERSONAL_INFO_FAILED = "CHANGE_PERSONAL_INFO_FAILED";

export const CHANGE_ACCOUNT_PLAN = "CHANGE_ACCOUNT_PLAN";
export const CHANGE_ACCOUNT_PLAN_SUCCESS = "CHANGE_ACCOUNT_PLAN_SUCCESS";
export const CHANGE_ACCOUNT_PLAN_FAILED = "CHANGE_ACCOUNT_PLAN_FAILED";

export const CANCEL_ACCOUNT_PLAN = "CANCEL_ACCOUNT_PLAN";
export const CANCEL_ACCOUNT_PLAN_SUCCESS = "CANCEL_ACCOUNT_PLAN_SUCCESS";
export const CANCEL_ACCOUNT_PLAN_FAILED = "CANCEL_ACCOUNT_PLAN_FAILED";

export const GET_RATES = "GET_RATES";
export const GET_RATES_SUCCESS = "GET_RATES_SUCCESS";
export const GET_RATES_FAILED = "GET_RATES_FAILED";

export const GET_EVENT_LOGS = "GET_EVENT_LOGS";
export const GET_EVENT_LOGS_SUCCESS = "GET_EVENT_LOGS_SUCCESS";
export const GET_EVENT_LOGS_FAILED = "GET_EVENT_LOGS_FAILED";

export const CLOSE_MODAL = "CLOSE_MODAL";

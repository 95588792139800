import { useEffect, useState } from "react";

import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import validate from "validate.js";

import { handleFormState, handleFormStateUseEffect } from "../../../helpers/HandleFormState";
import { getPostMethodSite } from "../../../helpers/Selector";
import { updatePostMethod } from "../../../redux/actions/Configure";
import LoaderComponent from "../../../shared/LoaderComponent/LoaderComponent";
import { schema } from "./validationSchema";

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const payment_method = useSelector(getPostMethodSite);

  const [formState, setFormState] = useState({
    isValid: false,
    values: payment_method ? { payment_method: payment_method } : { payment_method: "direct_payment_method_demo" },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  const handleSave = () => {
    formState.isValid
      && dispatch(
          updatePostMethod({
            direct_post_method: formState.values.payment_method,
          })
        )
  };

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
  };

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);
  return (
    <div style={{ paddingTop: 20 }}>
      {payment_method ? (
        <>
          <Grid item xs={12} sm={4} className="wrap-select-config">
            <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
              Payment Method
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Payment Method"
              variant="outlined"
              size="medium"
              name="payment_method"
              fullWidth
              type="text"
              helperText={hasError("payment_method") ? formState.errors.payment_method[0] : null}
              error={hasError("payment_method")}
              value={formState.values.payment_method || ""}
              onChange={(event) => handleChange(event)}
            />
          </Grid>
          <Grid style={{ marginTop: 30 }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save changes
            </Button>
          </Grid>
        </>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};
export default PaymentMethod;

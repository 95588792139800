export const pricings = [
  {
    title: "Standard License",
    subtitle: "A pay-once license, just for you",
    price: "$49",
    priceSuffix: " / MO",
    features: [
      "Rich, responsive landing pages",
      "100+ styled components",
      "Flexible, simple license",
      "Speedy build tooling",
      "6 months free support included",
    ],
    disclaimer: "Fully featured 30-day free trial",
    isHighlighted: false,
  },
  {
    title: "Extended License",
    subtitle: "A pay-once license, just for you",
    price: "$79",
    priceSuffix: " / MO",
    features: [
      "Rich, responsive landing pages",
      "100+ styled components",
      "Flexible, simple license",
      "Speedy build tooling",
      "6 months free support included",
    ],
    disclaimer: "Fully featured 30-day free trial",
    isHighlighted: true,
  },
  {
    title: "Standard License",
    subtitle: "A pay-once license, just for you",
    price: "$49",
    priceSuffix: " / MO",
    features: [
      "Rich, responsive landing pages",
      "100+ styled components",
      "Flexible, simple license",
      "Speedy build tooling",
      "6 months free support included",
    ],
    disclaimer: "Fully featured 30-day free trial",
    isHighlighted: false,
  },
];
export const categories = [
  {
    title: "Headphones",
    icon: {
      src:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-headphone.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-headphone@2x.png 2x",
    },
  },
  {
    title: "Beats",
    icon: {
      src:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-music.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-music@2x.png 2x",
    },
  },
  {
    title: "Notebooks",
    icon: {
      src:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-laptop.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-laptop@2x.png 2x",
    },
  },
  {
    title: "Cameras",
    icon: {
      src:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-camera.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-camera@2x.png 2x",
    },
  },
  {
    title: "Gaming",
    icon: {
      src:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-game.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-game@2x.png 2x",
    },
  },
  {
    title: "Video",
    icon: {
      src:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-camera.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/illustrations/icon-camera@2x.png 2x",
    },
  },
];

export const featuredProducts = [
  {
    id: 1,
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product1.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product1@2x.png 2x",
    },
    price: 320,
    title: "Music player",
    rating: 5,
    quantity: 1,
  },
  {
    id: 2,
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product2.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product2@2x.png 2x",
    },
    price: 120,
    title: "Headphones",
    rating: 4,
    quantity: 1,
  },
  {
    id: 3,
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product3.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product3@2x.png 2x",
    },
    price: 290,
    title: "Wireless headpohones",
    rating: 5,
    quantity: 1,
  },
  {
    id: 4,
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product4.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product4@2x.png 2x",
    },
    price: 140,
    title: "Bluetooth headphones",
    rating: 3,
    quantity: 1,
  },
  {
    id: 5,
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product5.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product5@2x.png 2x",
    },
    price: 170,
    title: "Headphones",
    rating: 5,
    quantity: 1,
  },
  {
    id: 6,
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product6.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product6@2x.png 2x",
    },
    price: 300,
    title: "Music player",
    rating: 4,
    quantity: 1,
  },
];

export const mostSoldProducts = [
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product7.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product7@2x.png 2x",
    },
    title: "Laptops and computers",
  },
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product8.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product8@2x.png 2x",
    },
    title: "Phones and tablets",
  },
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product9.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product9@2x.png 2x",
    },
    title: "Headphones and airpods",
  },
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product8.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product8@2x.png 2x",
    },
    title: "iWatch and smartwatch",
  },
];

export const news = [
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/rental/los-angeles.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/rental/los-angeles@2x.jpg 2x",
    },
    title: "The products you need",
    subtitle:
      "The Nike Zoom SB Stefan Janoski SE Men's Skateboarding Shoe is made with smooth suede and Nubuck…",
    cols: 2,
  },
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/rental/san-francisco.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/rental/san-francisco@2x.jpg 2x",
    },
    title: "The most viewed products of the month",
    subtitle:
      "The Nike Zoom SB Stefan Janoski SE Men's Skateboarding Shoe is made with smooth suede and Nubuck…",
    cols: 1,
    rows: 2,
  },
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/rental/seattle.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/rental/seattle@2x.jpg 2x",
    },
    title: "Why you need to have these products?",
    subtitle:
      "The Nike Zoom SB Stefan Janoski SE Men's Skateboarding Shoe is made with smooth suede and Nubuck…",
    cols: 3,
  },
];

export const latestProducts = [
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product2.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product2@2x.png 2x",
    },
    price: "$120",
    title: "Headphones",
    rating: 4,
  },
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product3.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product3@2x.png 2x",
    },
    price: "$290",
    title: "Wireless headpohones",
    rating: 5,
  },
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product4.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product4@2x.png 2x",
    },
    price: "$140",
    title: "Bluetooth headphones",
    rating: 3,
  },
  {
    image: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product5.png",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/ecommerce/product5@2x.png 2x",
    },
    price: "$170",
    title: "Headphones",
    rating: 5,
  },
];

export const reviews = [
  {
    authorPhoto: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x",
    },
    authorName: "Veronica Adams",
    authorOccupation: "Growth Marketer, Crealytics",
    feedback:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    authorPhoto: {
      src:
        "https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x",
    },
    authorName: "Akachi Luccini",
    authorOccupation: "Lead Generation, Alternative Capital",
    feedback:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
];

export const billingInvoicesData = {
  currentPlan: {
    type: "Standard",
    price: "13.99",
    period: "Month",
  },
  nextBill: {
    date: "February 12, 2021",
  },
  history: [
    {
      date: "1/12/21",
      description: "Streaming Service",
      periodStart: "1/12/21",
      periodEnd: "2/11/21",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "12/12/20",
      description: "Streaming Service",
      periodStart: "12/12/20",
      periodEnd: "1/11/21",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "11/12/20",
      description: "Streaming Service",
      periodStart: "11/12/20",
      periodEnd: "12/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "10/12/20",
      description: "Streaming Service",
      periodStart: "10/12/20",
      periodEnd: "11/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "9/12/20",
      description: "Streaming Service",
      periodStart: "9/12/20",
      periodEnd: "10/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "8/12/20",
      description: "Streaming Service",
      periodStart: "9/12/20",
      periodEnd: "9/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "7/12/20",
      description: "Streaming Service",
      periodStart: "7/12/20",
      periodEnd: "8/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "6/12/20",
      description: "Streaming Service",
      periodStart: "6/12/20",
      periodEnd: "7/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "5/12/20",
      description: "Streaming Service",
      periodStart: "5/12/20",
      periodEnd: "6/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "4/12/20",
      description: "Streaming Service",
      periodStart: "4/12/20",
      periodEnd: "5/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "3/12/20",
      description: "Streaming Service",
      periodStart: "3/12/20",
      periodEnd: "4/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "2/12/20",
      description: "Streaming Service",
      periodStart: "2/12/20",
      periodEnd: "3/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
    {
      date: "1/12/20",
      description: "Streaming Service",
      periodStart: "1/12/20",
      periodEnd: "2/11/20",
      method: "4245112246795250",
      methodCart: "visa",
      price: "13.99",
    },
  ],
};

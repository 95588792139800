import { combineReducers } from "redux";

import { accountReducer } from "./reducers/Account";
import { authReducer } from "./reducers/Auth";
import { billingInvoiceReducer } from "./reducers/BillingInvoice";
import { checkoutReducer } from "./reducers/Checkout";
import { configureReducer } from "./reducers/Configure";
import { countriesReducer } from "./reducers/Countries";
import { currencyReducer } from "./reducers/Currency";
import { loadingReducer } from "./reducers/Loading";
import { notificationReducer } from "./reducers/Notification";
import { paymentReducer } from "./reducers/Payment";
import { planReducer } from "./reducers/Plan";
import { productsReducer } from "./reducers/Products";

const rootReducer = combineReducers({
  cart: productsReducer,
  auth: authReducer,
  checkout: checkoutReducer,
  loading: loadingReducer,
  plan: planReducer,
  currency: currencyReducer,
  account: accountReducer,
  payment: paymentReducer,
  notification: notificationReducer,
  billingInvoice: billingInvoiceReducer,
  configure: configureReducer,
  countries: countriesReducer,
});

export default rootReducer;

import { ADD_REMOVE_QUANTITY, ADD_TO_CART, CLEAR_CART, DELETE_FROM_CART } from "../actionNames/Products";

export function addToCart(data) {
  return { type: ADD_TO_CART, data };
}

export function deleteFromCart(data) {
  return { type: DELETE_FROM_CART, data };
}

export function addRemoveQuantity(data) {
  return { type: ADD_REMOVE_QUANTITY, data };
}
export function clearCart(data) {
  return { type: CLEAR_CART, data };
}

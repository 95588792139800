export const countrySelect = [
  {
    id: "US",
    value: "United States",
  },
  {
    id: "CA",
    value: "Canada",
  },
  {
    id: "AU",
    value: "Australia",
  },
  {
    id: "GB",
    value: "Great Britain",
  },
  {
    id: "BR",
    value: "Brazil",
  },
];

export const stateSelect = {
  CA: [
    {
      id: "AB",
      value: "Alberta",
    },
    {
      id: "BC",
      value: "British Columbia",
    },
    {
      id: "MB",
      value: "Manitoba",
    },
    {
      id: "NB",
      value: "New Brunswick",
    },
    {
      id: "NL",
      value: "Newfoundland and Labrador",
    },
    {
      id: "NS",
      value: "Nova Scotia",
    },
    {
      id: "NT",
      value: "Northwest Territories",
    },
    {
      id: "NU",
      value: "Nunavut",
    },
    {
      id: "ON",
      value: "Ontario",
    },
    {
      id: "PE",
      value: "Prince Edward Island",
    },
    {
      id: "QC",
      value: "Quebec",
    },
    {
      id: "SK",
      value: "Saskatchewan",
    },
    {
      id: "YT",
      value: "Yukon",
    },
  ],
  US: [
    {
      id: "AA",
      value: "Armed Forces America",
    },
    {
      id: "AE",
      value: "Armed Forces",
    },
    {
      id: "AK",
      value: "Alaska",
    },
    {
      id: "AL",
      value: "Alabama",
    },
    {
      id: "AP",
      value: "Armed Forces Pacific",
    },
    {
      id: "AR",
      value: "Arkansas",
    },
    {
      id: "AZ",
      value: "Arizona",
    },
    {
      id: "CA",
      value: "California",
    },
    {
      id: "CO",
      value: "Colorado",
    },
    {
      id: "CT",
      value: "Connecticut",
    },
    {
      id: "DC",
      value: "Washington DC",
    },
    {
      id: "DE",
      value: "Delaware",
    },
    {
      id: "FL",
      value: "Florida",
    },
    {
      id: "GA",
      value: "Georgia",
    },
    {
      id: "GU",
      value: "Guam",
    },
    {
      id: "HI",
      value: "Hawaii",
    },
    {
      id: "IA",
      value: "Iowa",
    },
    {
      id: "ID",
      value: "Idaho",
    },
    {
      id: "IL",
      value: "Illinois",
    },
    {
      id: "IN",
      value: "Indiana",
    },
    {
      id: "KS",
      value: "Kansas",
    },
    {
      id: "KY",
      value: "Kentucky",
    },
    {
      id: "LA",
      value: "Louisiana",
    },
    {
      id: "MA",
      value: "Massachusetts",
    },
    {
      id: "MD",
      value: "Maryland",
    },
    {
      id: "ME",
      value: "Maine",
    },
    {
      id: "MI",
      value: "Michigan",
    },
    {
      id: "MN",
      value: "Minnesota",
    },
    {
      id: "MO",
      value: "Missouri",
    },
    {
      id: "MS",
      value: "Mississippi",
    },
    {
      id: "MT",
      value: "Montana",
    },
    {
      id: "NC",
      value: "North Carolina",
    },
    {
      id: "ND",
      value: "North Dakota",
    },
    {
      id: "NE",
      value: "Nebraska",
    },
    {
      id: "NH",
      value: "New Hampshire",
    },
    {
      id: "NJ",
      value: "New Jersey",
    },
    {
      id: "NM",
      value: "New Mexico",
    },
    {
      id: "NV",
      value: "Nevada",
    },
    {
      id: "NY",
      value: "New York",
    },
    {
      id: "OH",
      value: "Ohio",
    },
    {
      id: "OK",
      value: "Oklahoma",
    },
    {
      id: "OR",
      value: "Oregon",
    },
    {
      id: "PA",
      value: "Pennsylvania",
    },
    {
      id: "PR",
      value: "Puerto Rico",
    },
    {
      id: "RI",
      value: "Rhode Island",
    },
    {
      id: "SC",
      value: "South Carolina",
    },
    {
      id: "SD",
      value: "South Dakota",
    },
    {
      id: "TN",
      value: "Tennessee",
    },
    {
      id: "TX",
      value: "Texas",
    },
    {
      id: "UT",
      value: "Utah",
    },
    {
      id: "VA",
      value: "Virginia",
    },
    {
      id: "VI",
      value: "Virgin Islands",
    },
    {
      id: "VT",
      value: "Vermont",
    },
    {
      id: "WA",
      value: "Washington",
    },
    {
      id: "WI",
      value: "Wisconsin",
    },
    {
      id: "WV",
      value: "West Virginia",
    },
    {
      id: "WY",
      value: "Wyoming",
    },
  ],
  AU: [
    {
      id: "NSW",
      value: "New South Wales",
    },
    {
      id: "QLD",
      value: "Queensland",
    },
    {
      id: "SA",
      value: "South Australia",
    },
    {
      id: "TAS",
      value: "Tasmania",
    },
    {
      id: "VIC",
      value: "Victoria",
    },
    {
      id: "WA",
      value: "Western Australia",
    },
    {
      id: "ACT",
      value: "Australian Capital Territory",
    },
    {
      id: "NT",
      value: "Northern Territory",
    },
  ],
};

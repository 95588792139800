import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import "./LinksMobile.css";
import { getLoginUserInfo } from "../../../helpers/Selector";
import PlanSelectGroup from "../../../shared/PlansSelectGroup/PlanSelectGroup";

const LinksMobile = ({ onClose }) => {
  const loginUserInfo = useSelector(getLoginUserInfo);
  const history = useHistory();

  const handleRedirect = (path) => {
    if (path === "/account/settings") {
      if (!loginUserInfo.userid) {
        let pathLocation = "/account/settings";
        history.push("/sign-in", pathLocation);
      } else {
        history.push(path);
      }
    }
  };

  return (
    <div className="links-mobile">
      <PlanSelectGroup />
      {/* <Button color="primary" size="large" onClick={onClose}>
        <Link to="/products" className="link-navigation">
          Products
        </Link>
      </Button> */}

      <Button color="primary" size="large" onClick={onClose}>
        <div onClick={() => handleRedirect("/account/settings")} className="link-navigation">
          My Account
        </div>
      </Button>
    </div>
  );
};
export default LinksMobile;

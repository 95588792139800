import { put, takeEvery, call } from "redux-saga/effects";

import {
  callConfigure,
  callConfigureImages,
} from "../../service/apiService/apiService";
import {
  GET_API_KEYS,
  GET_API_KEYS_FAILED,
  GET_API_KEYS_SUCCESS,
  GET_EVENT_LISTENER_KEYS,
  GET_EVENT_LISTENER_KEYS_FAILED,
  GET_EVENT_LISTENER_KEYS_SUCCESS,
  GET_IMAGES,
  GET_IMAGES_FAILED,
  GET_IMAGES_SUCCESS,
  GET_POST_METHOD,
  GET_POST_METHOD_FAILED,
  GET_POST_METHOD_SUCCESS,
  GET_STYLES,
  GET_STYLES_FAILED,
  GET_STYLES_SUCCESS,
  SYNC_PLANS,
  SYNC_PLANS_FAILED,
  SYNC_PLANS_SUCCESS,
  UPDATE_API_KEYS,
  UPDATE_API_KEYS_FAILED,
  UPDATE_API_KEYS_SUCCESS,
  UPDATE_EVENT_LISTENER_KEYS,
  UPDATE_EVENT_LISTENER_KEYS_FAILED,
  UPDATE_EVENT_LISTENER_KEYS_SUCCESS,
  UPDATE_POST_METHOD,
  UPDATE_POST_METHOD_FAILED,
  UPDATE_POST_METHOD_SUCCESS,
  UPDATE_STYLES,
  UPDATE_STYLES_FAILED,
  UPDATE_STYLES_SUCCESS,
  UPLOAD_BACKGROUND_IMAGE,
  UPLOAD_BACKGROUND_IMAGE_FAILED,
  UPLOAD_BACKGROUND_IMAGE_SUCCESS,
  UPLOAD_LOGO_IMAGE,
  UPLOAD_LOGO_IMAGE_FAILED,
  UPLOAD_LOGO_IMAGE_SUCCESS,
} from "../actionNames/Configure";

function* doGetApiKeys(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(callConfigure, "POST", `getapikeys`, {});

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_API_KEYS_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: GET_API_KEYS_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doUpdateApiKeys(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(callConfigure, "POST", `updateapikeys`, {
      client_number: action.data.clientNumber,
      authorization_key: action.data.authKey,
    });

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: UPDATE_API_KEYS_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: UPDATE_API_KEYS_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetEventListenerKeys(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callConfigure,
      "POST",
      `geteventlistenerkeys`,
      {}
    );

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_EVENT_LISTENER_KEYS_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: GET_EVENT_LISTENER_KEYS_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doUpdateEventListenerKeys(action) {
  try {
    yield put({ type: "START_LOADING" });
    const { eventListenerKey, password } = action.data;
    console.log(eventListenerKey, password, action.data);
    const answerBackend = yield call(
      callConfigure,
      "POST",
      `updateeventlistenerkeys`,
      {
        event_listener_id: eventListenerKey,
        event_listener_psw: password,
      }
      // { event_listener_id, event_listener_psw }
    );

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: UPDATE_EVENT_LISTENER_KEYS_SUCCESS,
      status,
      data,
      eventListenerKey,
      password,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: UPDATE_EVENT_LISTENER_KEYS_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doSyncPlans(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(callConfigure, "POST", `syncplans`, {});

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: SYNC_PLANS_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: SYNC_PLANS_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetStyles(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(callConfigure, "POST", `getstyles`, {});

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_STYLES_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: GET_STYLES_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetPostMethod(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callConfigure,
      "POST",
      `getdirectpostmethod`,
      {}
    );

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_POST_METHOD_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data.Message;
    yield put({
      type: GET_POST_METHOD_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doUpdatePostMethod(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callConfigure,
      "POST",
      `updatedirectpostmethod`,
      action.data
    );

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: UPDATE_POST_METHOD_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: UPDATE_POST_METHOD_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doUpdateStyles(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    localStorage.setItem("loader", action.data.icon_color || "red");
    const answerBackend = yield call(
      callConfigure,
      "POST",
      `updatestyles`,
      action.data
    );

    const status = answerBackend.status;
    const data = answerBackend.data;
    const styles = action.data;

    yield put({
      type: UPDATE_STYLES_SUCCESS,
      status,
      data,
      styles,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: UPDATE_STYLES_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doUploadBackgroundImage(action) {
  try {
    yield put({ type: "START_LOADING" });
    const formData = new FormData();
    formData.append("image", action.data);
    console.log(action);

    const answerBackend = yield call(
      callConfigureImages,
      "POST",
      `uploadbackgroundimage`,
      formData
    );

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: UPLOAD_BACKGROUND_IMAGE_SUCCESS,
      status,
      data,
    });

    if (status === 200) {
      try {
        const answerBackendSecond = yield call(
          callConfigure,
          "POST",
          `getimages`,
          {}
        );
        const statusSecond = answerBackendSecond.status;
        const dataSecond = answerBackendSecond.data;
        yield put({
          type: GET_IMAGES_SUCCESS,
          status: statusSecond,
          data: dataSecond,
        });
      } catch (error) {
        const message = error.response.data.Message;
        yield put({
          type: GET_IMAGES_FAILED,
          message,
        });
        yield put({ type: "END_LOADING" });
      }
    }

    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = {
      Message: "You cannot upload this image",
      Status: 403,
    };
    yield put({
      type: UPLOAD_BACKGROUND_IMAGE_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doUploadLogoImage(action) {
  try {
    yield put({ type: "START_LOADING" });

    const formData = new FormData();
    formData.append("image", action.data);
    console.log(action);

    const answerBackend = yield call(
      callConfigureImages,
      "POST",
      `uploadlogoimage`,
      formData
    );

    const status = answerBackend.status;
    const data = answerBackend.data;
    yield put({
      type: UPLOAD_LOGO_IMAGE_SUCCESS,
      status,
      data,
    });

    if (status === 200) {
      try {
        const answerBackendSecond = yield call(
          callConfigure,
          "POST",
          `getimages`,
          {}
        );

        const statusSecond = answerBackendSecond.status;
        const dataSecond = answerBackendSecond.data;

        yield put({
          type: GET_IMAGES_SUCCESS,
          status: statusSecond,
          data: dataSecond,
        });
      } catch (error) {
        const data = {
          Message: "You cannot upload this image",
          Status: 403,
        };
        yield put({
          type: GET_IMAGES_FAILED,
          data,
        });
        yield put({ type: "END_LOADING" });
      }
    }

    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: UPLOAD_LOGO_IMAGE_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetImages(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(callConfigure, "POST", `getimages`, {});
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_IMAGES_SUCCESS,
      status,
      data,
    });

    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: GET_IMAGES_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

export function* configureSaga() {
  yield takeEvery(GET_API_KEYS, doGetApiKeys);
  yield takeEvery(UPDATE_API_KEYS, doUpdateApiKeys);
  yield takeEvery(SYNC_PLANS, doSyncPlans);
  yield takeEvery(GET_STYLES, doGetStyles);
  yield takeEvery(UPDATE_STYLES, doUpdateStyles);
  yield takeEvery(UPLOAD_BACKGROUND_IMAGE, doUploadBackgroundImage);
  yield takeEvery(UPLOAD_LOGO_IMAGE, doUploadLogoImage);
  yield takeEvery(GET_IMAGES, doGetImages);
  yield takeEvery(GET_POST_METHOD, doGetPostMethod);
  yield takeEvery(UPDATE_POST_METHOD, doUpdatePostMethod);
  yield takeEvery(GET_EVENT_LISTENER_KEYS, doGetEventListenerKeys);
  yield takeEvery(UPDATE_EVENT_LISTENER_KEYS, doUpdateEventListenerKeys);
}

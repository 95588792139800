import { Grid, Typography, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputMask from "react-input-mask";

import { handleFormState } from "../../../../../helpers/HandleFormState";
import { expDate, expMonth } from "../../../../../shared/data/dataForSelectCard";

const FormPaymentDetails = ({ setFormState, formState, month, setMonth, date, setDate, hasError }) => {
  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
  };
  return (
    <form id="AriaPay" name="payment_info" action="" method="" target="_new" style={{ marginTop: 20 }}>
      <Grid container justify="space-between">
        <Grid item xs={12} sm={5}>
          <Typography variant="subtitle1" color="textPrimary" className="input-title">
            Name on card *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Name on card"
            variant="outlined"
            size="medium"
            name="nameOnCard"
            fullWidth
            type="text"
            helperText={hasError("nameOnCard") ? formState.errors.nameOnCard[0] : null}
            error={hasError("nameOnCard")}
            value={formState.values.nameOnCard || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography variant="subtitle1" color="textPrimary" className="input-title">
            Card number *
          </Typography>
          <InputMask
            mask={`9999 9999 9999 9999 9999`}
            maskChar=" "
            value={formState.values.cardNumber || ""}
            onChange={(event) => handleChange(event)}
          >
            {() => (
              <TextField
                className="height-for-valid"
                placeholder="Your card number"
                variant="outlined"
                size="medium"
                name="cardNumber"
                fullWidth
                type="text"
                helperText={hasError("cardNumber") ? formState.errors.cardNumber[0] : null}
                error={hasError("cardNumber")}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography variant="subtitle1" color="textPrimary" className="input-title">
            Expiry Date *
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <FormControl variant="outlined" className="form-control">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={date}
                onChange={(event) => setDate(event.target.value)}
                label="Date"
              >
                {expDate.map((item, idx) => (
                  <MenuItem value={item.value} key={idx + item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={month}
                onChange={(event) => setMonth(event.target.value)}
                label="Month"
              >
                {expMonth.map((item, idx) => (
                  <MenuItem value={item.value} key={idx + item.value}>
                    {item.month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography variant="subtitle1" color="textPrimary" className="input-title">
            CVV *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your CVV"
            variant="outlined"
            size="medium"
            name="securityCode"
            fullWidth
            type="text"
            helperText={hasError("securityCode") ? formState.errors.securityCode[0] : null}
            error={hasError("securityCode")}
            value={formState.values.securityCode || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
      </Grid>
    </form>
  );
};
export default FormPaymentDetails;

import { useState } from "react";

import {
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { getEventListenerKeySite } from "../../../helpers/Selector";
import { updateEventListenerKeys } from "../../../redux/actions/Configure";
import LoaderComponent from "../../../shared/LoaderComponent/LoaderComponent";
import ModalWindow from "../../../shared/ModalWindow/ModalWindow";

const EventLogKeys = () => {
  const dispatch = useDispatch();
  const eventListenerId = useSelector(getEventListenerKeySite);
  const [eventListenerKey, setEventListenerKey] = useState(
    eventListenerId || ""
  );
  const [password, setPassword] = useState("");

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div style={{ paddingTop: 20 }}>
      
      
        <>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} sm={6} className="wrap-input-typo-key">
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className="input-title"
                style={{ marginBottom: 5, fontSize: 20 }}
              >
                Event Listener Key
              </Typography>
              <TextField
                className="height-for-valid"
                placeholder="Event Listener Key"
                variant="outlined"
                size="medium"
                name="EventListenerKey"
                fullWidth
                type="text"
                value={eventListenerKey}
                onChange={(event) => setEventListenerKey(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className="input-title"
                style={{ marginBottom: 5, fontSize: 20 }}
              >
                Event Listener Key Password
              </Typography>
              <TextField
                className="height-for-valid"
                placeholder="Event Listener Key Password"
                variant="outlined"
                size="medium"
                name="eventListenerKeyPassword"
                fullWidth
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={() =>
              dispatch(updateEventListenerKeys({ eventListenerKey, password }))
            }
          >
            Save
          </Button>
        </>
        {!eventListenerId && <ModalWindow message='Event Log Key is incorrect. Please recover it' status={1}/>}
        {/* <LoaderComponent /> */}
    </div>
  );
};
export default EventLogKeys;

import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./Notifications.css";
import { getEventLog } from "../../../helpers/Selector";
import TableBillingInvoice from "../BillingInvoice/TableBillingInvoice";

const Notifications = (props) => {
  const history = useHistory();
  const eventLogs = useSelector(getEventLog);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div className="wrap-notification">
      {eventLogs && !!eventLogs.length ? (
        <TableBillingInvoice history={eventLogs} eventLog={true} notification={true} />
      ) : (
        <div className="non-notifications">Sorry, but you don't have notifications</div>
      )}
    </div>
  );
};

export default Notifications;

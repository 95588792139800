import {
  ACTIVATE_PROFILE_USER,
  ACTIVATE_PROFILE_USER_FAILED,
  ACTIVATE_PROFILE_USER_SUCCESS,
  CLEAR_STATUSES,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_FAILED,
  LOGOUT_USER_SUCCESS,
  PREREGISTER_USER,
  PREREGISTER_USER_FAILED,
  PREREGISTER_USER_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_SUCCESS,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESS,
} from "../actionNames/Auth";

export const initialState = {
  authorizedUser: false,
  preRegisterUser: {},
  statusAction: {
    status: "",
    message: "",
  },
  user: null,
  loginUserInfo: {},
  refreshToken: null,
  isPolling: false,
  loginUser: null,
  statusCheck: null,
  errorMessageLogin: null,
  errorMessageRegister: null,
  activateProfileUser: {
    status: "",
    message: "",
  },
  isAuth: false,
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER: {
      return {
        ...state,
        loginUser: action.data,
      };
    }
    case LOGIN_USER_SUCCESS: {
      console.log(action);

      let dataForLoginUser = {};
      if (action.sessionId) {
        dataForLoginUser = {
          ...action.data,
          session_id: action.sessionId,
        };
      } else {
        localStorage.setItem("sessionId", action.data.session_id);
        dataForLoginUser = action.data;
      }
      return {
        ...state,
        statusCheck: action.status,
        loginUserInfo: dataForLoginUser,
        errorMessageLogin: null,
      };
    }
    case LOGIN_USER_FAILED: {
      return {
        ...state,
        errorMessageLogin: action.message,
      };
    }

    case LOGOUT_USER: {
      localStorage.removeItem("sessionId");
      return {
        ...initialState,
      };
    }
    case LOGOUT_USER_SUCCESS: {
      localStorage.removeItem("sessionId");
      return {
        ...initialState,
      };
    }
    case LOGOUT_USER_FAILED: {
      localStorage.removeItem("sessionId");
      return {
        ...initialState,
      };
    }

    case PREREGISTER_USER: {
      return {
        ...state,
        preRegisterUser: action.data,
      };
    }
    case PREREGISTER_USER_SUCCESS: {
      return {
        ...state,
        statusCheck: action.status,
        errorMessageRegister: null,
      };
    }
    case PREREGISTER_USER_FAILED: {
      return {
        ...state,
        errorMessageRegister: action.message,
      };
    }

    case REGISTER_USER_SUCCESS: {
      const user = state.preRegisterUser;
      localStorage.setItem("registerUser", JSON.stringify(user));
      return {
        ...state,
        authorizedUser: user,
        user: action.data,
        statusAction: {
          status: "",
          message: "",
        },
      };
    }
    case REGISTER_USER_FAILED: {
      return {
        ...state,
        statusAction: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case ACTIVATE_PROFILE_USER: {
      return {
        ...state,
      };
    }
    case ACTIVATE_PROFILE_USER_SUCCESS: {
      const currentUser = JSON.parse(localStorage.getItem("registerUser"));
      localStorage.setItem("authUser", JSON.stringify(currentUser));

      return {
        ...state,
        activateProfileUser: {
          message: action.data.Message,
          status: action.data.Status,
        },
        isAuth: true,
      };
    }
    case ACTIVATE_PROFILE_USER_FAILED: {
      return {
        ...state,
        activateProfileUser: {
          message: action.data.Message,
          status: action.data.Status,
        },
      };
    }

    case CLEAR_STATUSES: {
      return {
        ...state,
        activateProfileUser: null,
      };
    }

    case REFRESH_TOKEN: {
      return {
        ...state,
      };
    }
    case REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
      };
    }
    case REFRESH_TOKEN_FAILED: {
      return {
        ...state,
        refreshToken: action.status,
      };
    }

    default:
      return state;
  }
}
export const auth = (state) => state.auth;

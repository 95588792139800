import { useEffect } from "react";

import { Grid, Button, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import "./PaymentInfo.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getCurrentPaymentMethod } from "../../../../helpers/Selector";
import visa from "../../../../images/visa.png";
import { getPaymentMethod, removePaymentMethod } from "../../../../redux/actions/Payment";

const PaymentInfo = () => {
  const paymentMethod = useSelector(getCurrentPaymentMethod);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaymentMethod());
  }, []);

  return (
    <div className="wrap-payment-info">
      <>
        <div className="wrap-content">
          <div className="content-payment-info">
            <LockOutlinedIcon color="secondary" fontSize="large" className="icon-lock" />
            <div className="title">Manage payment info</div>
            <div>Edit your payment details, add a backup, or switch your preferred payment method</div>
            {paymentMethod ? (
              <div className="wrap-view-payment-method">
                {paymentMethod.map((item, idx) => (
                  <div className="payment-method" key={idx}>
                    <div className="card-view-payment-method">
                      <div style={{ backgroundImage: `url(${visa})` }} className="image-billing-invoice"></div>
                      <div>•••• •••• •••• {item.suffix}</div>
                    </div>
                    <div className="actions-view-payment-method">
                      <div className="action-buttons-view-payment-method">
                        <Button color="primary" onClick={() => history.push("/account/PaymentEdit", { item })}>
                          Edit
                        </Button>
                        {paymentMethod.length === 1 ? (
                          <Tooltip title="To delete, add another payment method first">
                            <div>
                              <Button
                                disabled={true}
                                color="secondary"
                                onClick={() => {
                                  dispatch(removePaymentMethod(item.payment_method_no));
                                  dispatch(getPaymentMethod());
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </Tooltip>
                        ) : (
                          <Button
                            color="secondary"
                            onClick={() => {
                              dispatch(removePaymentMethod(item.payment_method_no));
                              dispatch(getPaymentMethod());
                            }}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                <div></div>
              </div>
            ) : (
              <div>you have no any payment method now</div>
            )}

            <div className="wrap-button-add-payment">
              <Button variant="outlined" color="primary" onClick={() => history.push("/account/paymentAdd")}>
                <AddIcon />
                <span>Add payment method</span>
              </Button>
            </div>
          </div>
        </div>
        <Grid item container justify="space-between" xs={12}>
          <Button type="submit" color="secondary" size="large" onClick={() => history.push("/account/settings")}>
            cancel
          </Button>
        </Grid>
      </>
    </div>
  );
};
export default PaymentInfo;

import { useEffect, useState } from "react";

import { Typography, Grid, Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import validate from "validate.js";

import { handleFormState, handleFormStateUseEffect } from "../../../../helpers/HandleFormState";
import { getErrorMessageLogin, getGroupsPlans } from "../../../../helpers/Selector";
import { loginUser } from "../../../../redux/actions/Auth";
import { schema } from "./validationSchema";

import "./Form.css";

const FormSignIn = ({ checkout, onNext }) => {
  const errorMessageLogin = useSelector(getErrorMessageLogin);
  const dispatch = useDispatch();
  const history = useHistory();
  const groups = useSelector(getGroupsPlans)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.isValid && !checkout) {
      dispatch(loginUser(formState.values, history, { checkout: false }));
    }
    if (formState.isValid && checkout) {
      onNext("sign-in", formState);
    }
    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div className="root-sign-in">
      {checkout ? <div className="title-checkout-login">Login</div> : null}
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary" className="input-title">
              E-mail
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="E-mail"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError("email") ? formState.errors.email[0] : null}
              error={hasError("email")}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary" className="input-title">
              Password
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Password"
              variant="outlined"
              size="medium"
              name="password"
              fullWidth
              helperText={hasError("password") ? formState.errors.password[0] : null}
              error={hasError("password")}
              onChange={handleChange}
              type="password"
              value={formState.values.password || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                * accounts registration created with{" "}
                <Link to={!!groups ? `/choose-plan/${groups[0].group_no}`: '/'} className="link-navigation">
                  purchase of currently available plans or service
                </Link>
              </Typography>
            </i>

            <div className="text-error">{errorMessageLogin}</div>
          </Grid>

          <Grid item xs={12}>
            {checkout ? (
              <div>
                <Button size="large" variant="contained" type="submit" color="primary" fullWidth>
                  Submit
                </Button>
              </div>
            ) : (
              <>
                <Button size="large" variant="contained" type="submit" color="primary" fullWidth>
                  Send
                </Button>

                <div className="button-forgot-password">
                  <Link to="/password-reset" className="link-navigation">
                    Forgot password?
                  </Link>
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </form>
    </div>
  );
};

export default FormSignIn;

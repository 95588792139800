import "./Plans.css";
const Plans = () => {
  

  return (
    <div className="wrap-plans">
      No Plans Available
    </div>
  );
};

export default Plans;

import { GET_ALL_ACCOUNT_PLANS, GET_BILLING_INVOICE, GET_INVOICE_DETAILS } from "../actionNames/BillingInvoice";

export function getBillingInvoice(data) {
  return { type: GET_BILLING_INVOICE, data };
}
export function getInvoiceDetails(data, history) {
  return { type: GET_INVOICE_DETAILS, data, history };
}
export function getAllAccountPlans(data) {
  return { type: GET_ALL_ACCOUNT_PLANS, data };
}

import { useEffect, useState } from "react";

import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { addToCart, deleteFromCart } from "../../../../redux/actions/Products";
import ContentToast from "../../../../shared/Notification/ContentToast";

const SupplementalPlan = ({
  supplemental,
  isAllAdded,
  parentPlan,
  currency,
}) => {
  const [isAdded, setIsAdded] = useState(isAllAdded);
  const dispatch = useDispatch();
  const handleAdd = (item, plan) => {
    setIsAdded(!isAdded);
    if (!isAdded) {
      const price = item.amount;
      const quantity = 1;
      const currency = item.schedule_currency;
      const plan_no = plan;
      const title = item.schedule_name;
      const isSupplemental = true;

      let objectForSend = {
        ...item,
        price,
        quantity,
        title,
        currency,
        plan_no,
        isSupplemental,
        parentPlan,
      };

      toast(<ContentToast title={title} schedule_name={item.schedule_name} />);
      dispatch(addToCart(objectForSend));
    } else {
      dispatch(deleteFromCart(item));
    }
  };

  useEffect(() => {
    setIsAdded(isAllAdded);
  }, [isAllAdded]);

  return (
    <div className="block-supplemental-plan">
      <div className="name-description-supplemental-plan">
        <div className="name-supplemental-plan">
          {supplemental[0].schedule_name}
        </div>
        <div className="description-supplemental-plan">
          Description for supplemental plans
        </div>
      </div>
      <div className="amount-add-supplemental-plan">
        <div className="amount-supplemental-plan">
          <div>
            {currency}
            {supplemental[0].amount.toFixed(2)}/month
          </div>
        </div>
        <div className="add-supplemental-plan">
          {isAdded ? (
            <Button
              color="secondary"
              onClick={() => handleAdd(supplemental[0])}
            >
              Remove
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAdd(supplemental[0], supplemental.plan_no)}
            >
              Add
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default SupplementalPlan;

import { put, takeEvery, call } from "redux-saga/effects";

import { callPlan, callPlanGroups } from "../../service/apiService/apiService";
import {
  GET_ALL_PLANS,
  GET_ALL_PLANS_FAILED,
  GET_ALL_PLANS_SUCCESS,
  GET_GROUPS,
  GET_GROUPS_FAILED,
  GET_GROUPS_SUCCESS,
  GET_SUPPLEMENTAL_PLANS,
  GET_SUPPLEMENTAL_PLANS_FAILED,
  GET_SUPPLEMENTAL_PLANS_SUCCESS,
} from "../actionNames/Plan";

function* doGetPlan(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(callPlan, "POST", `getallplans`, {});
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_ALL_PLANS_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: GET_ALL_PLANS_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetPlanGroups(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(callPlanGroups, "POST", `getgroups`, {});
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_GROUPS_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: GET_GROUPS_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetSupplementalPlans(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callPlan,
      "POST",
      `getsupplementalplans`,
      action.data
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_SUPPLEMENTAL_PLANS_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: GET_SUPPLEMENTAL_PLANS_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

export function* planSaga() {
  yield takeEvery(GET_ALL_PLANS, doGetPlan);
  yield takeEvery(GET_GROUPS, doGetPlanGroups);
  yield takeEvery(GET_SUPPLEMENTAL_PLANS, doGetSupplementalPlans);
}

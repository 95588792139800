import { useEffect, useRef, useState } from "react";

import {
  useMediaQuery,
  Grid,
  Typography,
  Button,
  Paper,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import "./CategoriesPlan.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  getCurrencySymbol,
  getItemsInCart,
  getLoginUserInfo,
  getPlans,
  getPreRegisterUser,
  getSupplemental,
} from "../../../helpers/Selector";
import { ReactComponent as Logo } from "../../../images/shop.svg";
import { getAllPlans, getSupplementalPlans } from "../../../redux/actions/Plan";
import { addToCart, deleteFromCart } from "../../../redux/actions/Products";
import { Icon } from "../../../shared/atoms";
import LoaderComponent from "../../../shared/LoaderComponent/LoaderComponent";
import ContentToast from "../../../shared/Notification/ContentToast";
import CardPricingStandard from "../../../shared/organisms/CardPricingStandard/CardPricingStandard";
import SupplementalPlan from "./SupplementalPlan/SupplementalPlan";

const CategoriesPlan = (props) => {
  const getItemsCart = useSelector(getItemsInCart);
  const plans = useSelector(getPlans);
  const loginUserInfo = useSelector(getLoginUserInfo);
  const preRegisterUser = useSelector(getPreRegisterUser);
  const currencySymbol = useSelector(getCurrencySymbol);
  const supplementalPlans = useSelector(getSupplemental);
  const dispatch = useDispatch();
  const history = useHistory();

  const myRef = useRef(null);
  const [isOpenSupplemental, setIsOpenSupplemental] = useState(false);
  const [isAllAdded, setIsAllAdded] = useState(false);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const currentGroup = history.location.pathname.split("/", 3).join("/");
  const currentId = history.location.pathname.split("/", 4)[3];
  let rate_sched = {};
  let title = "";
  if (!plans) {
    dispatch(getAllPlans());
  } else {
    plans.forEach((item) => {
      if (+item.plan_no === +currentId) {
        title = item.plan_name;
        rate_sched = item.rate_sched;
      }
    });
  }

  const handleCheckout = (event) => {
    if (loginUserInfo.session_id || preRegisterUser.email) {
      history.push("/checkout/shipping-address");
    } else {
      history.push("/checkout/account");
    }
  };

  const features = [
    "Rich, responsive landing pages",
    "100+ styled components",
    "Flexible, simple license",
    "Speedy build tooling",
    "6 months free support included",
    "Rich, responsive landing pages",
    "100+ styled components",
  ];
  useEffect(() => {
    setTimeout(() => {
      if (myRef.current !== null) {
        myRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 100);

    if (supplementalPlans && !supplementalPlans.length) {
      setIsOpenSupplemental(false);
    } else {
      setIsOpenSupplemental(true);
    }
  }, [supplementalPlans]);

  const handleBuyPlan = (item, plan, buyAllPlans) => {
    // setIsOpenSupplemental(!isOpenSupplemental);

    const price = item.amount;
    const quantity = 1;
    const currency = item.schedule_currency;
    const plan_no = plan || +currentId;
    const parentPlan = plan || null;
    const isSupplemental = plan ? true : false;

    let objectForSend = {
      ...item,
      price,
      quantity,
      title,
      currency,
      isSupplemental,
      parentPlan,
      plan_no,
    };
    const isSamePlan = getItemsCart.find(
      (i) => i.schedule_no === item.schedule_no
    );
    const isSame = getItemsCart.find((i) => i.plan_no === plan_no);
    if (!isSame && !isSamePlan) {
      toast(<ContentToast title={title} schedule_name={item.schedule_name} />);
    }
    dispatch(getSupplementalPlans({ plan_no: plan_no, currency: currency }));
    dispatch(addToCart(objectForSend));
  };
  const handleAddAllOptions = () => {
    setIsAllAdded(!isAllAdded);
    if (!isAllAdded) {
      supplementalPlans.forEach((item) => {
        handleBuyPlan(item[0], item.plan_no);
      });
    } else {
      supplementalPlans.forEach((item) => {
        dispatch(deleteFromCart(item[0]));
      });
    }
  };
  return (
    <div style={{ padding: 40, width: "100%" }}>
      {plans ? (
        <>
          <div className="categories-wrap-title-description">
            <div className="categories-plans-title">{title}</div>
            <div className="categories-plans-description">Choose your Plan</div>
            {!rate_sched.details.length ? (
              <div className="categories-plans-error">
                Sorry, but there are no plans offered within the currency you
                selected
              </div>
            ) : null}
          </div>

          <Grid container spacing={isMd ? 4 : 2} justify="center">
            {rate_sched.details.map((item, index) => (
              <Grid item xs={12} md={3} data-aos="fade-up" key={index}>
                <CardPricingStandard
                  variant="outlined"
                  withShadow={true}
                  liftUp
                  title={item.schedule_name}
                  subtitle="Rate Schedule"
                  priceComponent={
                    <div>
                      <Typography variant="h3" component="span">
                        {currencySymbol}
                        {item.amount.toFixed(2)}
                      </Typography>
                      <Typography component="span" variant="subtitle1">
                        / {item.usage_billing_period_type === 1 ? "D" :
                          item.usage_billing_period_type === 2 ? 'WK' : 
                          'MO'}
                      </Typography>
                    </div>
                  }
                  features={features.slice(index * 2)}
                  featureCheckComponent={
                    <Icon
                      fontIconClass="far fa-check-circle"
                      fontIconColor={theme.palette.primary.main}
                    />
                  }
                  cta={
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      size="large"
                      className="btn"
                      onClick={() => handleBuyPlan(item)}
                    >
                      Choose this Plan
                    </Button>
                  }
                />
              </Grid>
            ))}
          </Grid>

          <div className="supplemental-plans-wrap">
            {isOpenSupplemental && supplementalPlans ? (
              <Paper className="paper-supplemental-plans" ref={myRef}>
                {supplementalPlans.length ? (
                  <div className="header-supplemental-plans">
                    <div>What options do you need?</div>
                    <div className="header-supplemental-plans-button">
                      {isAllAdded ? (
                        <Button color="secondary" onClick={handleAddAllOptions}>
                          remove all options
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleAddAllOptions}
                        >
                          Add all options
                        </Button>
                      )}
                    </div>
                  </div>
                ) : null}

                <div className="wrap-blocks-supplemental-plans">
                  {supplementalPlans.map((item, idx) => (
                    <SupplementalPlan
                      key={idx}
                      supplemental={item}
                      isAllAdded={isAllAdded}
                      parentPlan={+currentId}
                      currency={currencySymbol}
                    />
                  ))}
                  <div className="wrap-add-all-options">
                    <Button
                      color="secondary"
                      onClick={() => history.push(currentGroup)}
                    >
                      Continue shopping
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCheckout}
                    >
                      <Logo style={{ marginRight: 5, color: "white" }} />{" "}
                      checkout
                    </Button>
                  </div>
                </div>
              </Paper>
            ) : null}
          </div>
        </>
      ) : (
        <LoaderComponent />
      )}
      {!isOpenSupplemental ? (
        <Button
          color="secondary"
          style={{ marginTop: 20 }}
          onClick={() => history.push(currentGroup)}
        >
          Back
        </Button>
      ) : null}
    </div>
  );
};

export default CategoriesPlan;

import {
  ADD_PAYMENT_METHOD,
  CHECK_TAXES,
  GET_PAYMENT_METHOD,
  POST_PAYMENT,
  REMOVE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD,
} from "../actionNames/Payment";

export function postPayment(data, history) {
  return { type: POST_PAYMENT, data, history };
}

export function getPaymentMethod(data) {
  return { type: GET_PAYMENT_METHOD, data };
}

export function removePaymentMethod(data) {
  return { type: REMOVE_PAYMENT_METHOD, data };
}

export function updatePaymentMethod(data, history) {
  return { type: UPDATE_PAYMENT_METHOD, data, history };
}

export function addPaymentMethod(data) {
  return { type: ADD_PAYMENT_METHOD, data };
}

export function checkTaxes(data) {
  return { type: CHECK_TAXES, data };
}

import { useEffect, useState } from "react";

import { Button, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import "./FilterContent.css";
import { useDispatch } from "react-redux";

import useDebounce from "../../../helpers/useDebounce";
import { clearFilterPlanGroups, filterPlanGroups } from "../../../redux/actions/Plan";

const FilterContent = ({ onClose }) => {
  const [debounceValue, setDebounceValue] = useState("");
  const dispatch = useDispatch();
  const changeInput = (event) => {
    setDebounceValue(event.target.value);
  };
  const searchValue = useDebounce(debounceValue, 1000);

  useEffect(() => {
    if (searchValue) {
      dispatch(filterPlanGroups(searchValue));
    }
  }, [searchValue]);

  const handleClearFilter = () => {
    dispatch(clearFilterPlanGroups());
  };
  return (
    <div className="wrap-filter-content">
      <div className="wrap-button-filter-content">
        <Button color="secondary" onClick={handleClearFilter}>
          clear filter
        </Button>
        <IconButton component="div" onClick={onClose}>
          <CloseOutlinedIcon fontSize="default" />
        </IconButton>
      </div>
      <div>
        <div>
          <Typography variant="subtitle1" color="textPrimary" className="input-title">
            Plan category
          </Typography>
          <TextField id="standard-basic" style={{ width: "100%" }} onChange={(event) => changeInput(event)} />
          {/* <div></div> */}
        </div>
        <div>
          <div></div>
          <div></div>
        </div>
        <div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default FilterContent;

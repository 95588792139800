export const GET_BILLING_INVOICE = "GET_BILLING_INVOICE";
export const GET_BILLING_INVOICE_SUCCESS = "GET_BILLING_INVOICE_SUCCESS";
export const GET_BILLING_INVOICE_FAILED = "GET_BILLING_INVOICE_FAILED";

export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
export const GET_INVOICE_DETAILS_SUCCESS = "GET_INVOICE_DETAILS_SUCCESS";
export const GET_INVOICE_DETAILS_FAILED = "GET_INVOICE_DETAILS_FAILED";

export const GET_ALL_ACCOUNT_PLANS = "GET_ALL_ACCOUNT_PLANS";
export const GET_ALL_ACCOUNT_PLANS_SUCCESS = "GET_ALL_ACCOUNT_PLANS_SUCCESS";
export const GET_ALL_ACCOUNT_PLANS_FAILED = "GET_ALL_ACCOUNT_PLANS_FAILED";

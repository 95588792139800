import { Switch, Route } from "react-router-dom";

import AccountSignInSignUp from "../StepperData/Account/AccountSignInSignUp";
import PaymentDetails from "../StepperData/PaymentDetails/PaymentDetails";
import ReviewOrder from "../StepperData/ReviewOrder/ReviewOrder";
import ShippingAddress from "../StepperData/ShippingAddress/ShippingAddress";

export default function StepperCheckoutContent({ handleNext, handleBack, handleFinish, errorMessageRegister, errorMessageLogin }) {
  return (
    <Switch>
      <Route
        path="/checkout/account"
        render={() => (
          <AccountSignInSignUp onNext={handleNext} errorMessageRegister={errorMessageRegister} errorMessageLogin={errorMessageLogin} />
        )}
      />
      <Route path="/checkout/shipping-address" render={() => <ShippingAddress onNext={handleNext} onBack={handleBack} />} />
      <Route path="/checkout/payment" render={() => <PaymentDetails onNext={handleNext} onBack={handleBack} />} />
      <Route path="/checkout/review" render={() => <ReviewOrder onFinish={handleFinish} onBack={handleBack} />} />
    </Switch>
  );
}

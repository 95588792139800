import "./PaymentAdd.css";
import { Grid, Button } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useHistory } from "react-router-dom";

import CardJobMinimal from "../../../../shared/organisms/CardJobMinimal/CardJobMinimal";

const PaymentAdd = () => {
  const history = useHistory();

  return (
    <div className="wrap-payment-add">
      <div className="wrap-content">
        <div className="content-payment-add">
          <LockOutlinedIcon color="secondary" fontSize="large" className="icon-lock" />
          <div className="title">Manage payment info</div>
          <div style={{ width: "60%" }}>
            <CardJobMinimal title="Credit or Debit Cart" showArrow onClick={() => history.push("/account/paymentData")} />
          </div>
        </div>
      </div>
      <Grid item container justify="space-between" xs={12}>
        <Button type="submit" color="secondary" size="large" onClick={() => history.push("/account/paymentInfo")}>
          back
        </Button>
      </Grid>
    </div>
  );
};
export default PaymentAdd;

import { Button } from "@material-ui/core";
import "./UserOptions.css";
import { useHistory } from "react-router-dom";

import Logout from "../../Logout/Logout";

const UserOptions = ({ isLogin, sessionId, handleClose }) => {
  const history = useHistory();
  const handleLogin = () => {
    history.push("/sign-in");
    handleClose();
  };
  return (
    <div className="wrap-user-options">
      {!isLogin ? (
        <Button size="large" color="primary" onClick={handleLogin}>
          login
        </Button>
      ) : (
        <Logout sessionId={sessionId} handleClose={handleClose} />
      )}
    </div>
  );
};
export default UserOptions;

export const FILTER_PLAN_GROUPS = "FILTER_PLAN_GROUPS";

export const CLEAR_FILTER_PLAN_GROUPS = "CLEAR_FILTER_PLAN_GROUPS";

export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const GET_ALL_PLANS_SUCCESS = "GET_ALL_PLANS_SUCCESS";
export const GET_ALL_PLANS_FAILED = "GET_ALL_PLANS_FAILED";

export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED";

export const GET_SUPPLEMENTAL_PLANS = "GET_SUPPLEMENTAL_PLANS";
export const GET_SUPPLEMENTAL_PLANS_SUCCESS = "GET_SUPPLEMENTAL_PLANS_SUCCESS";
export const GET_SUPPLEMENTAL_PLANS_FAILED = "GET_SUPPLEMENTAL_PLANS_FAILED";

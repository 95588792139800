import { useEffect, useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import moment from "moment";
import { useSelector } from "react-redux";
import "./PlanSelectGroup.css";
import { useHistory } from "react-router";

import { getGroupsPlans } from "../../helpers/Selector";
import LoaderComponent from "../LoaderComponent/LoaderComponent";

const PlanSelectGroup = () => {
  const planGroups = useSelector(getGroupsPlans);
  const history = useHistory();

  const [group, setGroup] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    if (group) {
      console.log('group', group);
      history.push(`/choose-plan/${group.group_no}`);
    }
  }, [group, date]);

  const handleChange = (event) => {
    const currentDate = moment().format();
    console.log('event', event.target.value);
    setGroup(event.target.value || group);
    setDate(currentDate);
  };

  return (
    <div className="wrap-plan-select-group">
      {planGroups ? (
        <FormControl variant="outlined" className="plan-select-group">
          <InputLabel id="demo-simple-select-outlined-label">
            PLANS & SERVICES
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={group}
            onClick={handleChange}
            label="PLANS & SERVICES"
            className="select-group"
            inputProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {planGroups.map((item, idx) => (
              <MenuItem
                key={item.group_no + idx + Math.floor(Math.random() * 100)}
                value={item}
              >
                {item.group_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};

export default PlanSelectGroup;

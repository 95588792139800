import { useEffect, useMemo } from "react";

import "./NavigationPanel.css";
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { getLoginUserInfo, getGroupsPlans } from "../../helpers/Selector";
import { activateProfileUser } from "../../redux/actions/Auth";
import PlanSelectGroup from "../../shared/PlansSelectGroup/PlanSelectGroup";
import TemporaryDrawer from "../../shared/SideBar/SideBar";
import SelectorCurrency from "../SelectorCurrency/SelectorCurrency";
import User from "../User/User";

const NavigationPanel = () => {
  const loginUserInfo = useSelector(getLoginUserInfo);
  const groups = useSelector(getGroupsPlans)
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const history = useHistory();
  const search = useLocation().search;

  useEffect(() => {
    if (location === "/activateprofile") {
      dispatch(activateProfileUser(search));
    }
  }, [location, search]);

  const handleRedirect = (path) => {
    if (path === "/account/settings") {
      if (!loginUserInfo.userid) {
        let pathLocation = "/account/settings";
        history.push("/sign-in", pathLocation);
      } else {
        history.push(path);
      }
    } else {
      history.push(path);
    }
  };
  const isSign = useMemo(() => {
    return location === "/sign-in" || location === "/sign-up" || location === "/password-reset" || location === "/config";
  }, [location]);

  const isCheckout = useMemo(() => {
    return location.includes("/checkout/");
  }, [location]);

  return (
    <div className="main-navigation">
      <div className={isCheckout || isSign ? "display-none" : "wrap-logo-for-small-devices"}>
        <div className="container-logo" onClick={() => handleRedirect(`/choose-plan/${groups[0].group_no}`)}>
          <div className="logo-wrap-section"></div>
        </div>
      </div>
      <div className="navigation-panel">
        <div className={isCheckout || isSign ? "display-none" : "wrap-links-mobile"}>
          <TemporaryDrawer burgerMenu={true} />
        </div>
        <div className={isCheckout || isSign ? "wrap-logo-bigger" : "wrap-logo"}>
          <div className="container-logo" onClick={() => handleRedirect(`/choose-plan/${groups[0].group_no}`)}>
            <div className="logo-wrap-section"></div>
          </div>
        </div>
        <div className={isCheckout || isSign ? "display-none" : "wrap-links-web-logout"}>
          <div className="wrap-links-web">
            <PlanSelectGroup />
            {/* <Button color="primary" size="large" onClick={() => handleRedirect("/products")}>
              <div className="link-navigation">Products</div>
            </Button> */}

            <Button color="primary" size="large" onClick={() => handleRedirect("/account/settings")}>
              <div className="link-navigation">My Account</div>
            </Button>
          </div>

          <div className="wrap-logout">
            <div>
              <SelectorCurrency />
            </div>
            <div>
              <User isLogin={loginUserInfo.userid} sessionId={loginUserInfo.session_id} />
            </div>
            <div className="cart-wrap">
              <TemporaryDrawer />
            </div>
          </div>
        </div>
        <div className={isCheckout ? "cart-wrap-mobile-checkout" : "display-none"}>
          <TemporaryDrawer checkout={true} menuCheckout={true} />
        </div>
      </div>
    </div>
  );
};
export default NavigationPanel;

import { useState, useEffect, useMemo } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { cancelAccountPlan, changeAccountPlan, closeModal } from "../../redux/actions/Account";
import { clearStatuses } from "../../redux/actions/Auth";

import "./ModalWindow.css";

const ModalWindow = ({ status, message, path, cancelPlan, plan_instance_no, schedule_no }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    setOpen(true);
  }, [status]);

  const handleClose = () => {
    setOpen(false);
    if (status === "statusAsk") {
      dispatch(cancelAccountPlan(cancelPlan));
      dispatch(closeModal(false));
    }
    if (status === "statusAskChangePlan") {
      dispatch(closeModal(false));
      dispatch(changeAccountPlan({ plan_instance_no, schedule_no }));
    } else {
      dispatch(clearStatuses());
      if (path) {
        history.push(path);
      }
    }
  };
  const handleCancel = () => {
    dispatch(closeModal(false));
  };
  const isPrimary = useMemo(() => {
    return status === 200 || status === "statusAsk" || status === "statusAskChangePlan";
  }, [status]);

  return (
    <div>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" style={{ minWidth: 400 }}>
        <DialogTitle id="alert-dialog-title">Notification</DialogTitle>
        <DialogContent className="dialog-modal-window">
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={isPrimary ? "primary" : "secondary"} autoFocus variant={isPrimary ? "contained" : "text"}>
            Ok
          </Button>
          {status === "statusAsk" || status === "statusAskChangePlan" ? (
            <Button onClick={handleCancel} color="secondary" autoFocus>
              No
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ModalWindow;

import { Button, Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./BillingInvoice.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import { useDispatch } from "react-redux";

import visa from "../../../images/visa.png";
import { getInvoiceDetails } from "../../../redux/actions/BillingInvoice";
import ResponsiveTableBillingInvoice from "./ResponsiveTableBillingInvoice";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const TableBillingInvoice = ({ history, eventLog, notification, accountSymbol }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getCard = (card) => {
    const currentCard = card.split("(")[1].split(")")[0].toLowerCase();
    if (currentCard === "visa") {
      return visa;
    } else {
      return "";
    }
  };

  const handleClick = (invoice) => {
    dispatch(getInvoiceDetails(invoice));
  };

  return (
    <>
      <div className="wrap-non-responsive-table-billing-invoice">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className="table-thead-titles">
              {eventLog ? (
                <TableRow>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Subject
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Recipient
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Date Created
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Date Sent
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Invoice status
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Payment method
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Balance
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  >
                    Charges/Payments
                  </TableCell>
                  <TableCell
                    className={`table-cell-billing-invoice ${
                      notification ? "table-cell-notification" : null
                    } `}
                  ></TableCell>
                </TableRow>
              )}
            </TableHead>

            {history.map((row, idx) =>
              eventLog ? (
                <TableBody key={idx}>
                  <TableRow>
                    <TableCell>{row.msg_class_label || ''}</TableCell>
                    <TableCell>{row.msg_subject && row.msg_subject.slice(0, 20)|| ''} ...</TableCell>
                    <TableCell>{row.msg_recipient_email_address || ''}</TableCell>
                    <TableCell component="th" scope="row">
                      {moment(row.msg_creation_date).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(row.msg_sent_date).format("MM/DD/YYYY")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody key={idx}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {moment(row.create_date).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>Invoice #{row.invoice_no}</TableCell>
                    <TableCell align="center">
                      {!!row.invoice_status ? (
                        <Tooltip title="Invoice Paid">
                          <CheckCircleOutlineIcon
                            style={{ color: "green", cursor: "pointer" }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Not Paid">
                          <HighlightOffIcon
                            style={{ color: "red", cursor: "pointer" }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {accountSymbol}{parseInt(row.total_amount, 10).toFixed(2)}
                    </TableCell>
                    <TableCell>{accountSymbol}{parseInt(row.total).toFixed(2)}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => handleClick(row.invoice_no)}
                        color="primary"
                      >
                        View and Print
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ background: "#e2e2e2" }}>
                    <TableCell>
                      {row.transaction_history && row.transaction_history.length
                        ? moment(
                            row.transaction_history[0].transaction_create_date
                          ).format("MM/DD/YYYY")
                        : ""}
                    </TableCell>
                    <TableCell>
                      {row.transaction_history && row.transaction_history.length
                        ? row.transaction_history[0].transaction_desc
                        : ""}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {" "}
                      {row.cart_info && row.cart_info.cc_string ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundImage: `url(${getCard(
                                row.cart_info.cc_string
                              )})`,
                            }}
                            className="image-billing-invoice"
                          ></div>
                          <div>•••• •••• •••• {row.cart_info.cc_suffix}</div>
                        </div>
                      ) : null}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {row.transaction_history &&
                      row.transaction_history.length &&
                      row.transaction_history[0].transaction_applied_amount
                        ? `-${accountSymbol}${row.transaction_history[0].transaction_applied_amount.toFixed(
                            2
                          )}`
                        : ""}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              )
            )}
          </Table>
        </TableContainer>
      </div>
      <ResponsiveTableBillingInvoice
        history={history}
        handleClick={handleClick}
        getCard={getCard}
        eventLog={eventLog}
        accountSymbol ={accountSymbol}
      />
    </>
  );
};
export default TableBillingInvoice;

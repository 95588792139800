import { useSelector } from "react-redux";

import { getStylesSite } from "../../helpers/Selector";
import { ReactComponent as Logo } from "../../images/loader.svg";
import "./LoaderComponent.css";

const LoaderComponent = () => {
  const styleSite = useSelector(getStylesSite);
  const loaderColor = localStorage.getItem("loader");
  return (
    <div className="wrap-loader">
      <div>
        <Logo stroke={styleSite ? styleSite.icon_color : loaderColor ? loaderColor : "#3f51b5"} />
      </div>
    </div>
  );
};
export default LoaderComponent;

import { useEffect, useState } from "react";

import clsx from "clsx";
import { useHistory } from "react-router";

import { SectionHeader } from "../../../shared/molecules";
import { Section } from "../../../shared/organisms";
import { useStyles } from "./style";

import "./Hero.css";

const Hero = () => {
  const classes = useStyles();
  const history = useHistory();
  const [title, setTitle] = useState("Account");

  useEffect(() => {
    if (history.location.pathname.includes("/account/billing-invoice")) {
      setTitle("Billing & Invoice");
    }
    if (history.location.pathname.includes("/account/email")) {
      setTitle("Email Information");
    }
    if (history.location.pathname.includes("/account/security")) {
      setTitle("Change Password");
    }
    if (history.location.pathname.includes("/account/general")) {
      setTitle("Basic Information");
    }
    if (history.location.pathname.includes("/account/paymentInfo")) {
      setTitle("Payment Info");
    }
    if (history.location.pathname.includes("/account/change-plan")) {
      setTitle("Change Plan");
    }
    if (history.location.pathname.includes("/account/notifications")) {
      setTitle("Notifications");
    }
    if (history.location.pathname.includes("/account/settings")) {
      setTitle("Account");
    }
  }, [history.location.pathname]);

  return (
    <div className="root-hero">
      <Section className="section-wrap">
        <SectionHeader
          title={title}
          align="left"
          disableGutter
          titleProps={{
            className: clsx(classes.title, classes.textWhite),
            variant: "h3",
          }}
          subtitleProps={{
            className: classes.textWhite,
          }}
        />
      </Section>
    </div>
  );
};

export default Hero;

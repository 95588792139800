import { put, takeEvery, call } from "redux-saga/effects";

import {
  callBillingInvoice,
  callAllAccountPlans,
} from "../../service/apiService/apiService";
import {
  GET_ALL_ACCOUNT_PLANS,
  GET_ALL_ACCOUNT_PLANS_FAILED,
  GET_ALL_ACCOUNT_PLANS_SUCCESS,
  GET_BILLING_INVOICE,
  GET_BILLING_INVOICE_FAILED,
  GET_BILLING_INVOICE_SUCCESS,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_FAILED,
  GET_INVOICE_DETAILS_SUCCESS,
} from "../actionNames/BillingInvoice";

function* doGetBillingInvoice(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callBillingInvoice,
      "POST",
      `getbillinginfo`,
      {},
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_BILLING_INVOICE_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    yield put({
      type: GET_BILLING_INVOICE_FAILED,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetInvoiceDetails(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);

    const history = action.history;
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callBillingInvoice,
      "POST",
      `getinvoicedetails`,
      { invoice_no: action.data },
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_INVOICE_DETAILS_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
    console.log(history);
  } catch (error) {
    yield put({
      type: GET_INVOICE_DETAILS_FAILED,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetAllAccountPlans(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);

    const history = action.history;
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callAllAccountPlans,
      "POST",
      `getallaccountplans`,
      {},
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_ALL_ACCOUNT_PLANS_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
    console.log(history);
  } catch (error) {
    yield put({
      type: GET_ALL_ACCOUNT_PLANS_FAILED,
    });
    yield put({ type: "END_LOADING" });
  }
}

export function* billingInvoiceSaga() {
  yield takeEvery(GET_BILLING_INVOICE, doGetBillingInvoice);
  yield takeEvery(GET_INVOICE_DETAILS, doGetInvoiceDetails);
  yield takeEvery(GET_ALL_ACCOUNT_PLANS, doGetAllAccountPlans);
}

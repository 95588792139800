import { put, takeEvery, call, select } from "redux-saga/effects";

import {
  callGetPaymentMethod,
  callRegisterSpecial,
} from "../../service/apiService/apiService";
import {
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESS,
} from "../actionNames/Auth";
import {
  GET_LOGGED_PAYMENT_INFO,
  GET_LOGGED_PAYMENT_INFO_FAILED,
  GET_LOGGED_PAYMENT_INFO_SUCCESS,
  LOGGED_PLACE_ORDER,
  LOGGED_PLACE_ORDER_FAILED,
  LOGGED_PLACE_ORDER_SUCCESS,
  PLACE_ORDER,
} from "../actionNames/Checkout";
import { CHECK_TAXES_SUCCESS } from "../actionNames/Payment";

function* doPlaceOrder(action) {
  const history = action.history;
  try {
    const state = yield select();
    const billingData = { ...state.checkout.billing };
    const shippingData = { ...state.checkout.shipping };
    const itemsInCart = [...state.cart.itemsInCart];
    const masterPlan = itemsInCart.filter((i) => i.schedule_no);

    const onlyMasterPlans = masterPlan.filter((i) => !i.isSupplemental);

    console.log(onlyMasterPlans);
    let planInstance = 1;
    const plans = onlyMasterPlans.map((i, idx) => {
      let ar = [];
      masterPlan.forEach((item) => {
        if (item.parentPlan === i.plan_no) {
          planInstance++;
          ar.push({
            plan_no: item.plan_no,
            plan_instance_idx: planInstance + idx,
            alt_rate_schedule_no: item.schedule_no,
          });
          console.log("PLANINSTANCE", planInstance, item.plan_no, ar.length);
        }
      });
      return {
        alt_rate_schedule_no: i.schedule_no,
        plan_no: i.plan_no,
        plan_instance_idx: idx
          ? planInstance + 1 - ar.length
          : planInstance - ar.length,
        supp_plan: ar,
      };
    });
    const objectForReq = {
      ...state.auth.preRegisterUser,
      address1: billingData.streetAddress,
      address2: billingData.addressSecond,
      city: billingData.city,
      zipCode: billingData.zipCode,
      currency: masterPlan[0].currency.toUpperCase(),
      country: billingData.country,
      state: billingData.stateProvince,
      phone: shippingData.phoneNumber || "",
      firstNameShipping: shippingData.firstName,
      lastNameShipping: shippingData.lastName,
      firstNameBilling: billingData.firstName,
      lastNameBilling: billingData.lastName,
      address1Billing: billingData.streetAddress,
      address2Billing: billingData.addressSecond,
      countryBilling: billingData.country,
      stateBilling: billingData.stateProvince,
      cityBilling: billingData.city,
      zipCodeBilling: billingData.zipCode,
      phoneBilling: shippingData.phoneNumber || "10997009376",
      plans: plans,
    };

    console.log(action);

    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callRegisterSpecial,
      "POST",
      `signup`,
      objectForReq,
      ""
    );

    console.log("objectForReq", objectForReq);
    const response = answerBackend.data;

    const newObjForReq = {
      payMethod: "Direct",
      plans: plans,
      address1: billingData.streetAddress,
      address2: billingData.addressSecond,
      city: billingData.city,
      zipCode: billingData.zipCode,
      country: billingData.country,
      phone: shippingData.phoneNumber || "",
      firstName: shippingData.firstName,
      lastName: shippingData.lastName,
      state: shippingData.stateProvince,
      firstNameBilling: billingData.firstName,
      lastNameBilling: billingData.lastName,
      address1Billing: billingData.streetAddress,
      address2Billing: billingData.addressSecond,
      countryBilling: billingData.country,
      stateBilling: billingData.stateProvince,
      cityBilling: billingData.city,
      zipCodeBilling: billingData.zipCode,
      phoneBilling: shippingData.phoneNumber || "",
    };
    console.log("newObjForReq", newObjForReq);
    const answerSecondBackend = yield call(
      callGetPaymentMethod,
      "POST",
      `checktaxesforunregistereduser`,
      newObjForReq,
      ""
    );

    const secondResponse = answerSecondBackend.data;
    console.log(secondResponse, answerSecondBackend);
    yield put({
      type: REGISTER_USER_SUCCESS,
      data: response,
    });
    yield put({
      type: CHECK_TAXES_SUCCESS,
      status: answerSecondBackend.status,
      data: secondResponse[0],
      method: "Direct",
      isUnRegister: true,
    });
    yield put({ type: "END_LOADING" });
    history.push("/checkout/payment");
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: REGISTER_USER_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doLoggedPlaceOrder(action) {
  const history = action.history;
  console.log(action);
  try {
    const state = yield select();
    const billingData = { ...state.checkout.billing };
    const shippingData = { ...state.checkout.shipping };
    const itemsInCart = [...state.cart.itemsInCart];
    const masterPlan = itemsInCart.filter((i) => i.schedule_no);
    let plans;
    let objectForReq;

    let countForMaster = 0;
    let countForSupplemental = 0;

    if (action.data.billing_group_no) {
      plans = masterPlan.map((i) => {
        if (!i.isSupplemental) {
          countForSupplemental++;
          countForMaster++;
          countForMaster = countForSupplemental;
          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            billing_group_no: action.data.billing_group_no,
            plan_instance_idx: countForSupplemental,
          };
        } else {
          countForSupplemental++;

          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            billing_group_no: action.data.billing_group_no,
            plan_instance_idx: countForSupplemental,
            parent_plan_instance_idx: countForMaster,
          };
        }
      });

      objectForReq = {
        plan_updates: plans,
        address1: billingData.streetAddress,
        address2: billingData.addressSecond,
        city: billingData.city,
        zipCode: billingData.zipCode,
        country: billingData.country,
        phone: shippingData.phoneNumber || "",
        firstName: shippingData.firstName,
        lastName: shippingData.lastName,
        state: shippingData.stateProvince,
        payMethod: action.data.payMethod,
      };
    } else {
      plans = masterPlan.map((i) => {
        if (!i.isSupplemental) {
          countForSupplemental++;
          countForMaster++;
          countForMaster = countForSupplemental;
          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            plan_instance_idx: countForSupplemental,
          };
        } else {
          countForSupplemental++;

          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            plan_instance_idx: countForSupplemental,
            parent_plan_instance_idx: countForMaster,
          };
        }
      });

      objectForReq = {
        plan_updates: plans,
        address1: billingData.streetAddress,
        address2: billingData.addressSecond,
        city: billingData.city,
        zipCode: billingData.zipCode,
        country: billingData.country,
        phone: shippingData.phoneNumber || "",
        firstName: shippingData.firstName,
        lastName: shippingData.lastName,
        state: shippingData.stateProvince,
        firstNameShipping: shippingData.firstName,
        lastNameShipping: shippingData.lastName,
        firstNameBilling: billingData.firstName,
        lastNameBilling: billingData.lastName,
        address1Billing: billingData.streetAddress,
        address2Billing: billingData.addressSecond,
        payMethod: "Direct",
        countryBilling: billingData.country,
        stateBilling: billingData.stateProvince,
        cityBilling: billingData.city,
        zipCodeBilling: billingData.zipCode,
        phoneBilling: shippingData.phoneNumber || "",
      };
    }
    console.log(action);
    const sessionId = localStorage.getItem("sessionId");
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callGetPaymentMethod,
      "POST",
      `buyplan`,
      objectForReq,
      sessionId
    );
    let response = answerBackend.data;

    countForMaster = 0;
    countForSupplemental = 0;

    if (!action.data.billing_group_no) {
      plans = masterPlan.map((i) => {
        if (!i.isSupplemental) {
          countForSupplemental++;
          countForMaster++;
          countForMaster = countForSupplemental;
          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            billing_group_no:
              response.multi_plan_invoice_details.billing_group_no,
            plan_instance_idx: countForSupplemental,
          };
        } else {
          countForSupplemental++;

          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            billing_group_no:
              response.multi_plan_invoice_details.billing_group_no,
            plan_instance_idx: countForSupplemental,
            parent_plan_instance_idx: countForMaster,
          };
        }
      });
      objectForReq = {
        plan_updates: plans,
        address1: billingData.streetAddress,
        address2: billingData.addressSecond,
        city: billingData.city,
        zipCode: billingData.zipCode,
        country: billingData.country,
        phone: shippingData.phoneNumber || "",
        firstName: shippingData.firstName,
        lastName: shippingData.lastName,
        state: shippingData.stateProvince,
        payMethod: "Billing",
      };

      const answerSecondBackend = yield call(
        callGetPaymentMethod,
        "POST",
        `buyplan`,
        objectForReq,
        sessionId
      );
      response = answerSecondBackend.data;
    }

    yield put({
      type: LOGGED_PLACE_ORDER_SUCCESS,
      data: response,
    });
    history.push("/checkout/review");
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: LOGGED_PLACE_ORDER_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetLoggedPaymentInfo(action) {
  const history = action.history;
  try {
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callGetPaymentMethod,
      "POST",
      `getpaymentinfo`,
      {},
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;
    yield put({
      type: GET_LOGGED_PAYMENT_INFO_SUCCESS,
      status,
      data,
    });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: GET_LOGGED_PAYMENT_INFO_FAILED,
      data,
    });
    console.log("EROOOOORRRRRRR");
    history.push("/sign-in");
    yield put({ type: "END_LOADING" });
  }
}

export function* checkoutSaga() {
  yield takeEvery(PLACE_ORDER, doPlaceOrder);
  yield takeEvery(LOGGED_PLACE_ORDER, doLoggedPlaceOrder);
  yield takeEvery(GET_LOGGED_PAYMENT_INFO, doGetLoggedPaymentInfo);
}

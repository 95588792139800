import { useState, useEffect } from "react";

import "./PaymentEdit.css";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import validate from "validate.js";

import { handleFormState, handleFormStateUseEffect } from "../../../../helpers/HandleFormState";
import { getAccountPaymentStatus } from "../../../../helpers/Selector";
import { updatePaymentMethod } from "../../../../redux/actions/Payment";
import { expDate, expMonth } from "../../../../shared/data/dataForSelectCard";
import { schema } from "./validationSchema";

const PaymentEdit = () => {
  const accountPaymentStatus = useSelector(getAccountPaymentStatus);
  const history = useHistory();
  const dispatch = useDispatch();

  const currentPaymentMethod = history.location.state.item;
  const data = {
    firstName: currentPaymentMethod.bill_first_name,
    lastName: currentPaymentMethod.bill_last_name,
    cardNumber: currentPaymentMethod.cc_num,
    zipCode: currentPaymentMethod.bill_postal_cd,
  };
  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...data },
    touched: {},
    errors: {},
  });

  const [month, setMonth] = useState(currentPaymentMethod ? currentPaymentMethod.cc_expire_mm : 1);
  const [date, setDate] = useState(currentPaymentMethod ? currentPaymentMethod.cc_expire_yyyy : 2021);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  const handleSave = () => {
    if (formState.isValid) {
      const dataForSend = {
        ...formState.values,
        month,
        date,
        payment_method_no: currentPaymentMethod.payment_method_no,
        pay_method_type: currentPaymentMethod.pay_method_type,
      };
      dispatch(updatePaymentMethod(dataForSend, history));
    }
  };

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
  };

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div className="wrap-content">
      <div className="content">
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textPrimary" className="input-title">
              First name *
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Your first name"
              variant="outlined"
              size="medium"
              name="firstName"
              fullWidth
              type="text"
              helperText={hasError("firstName") ? formState.errors.firstName[0] : null}
              error={hasError("firstName")}
              value={formState.values.firstName || ""}
              onChange={(event) => handleChange(event)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textPrimary" className="input-title">
              Last name *
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Your last name"
              variant="outlined"
              size="medium"
              name="lastName"
              fullWidth
              type="text"
              helperText={hasError("lastName") ? formState.errors.lastName[0] : null}
              error={hasError("lastName")}
              value={formState.values.lastName || ""}
              onChange={(event) => handleChange(event)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textPrimary" className="input-title">
              Billing zip code *
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Your billing zip code"
              variant="outlined"
              size="medium"
              name="zipCode"
              fullWidth
              type="text"
              helperText={hasError("zipCode") ? formState.errors.zipCode[0] : null}
              error={hasError("zipCode")}
              value={formState.values.zipCode || ""}
              onChange={(event) => handleChange(event)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textPrimary" className="input-title">
              Card number *
            </Typography>

            <InputMask
              mask={`9999 9999 9999 9999 9999`}
              maskChar=" "
              value={formState.values.cardNumber || ""}
              onChange={(event) => handleChange(event)}
            >
              {() => (
                <TextField
                  className="height-for-valid"
                  placeholder="Your card number"
                  variant="outlined"
                  size="medium"
                  name="cardNumber"
                  fullWidth
                  type="text"
                  helperText={hasError("cardNumber") ? formState.errors.cardNumber[0] : null}
                  error={hasError("cardNumber")}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textPrimary" className="input-title">
              Expiry Date *
            </Typography>
            <div
              className="height-for-valid"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FormControl variant="outlined" className="form-control form-control-payment-data">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={date}
                  onChange={(event) => setDate(event.target.value)}
                  label="Date"
                >
                  {expDate.map((item, idx) => (
                    <MenuItem value={item.value} key={idx + item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className="form-control form-control-payment-data">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={month}
                  onChange={(event) => setMonth(event.target.value)}
                  label="Month"
                >
                  {expMonth.map((item, idx) => (
                    <MenuItem value={item.value} key={idx + item.value}>
                      {item.month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textPrimary" className="input-title">
              Security code*
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Security code"
              variant="outlined"
              size="medium"
              name="securityCode"
              fullWidth
              type="text"
              helperText={hasError("securityCode") ? formState.errors.securityCode[0] : null}
              error={hasError("securityCode")}
              value={formState.values.securityCode || ""}
              onChange={(event) => handleChange(event)}
            />
            {accountPaymentStatus.status !== 200 ? <div className="text-error">{accountPaymentStatus.message}</div> : null}
          </Grid>
          <Grid item xs={12} sm={12} className="button-save-payment-data">
            <Button variant="contained" type="submit" color="primary" size="large" className="button-payment-data" onClick={handleSave}>
              save
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default PaymentEdit;

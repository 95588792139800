import { put, takeEvery, call, delay } from "redux-saga/effects";

import {
  callLogout,
  callProfile,
  callRegister,
} from "../../service/apiService/apiService";
import {
  GET_ACCOUNT_INFO_FAILED,
  GET_ACCOUNT_INFO_SUCCESS,
} from "../actionNames/Account";
import {
  ACTIVATE_PROFILE_USER,
  ACTIVATE_PROFILE_USER_FAILED,
  ACTIVATE_PROFILE_USER_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  PREREGISTER_USER,
  PREREGISTER_USER_FAILED,
  PREREGISTER_USER_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_SUCCESS,
} from "../actionNames/Auth";

function* doRegister(action) {
  try {
    const history = action.history;
    yield put({ type: "START_LOADING" });

    const { email, password, firstName, lastName } = action.data;
    const answerBackend = yield call(callRegister, "POST", `checkemail`, {
      email,
      password,
      firstName,
      lastName,
    });

    const status = answerBackend.data.Status;

    yield put({
      type: PREREGISTER_USER_SUCCESS,
      status,
    });

    yield put({ type: "END_LOADING" });
    if (action.checkout.checkout) {
      history.push(`/checkout/shipping-address`);
    }
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: PREREGISTER_USER_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doActivateProfileUser(action) {
  try {
    const modifyId = action.data.slice(4);
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callRegister,
      "POST",
      `accountverification`,
      { id: modifyId }
    );
    const data = answerBackend.data;

    yield put({
      type: ACTIVATE_PROFILE_USER_SUCCESS,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: ACTIVATE_PROFILE_USER_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doLogin(action) {
  try {
    const history = action.history;
    const updatedData = {
      userId: action.data.email,
      password: action.data.password,
    };
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callRegister,
      "POST",
      `signin`,
      updatedData
    );

    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: LOGIN_USER_SUCCESS,
      status,
      data,
    });

    if (action.checkout.checkout) {
      try {
        const answerBackendToAccount = yield call(
          callProfile,
          "POST",
          `getaccinfo`,
          {},
          data.session_id
        );
        const dataAccount = answerBackendToAccount.data;
        yield put({
          type: GET_ACCOUNT_INFO_SUCCESS,
          status,
          data: dataAccount,
          sessionId: data.session_id,
        });

        history.push(history.location.state);
      } catch (error) {
        yield put({
          type: GET_ACCOUNT_INFO_FAILED,
        });
        history.push("/sign-in");
      }

      history.push(`/checkout/shipping-address`);
    } else {
      if (history.location.state) {
        history.push(history.location.state);
      } else {
        history.push("/account/settings");
      }
    }

    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: LOGIN_USER_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doLogout(action) {
  const history = action.history;
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callLogout,
      "POST",
      `logout`,
      {},
      action.data
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: LOGOUT_USER_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
    history.push("/sign-in");
  } catch (error) {
    history.push("/sign-in");

    yield put({ type: "END_LOADING" });
  }
}
function* doRefreshToken(action) {
  const history = action.history;
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(
      callLogout,
      "POST",
      `refreshtoken`,
      {},
      action.data
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: REFRESH_TOKEN_SUCCESS,
      status,
      data,
    });
    yield delay(2000);
    yield put({ type: "END_LOADING" });
  } catch (error) {
    yield put({
      type: REFRESH_TOKEN_FAILED,
    });
    history.push("/sign-in");
    yield put({ type: "END_LOADING" });
  }
}

export function* authSaga() {
  yield takeEvery(LOGOUT_USER, doLogout);
  yield takeEvery(LOGIN_USER, doLogin);
  yield takeEvery(ACTIVATE_PROFILE_USER, doActivateProfileUser);
  yield takeEvery(PREREGISTER_USER, doRegister);
  yield takeEvery(REFRESH_TOKEN, doRefreshToken);
}

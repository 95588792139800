//Cart
export const getTotalSum = (state) => state.cart.totalSum;
export const getItemsInCart = (state) => state.cart.itemsInCart;
export const getTotalTaxes = (state) => state.cart.totalTaxes;

//Auth
export const getEmailRegister = (state) => state.auth.preRegisterUser.email;
export const getEmailLogin = (state) => state.auth.loginUser.email;
export const getAuthorizedUser = (state) => state.auth.authorizedUser;
export const getActivateProfileUser = (state) => state.auth.activateProfileUser;
export const getStatusAction = (state) => state.auth.statusAction;
export const getLoginUserInfo = (state) => state.auth.loginUserInfo;
export const getStatus = (state) => state.auth.statusCheck;
export const getErrorMessageLogin = (state) => state.auth.errorMessageLogin;
export const getPreRegisterUser = (state) => state.auth.preRegisterUser;
export const getAuth = (state) => state.auth.isAuth;
export const getUser = (state) => state.auth.user;
export const getErrorMessageRegister = (state) => state.auth.errorMessageRegister;

//Checkout
export const getBillingMethod = (state) => state.checkout.billing;
export const getShippingMethod = (state) => state.checkout.shipping;
export const getPaymentInfo = (state) => state.checkout.paymentInfo;
export const getLoggedPaymentSuccess = (state) => state.checkout.successPayment;

//Account
export const getStatusAccountInfo = (state) => state.account.statusAccountInfo;
export const getAccountSettingsInfo = (state) => state.account.accountInfo;
export const getAccountPlanCancelStatus = (state) => state.account.cancelPlanAccount;
export const getIsOpenModal = (state) => state.account.modalIsOpen;
export const getUserIsSystem = (state) => state.account;
export const getAllRates = (state) => state.account.currentRatePlan;
export const getCountryCode = (state) => state.account.countryCode;
export const getStatusChangePlan = (state) => state.account.statusAccountChangePlan;
export const getEventLog = (state) => state.account.eventLog;

//Billing Invoice
export const getInvoices = (state) => state.billingInvoice.invoiceDetail;
export const getAccountPlans = (state) => state.billingInvoice.allAccountPlans;
export const getBillingInvoices = (state) => state.billingInvoice.billingInvoice;
export const getAccountBalance = (state) => state.billingInvoice.accountBalance;

//Payment
export const getSuccessPayment = (state) => state.payment.successPayment;
export const getCurrentPaymentMethod = (state) => state.payment.paymentMethod;
export const getAccountPaymentStatus = (state) => state.payment.accountPaymentStatus;

//Plan
export const getPlans = (state) => state.plan.plans;
export const getGroupsPlans = (state) => state.plan.groups;
export const getSupplemental = (state) => state.plan.supplementalPlans;

//Loading
export const getLoading = (state) => state.loading.loading;

//Currency
export const getCurrencySymbol = (state) => state.currency.currency_symbol;
export const getCurrencyList = (state) => state.currency.currencyList;
export const getCurrencyAccountSymbol = (state) => state.currency.currencyAccountSymbol;

//Notification
export const getIsOpen = (state) => state.notification.isOpenCartFromNotification;

//Configure
export const getApiKeyCredential = (state) => state.configure.apiKeys;
export const getStatusConfigureActions = (state) => state.configure.statusActions;
export const getStylesSite = (state) => state.configure.styles;
export const getImagesSite = (state) => state.configure.images;
export const getPostMethodSite = (state) => state.configure.postMethod;
export const getEventListenerKeySite = (state) => state.configure.eventListenerId;

//Countries
export const getAllCountries = (state) => state.countries.countryList;

export const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    format: {
      pattern: "^[a-zA-Z0-9]+$"
    },
    length: {
      minimum: 8,
    },
    
  },
};

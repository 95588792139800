import { useState } from "react";

import FormSignIn from "../../../Auth/Signin/Form/Form";
import FormSignUp from "../../../Auth/Signup/Form/Form";

const AccountSignInSignUp = ({ onNext, errorMessageRegister, errorMessageLogin }) => {
  const [step, setStep] = useState(1);

  const changeStep = (key) => {
    setStep(key);
  };

  return (
    <div>
      {step === 1 ? (
        <div>
          <FormSignUp changeStep={changeStep} checkout={true} onNext={onNext} errorMessage={errorMessageRegister} />
        </div>
      ) : (
        <div>
          <FormSignIn changeStep={changeStep} checkout={true} onNext={onNext} errorMessage={errorMessageLogin} />
        </div>
      )}
    </div>
  );
};
export default AccountSignInSignUp;

import { useState, useEffect, useRef } from "react";

import { Grid, Typography, TextField } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputMask from "react-input-mask";
import { useSelector } from "react-redux";
import validate from "validate.js";
import "./ShippingAddress.css";

import {
  handleFormState,
  handleFormStateUseEffect,
} from "../../../../helpers/HandleFormState";
import {
  getAccountSettingsInfo,
  getAllCountries,
  getCountryCode,
  getShippingMethod,
  getStatusAction,
} from "../../../../helpers/Selector";
import { stateSelect } from "../../../../shared/data/dataStatesCountries";
import BillingAddress from "../BillingAddress/BillingAddress";
import { schema } from "./validationSchema";

const ShippingAddress = ({ onNext }) => {
  const countrySelect = useSelector(getAllCountries);
  const shippingData = useSelector(getShippingMethod);
  const statusAction = useSelector(getStatusAction);
  const accountData = useSelector(getAccountSettingsInfo);
  const countryCode = useSelector(getCountryCode);
  const myRef = useRef(null);

  const data = {
    firstName: accountData.first_name,
    lastName: accountData.last_name,
    streetAddress: accountData.address1,
    addressSecond: accountData.address2,
    city: accountData.city,
    stateProvince: accountData.state_prov,
    country: accountData.country_cd,
    zipCode: accountData.postal_cd,
    phoneNumber: accountData.phone,
  };

  const [checked, setChecked] = useState(true);
  const [countryStateCode, setCountryStateCode] = useState(countryCode || 1);
  const [country, setCountry] = useState(data.country ? data.country : "US");
  const [state, setState] = useState(
    data.stateProvince ? data.stateProvince : ""
  );
  const [formState, setFormState] = useState({
    isValid: false,
    values: !data.firstName
      ? { ...shippingData, country: country }
      : { ...data },
    touched: { country: true, stateProvince: true },
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
    if (event.target.name === "stateProvince") {
      setState(event.target.value);
    }
  };

  const changeState = (event) => {
    setState(event.target.value);
    setFormState(handleFormState(formState, event));
  };

  const changeCountry = (event) => {
    setCountry(event.target.value);
    setCountryStateCode(
      event.target.value === "CA" || event.target.value === "US"
        ? 1
        : event.target.value === "GB"
        ? 44
        : 61
    );
    setState("");
    setFormState(handleFormState(formState, event));
  };

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    setTimeout(() => {
      myRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 100);
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;
  return (
    <div style={{ padding: 10 }}>
      <div className="shipping-title-text">Billing Address</div>
      <Grid container justify="space-between">
        <Grid item xs={12} sm={5}>
          <Typography variant="subtitle1" color="textPrimary">
            First name *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your first name"
            variant="outlined"
            size="medium"
            name="firstName"
            fullWidth
            type="text"
            helperText={
              hasError("firstName") ? formState.errors.firstName[0] : null
            }
            error={hasError("firstName")}
            value={formState.values.firstName || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography variant="subtitle1" color="textPrimary">
            last name *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your last name"
            variant="outlined"
            size="medium"
            name="lastName"
            fullWidth
            type="text"
            helperText={
              hasError("lastName") ? formState.errors.lastName[0] : null
            }
            error={hasError("lastName")}
            value={formState.values.lastName || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle1" color="textPrimary">
            Street Address *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your street address"
            variant="outlined"
            size="medium"
            name="streetAddress"
            fullWidth
            type="text"
            helperText={
              hasError("streetAddress")
                ? formState.errors.streetAddress[0]
                : null
            }
            error={hasError("streetAddress")}
            value={formState.values.streetAddress || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle1" color="textPrimary">
            Address 2
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your address 2"
            variant="outlined"
            size="medium"
            name="addressSecond"
            fullWidth
            type="text"
            helperText={
              hasError("addressSecond")
                ? formState.errors.addressSecond[0]
                : null
            }
            error={hasError("addressSecond")}
            value={formState.values.addressSecond || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Country *
          </Typography>
          <FormControl
            variant="outlined"
            className="form-control height-for-valid"
          >
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={country}
              onChange={changeCountry}
              label="Country"
              name="country"
            >
              {countrySelect.map((item, idx) => (
                <MenuItem value={item.country_cd} key={idx + item.country_cd}>
                  {item.country_english}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            State / province *
          </Typography>
          {!!stateSelect[country] ? (
            <FormControl variant="outlined" className="form-control">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state}
                onChange={changeState}
                label="State / province"
                name="stateProvince"
              >
                {stateSelect[country].map((item, idx) => (
                  <MenuItem value={item.id} key={idx + item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              className="height-for-valid"
              placeholder="State / province"
              variant="outlined"
              size="medium"
              name="stateProvince"
              fullWidth
              type="text"
              helperText={
                hasError("stateProvince")
                  ? formState.errors.stateProvince[0]
                  : null
              }
              error={hasError("stateProvince")}
              value={state}
              onChange={(event) => handleChange(event)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography variant="subtitle1" color="textPrimary">
            Zip Code *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your zip code"
            variant="outlined"
            size="medium"
            name="zipCode"
            fullWidth
            type="text"
            helperText={
              hasError("zipCode") ? formState.errors.zipCode[0] : null
            }
            error={hasError("zipCode")}
            value={formState.values.zipCode || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography variant="subtitle1" color="textPrimary">
            City *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your city"
            variant="outlined"
            size="medium"
            name="city"
            fullWidth
            type="text"
            helperText={hasError("city") ? formState.errors.city[0] : null}
            error={hasError("city")}
            value={formState.values.city || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant="subtitle1" color="textPrimary">
            Phone number *
          </Typography>

          <InputMask
            mask={`+${countryStateCode || countryCode} (999) 999 9999`}
            maskChar=" "
            value={formState.values.phoneNumber || ""}
            onChange={(event) => handleChange(event)}
          >
            {() => (
              <TextField
                className="height-for-valid"
                placeholder="Your phone number"
                variant="outlined"
                size="medium"
                name="phoneNumber"
                fullWidth
                type="text"
                helperText={
                  hasError("phoneNumber")
                    ? formState.errors.phoneNumber[0]
                    : null
                }
                error={hasError("phoneNumber")}
              />
            )}
          </InputMask>
        </Grid>
      </Grid>
      <div className="wrap-checkbox-text" ref={myRef}>
        <Checkbox
          checked={checked}
          onChange={handleCheck}
          color="default"
          inputProps={{ "aria-label": "checkbox with default color" }}
        />
        <div>Shipping address is the same as billing address</div>
      </div>
      <div style={{ color: "red" }}>{statusAction.message}</div>

      <BillingAddress
        isChecked={checked}
        onNext={onNext}
        shippingFormState={formState}
        shippingCountry={country}
      />
      <div />
    </div>
  );
};
export default ShippingAddress;

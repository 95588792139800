import { useState } from "react";

import { Button, Grid, TextField, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { getApiKeyCredential } from "../../../helpers/Selector";
import { syncPlans, updateApiKeys } from "../../../redux/actions/Configure";
import LoaderComponent from "../../../shared/LoaderComponent/LoaderComponent";

const KeysCredentials = () => {
  const dispatch = useDispatch();
  const { auth_key, client_number } = useSelector(getApiKeyCredential);
  const [clientNumber, setClientNumber] = useState(client_number || "");
  const [authKey, setAuthKey] = useState(auth_key || "");

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <div style={{ paddingTop: 20 }}>
      {auth_key && client_number ? (
        <>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} sm={6} className="wrap-input-typo-key">
              <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
                API Client Number
              </Typography>
              <TextField
                className="height-for-valid"
                placeholder="API Client Number"
                variant="outlined"
                size="medium"
                name="clientNumber"
                fullWidth
                type="text"
                value={clientNumber}
                onChange={(event) => setClientNumber(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textPrimary" className="input-title" style={{ marginBottom: 5, fontSize: 20 }}>
                API Authorization Key
              </Typography>
              <TextField
                className="height-for-valid"
                placeholder="API Authorization Key"
                variant="outlined"
                size="medium"
                name="authKey"
                fullWidth
                type="password"
                value={authKey}
                onChange={(event) => setAuthKey(event.target.value)}
              />
            </Grid>
          </Grid>
          <Button color="primary" variant="contained" style={{ marginRight: 30 }} onClick={() => dispatch(syncPlans())}>
            Sync Plan Data with Admin Tools
          </Button>
          <Button variant="contained" onClick={() => dispatch(updateApiKeys({ authKey, clientNumber }))}>
            Save
          </Button>
        </>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};
export default KeysCredentials;

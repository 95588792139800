import { Button, Tooltip } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import "./BillingInvoice.css";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";

import visa from "../../../images/visa.png";

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
});

const ResponsiveTableBillingInvoice = ({ history, handleClick, getCard, eventLog, accountSymbol }) => {
  const classes = useStyles();
  return (
    <div className="wrap-responsive-table-billing-invoice">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          {history.map((row, idx) =>
            eventLog ? (
              <TableBody key={idx} className="responsive-table-row-event-log-body">
                <TableRow key={row.invoice_no || ''} className="responsive-table-table-row">
                  <TableCell component="th" scope="row">
                    <span className="responsive-table-title">Type: </span>
                    {row.msg_class_label || ''}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <span className="responsive-table-title">Subject: </span>
                    {row.msg_subject && row.msg_subject.slice(0, 20) || ''} ...
                  </TableCell>
                  <TableCell>
                    <div className="wrap-some-data-responsive-table">
                      <span className="responsive-table-title">Recipient: </span>
                      {row.msg_recipient_email_address || ''}
                    </div>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <span className="responsive-table-title">Date Created: </span>
                    {moment(row.msg_creation_date).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <span className="responsive-table-title">Date Sent: </span>
                    {moment(row.msg_sent_date).format("MM/DD/YYYY")}
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody key={idx}>
                <TableRow key={row.invoice_no} className="responsive-table-table-row">
                  <TableCell component="th" scope="row">
                    <span className="responsive-table-title">Date: </span>
                    {moment(row.create_date).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <span className="responsive-table-title">Description: </span>
                    Invoice #{row.invoice_no}
                  </TableCell>
                  <TableCell>
                    <div className="wrap-some-data-responsive-table">
                      <span className="responsive-table-title">Invoice status: </span>
                      {!!row.invoice_status ? (
                        <Tooltip title="Invoice Paid">
                          <CheckCircleOutlineIcon style={{ color: "green", cursor: "pointer" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Not Paid">
                          <HighlightOffIcon style={{ color: "red", cursor: "pointer" }} />
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <span className="responsive-table-title">Balance: </span>{accountSymbol}{parseInt(row.total_amount, 10).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {" "}
                    <span className="responsive-table-title">Charges/Payments: </span>{accountSymbol}{+row.total.toFixed(2)}
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined" onClick={() => handleClick(row.invoice_no)} color="primary">
                      View and Print
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow style={{ background: "#e2e2e2" }} className="responsive-table-table-row electronic-payment">
                  <TableCell className="cell-electronic-payment">
                    <span className="responsive-table-title">Date: </span>
                    {row.transaction_history && row.transaction_history.length
                      ? moment(row.transaction_history[0].transaction_create_date).format("MM/DD/YYYY")
                      : ""}
                  </TableCell>
                  <TableCell className="cell-electronic-payment">
                    <span className="responsive-table-title">Description: </span>
                    {row.transaction_history && row.transaction_history.length ? row.transaction_history[0].transaction_desc : ""}
                  </TableCell>
                  <TableCell className="cell-electronic-payment">
                    <div className="wrap-some-data-responsive-table">
                      <span className="responsive-table-title">Payment method: </span>
                      {row.cart_info && row.cart_info.cc_string ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              backgroundImage: `url(${getCard(row.cart_info.cc_string)})`,
                            }}
                            className="image-billing-invoice"
                          ></div>
                          <div> •••• •••• •••• {row.cart_info.cc_suffix}</div>
                        </div>
                      ) : null}
                    </div>
                  </TableCell>

                  <TableCell className="cell-electronic-payment">
                    <span className="responsive-table-title">Charges/Payments: </span>
                    {row.transaction_history && row.transaction_history.length && row.transaction_history[0].transaction_applied_amount
                      ? `-${accountSymbol}${row.transaction_history[0].transaction_applied_amount}`
                      : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            )
          )}
        </Table>
      </TableContainer>
    </div>
  );
};
export default ResponsiveTableBillingInvoice;

export const schema = {
  nameOnCard: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
    },
    format: {
      pattern: /^[a-z ,.'-]+$/i,
    },
  },
  cardNumber: {
    // format: {
    //   pattern: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
    // },
    length: {
      minimum: 16,
      maximum: 20,
    },

    presence: { allowEmpty: false, message: "is required" },
  },
  securityCode: {
    format: {
      pattern: /^[0-9]{3,4}$/,
    },
    presence: { allowEmpty: false, message: "is required" },
  },
};

import {useState, useEffect} from 'react'

import { Grid, Typography, Button } from "@material-ui/core";
import "./BillingInvoice.css";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  getBillingInvoices,
  getInvoices,
  getAccountPlans,
  getAccountBalance,
  getCurrencyAccountSymbol,
} from "../../../helpers/Selector";
import { getInvoiceDetails } from "../../../redux/actions/BillingInvoice";
import { billingInvoicesData } from "../../../shared/data/index";
import LoaderComponent from "../../../shared/LoaderComponent/LoaderComponent";
import ModalForHtml from "../../../shared/ModalForHtml/ModalForHtml";
import TableBillingInvoice from "./TableBillingInvoice";

const BillingInvoice = () => {
  const accountSymbol = useSelector(getCurrencyAccountSymbol)
  const invoices = useSelector(getInvoices);
  const allAccountPlans = useSelector(getAccountPlans);
  const billingInvoices = useSelector(getBillingInvoices);
  const AccountBalance = useSelector(getAccountBalance);
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentPlan } = { ...billingInvoicesData };
  const [period, setPeriod] = useState('Month')
  // const { period } = { ...currentPlan };
  useEffect(() => {
    if(allAccountPlans) {
      const path = allAccountPlans.all_acct_plans_m[0]
      if(path) {
        if(path.recurring_billing_period_type === 1) return setPeriod('Day')
        if(path.recurring_billing_period_type === 2) return setPeriod('Week')
        if(path.recurring_billing_period_type === 3) return setPeriod('Month')
      }
    }
  }, [])

  const handleClick = (invoice) => {
    dispatch(getInvoiceDetails(invoice));
  };

  return (
    <div className="wrap-block-billing-invoice">
      {allAccountPlans ? (
        <Grid container>
          {billingInvoices &&
          billingInvoices.Status &&
          billingInvoices.Status === 201 ? (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textPrimary"
                className="account-header-to-uppercase"
              >
                {billingInvoices.Message}
              </Typography>
            </Grid>
          ) : (
            <>
              <div className="wrap-info-block">
                <Paper className="wrap-block-membership-plan">
                  <div className="wrap-block-plan-billing-invoice">
                    <div className="block-plan-billing-invoice">
                      <div className="title-plan-block-membership-plan title-billing-invoice">
                        <div>Your plan</div>
                        {/* <Button color="secondary" onClick={() => history.push("/account/settings")}>
                          Back
                        </Button> */}
                      </div>


                      {allAccountPlans.all_acct_plans_m.map((item, idx) => (
                        <div key={idx}>
                          <div className="type-block-membership-plan">
                            <span>{item.plan_name} </span>
                            for{" "}
                            <span>
                              {accountSymbol}
                              {
                                item.plan_instance_services && item.plan_instance_services[0] && item.plan_instance_services[0].plan_service_rates && item.plan_instance_services[0].plan_service_rates[0] ?
                                  (item.recurring_billing_period_type === 1 ? 
                                    item.plan_instance_services[0].plan_service_rates[0].daily_fee : 
                                    item.recurring_billing_period_type === 2 ? 
                                    item.plan_instance_services[0].plan_service_rates[0].weekly_fee : 
                                    item.plan_instance_services[0].plan_service_rates[0].monthly_fee
                                  ) : "Some price"
                              }
                            </span>
                            /{period}
                          </div>
                        </div>
                      ))}



                    </div>
                    {allAccountPlans.statement ? (
                      <div className="wrap-summary">
                        <div className="title-bill-block-membership-plan title-billing-invoice">
                          <div>
                            Summary of Current Statement:{" "}
                            {moment(
                              allAccountPlans.statement.create_date
                            ).format("MM/DD/YYYY")}
                            :{" "}
                          </div>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              handleClick(allAccountPlans.statement.invoice_no)
                            }
                          >
                            View & Print
                          </Button>
                        </div>
                        <div className="wrap-summary-options">
                          <div className="summary-option">
                            <div className="summary-option-title">
                              <span>Due Date: </span>
                              <span className="adding-to-summary-option-title">
                                (*automatic billing will be executed on due
                                date)
                              </span>
                            </div>
                            <div className="summary-option-value">
                              {moment(
                                allAccountPlans.statement.due_date
                              ).format("MMMM Do YYYY")}
                            </div>
                          </div>
                          {allAccountPlans.statement.invoice_details_info.map(
                            (item, idx) => (
                              <div key={idx}>
                                {item.amount ? (
                                  <>
                                    <div className="summary-option">
                                      <div className="summary-option-title">
                                        {item.description}
                                      </div>
                                      <div className="summary-option-value">
                                        {accountSymbol}{item.amount.toFixed(2)}
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            )
                          )}

                          <div className="summary-option summary-total">
                            <div className="summary-option-title">
                              Total Current Charges
                            </div>
                            <div className="summary-option-value">
                              {accountSymbol}{allAccountPlans.statement.new_payments_amount}
                            </div>
                          </div>
                          <div className="summary-option balance">
                            <div className="summary-option-title">
                              Account Balance
                            </div>
                            {AccountBalance && AccountBalance.acct_balance ? (
                              <div className="summary-option-value">
                                {accountSymbol}
                                {parseInt(
                                  AccountBalance.acct_balance,
                                  10
                                ).toFixed(2)}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="last-payment-block-wrap">
                    <div className="last-payment-block">
                      <div className="wrap-price-due">
                        {AccountBalance && AccountBalance.acct_balance ? (
                          <div className="payment-price">
                            {accountSymbol}
                            {parseInt(AccountBalance.acct_balance, 10).toFixed(
                              2
                            )}
                          </div>
                        ) : null}

                        <div>
                          Due:{" "}
                          {moment(allAccountPlans.statement.due_date).format(
                            "MMMM Do YYYY"
                          )}
                        </div>
                      </div>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ width: "100%" }}
                      >
                        Pay Now
                      </Button>
                      {billingInvoices &&
                      billingInvoices.length &&
                      billingInvoices[billingInvoices.length - 1]
                        .transaction_history &&
                      billingInvoices[billingInvoices.length - 1]
                        .transaction_history.length ? (
                        <div className="last-payment-text">
                          Last Payment of {accountSymbol}
                          {
                            billingInvoices[billingInvoices.length - 1]
                              .transaction_history[0].transaction_applied_amount
                          }{" "}
                          paid on{" "}
                          {moment(
                            billingInvoices[billingInvoices.length - 1]
                              .transaction_history[0].transaction_create_date
                          ).format("MMMM Do YYYY")}
                        </div>
                      ) : (
                        <div className="last-payment-text">
                          {" "}
                          Transaction history is not available
                        </div>
                      )}

                      <Button color="primary" variant="text">
                        Print current bill
                      </Button>
                    </div>
                  </div>
                </Paper>
              </div>
              <div className="annotation-to-bill-block">
                Membership fees are billed at the beginning of each period and
                may take a few days after the billing date to appear on your
                account. Sales tax may apply
              </div>
              {billingInvoices ? (
                <>
                  <div className="wrap-table-billing-invoice">
                    <TableBillingInvoice history={billingInvoices} accountSymbol={accountSymbol}/>
                  </div>
                  {invoices ? <ModalForHtml invoices={invoices} /> : null}
                </>
              ) : null}
            </>
          )}
          <Button
            color="secondary"
            onClick={() => history.push("/account/settings")}
            style={{ marginTop: 20 }}
          >
            Back
          </Button>
        </Grid>
      ) : (
        <LoaderComponent />
      )}
    </div>
  );
};
export default BillingInvoice;

import { useState } from "react";

import { Button } from "@material-ui/core";
import ImageUploading from "react-images-uploading";
import { useDispatch } from "react-redux";

import { uploadBackgroundImage, uploadLogoImage } from "../../../redux/actions/Configure";

const LogoBackground = () => {
  const dispatch = useDispatch();
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [logo, setLogo] = useState([]);
  const maxNumber = 69;

  const onChangeBackground = (imageList, addUpdateIndex) => {
    setBackgroundImages(imageList);
    dispatch(uploadBackgroundImage(imageList[0].file));
  };
  const onChangeLogo = (imageList, addUpdateIndex) => {
    setLogo(imageList);
    if (imageList && imageList[0]) {
      dispatch(uploadLogoImage(imageList[0].file));
    }
  };
  return (
    <div
      style={{
        padding: 20,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
    >
      <div className="wrap-block-upload-img">
        <div className="wrap-block-with-img">
          <div className="title-upload-img">Background Image</div>
          <ImageUploading value={backgroundImages} onChange={onChangeBackground} maxNumber={maxNumber} dataURLKey="data_url">
            {({ imageList, onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
              <div className="upload__image-wrapper">
                <div className="wrap-buttons-upload-img">
                  <Button variant="contained" color={isDragging ? "secondary" : "primary"} onClick={onImageUpload} {...dragProps}>
                    Click or Drop here
                  </Button>
                  <Button variant="contained" onClick={onImageRemoveAll}>
                    Remove image
                  </Button>
                </div>

                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" style={{ width: "100%", height: "100%" }} />
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>

        <div className="wrap-block-with-img" style={{ marginTop: 30 }}>
          <div className="title-upload-img">Current Background Image</div>
          <div className="image-item" style={{ height: 500, width: "100%" }}>
            <div className="bg-config"></div>
          </div>
        </div>
      </div>

      <div className="wrap-block-upload-img">
        <div className="wrap-block-with-img">
          <div className="title-upload-img">Logo</div>
          <ImageUploading value={logo} onChange={onChangeLogo} maxNumber={maxNumber} dataURLKey="data_url">
            {({ imageList, onImageUpload, onImageRemoveAll, isDragging, dragProps }) => (
              <div className="upload__image-wrapper">
                <div className="wrap-buttons-upload-img">
                  <Button variant="contained" color={isDragging ? "secondary" : "primary"} onClick={onImageUpload} {...dragProps}>
                    Click or Drop here
                  </Button>
                  <Button variant="contained" onClick={onImageRemoveAll}>
                    Remove image
                  </Button>
                </div>

                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width={"30%"} />
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
        <div className="wrap-block-with-img" style={{ marginTop: 30 }}>
          <div className="title-upload-img">Current Logo</div>
          <div className="image-item" style={{ height: 30, width: "30%" }}>
            <div className="logo-config"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LogoBackground;

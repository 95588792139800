import { CLEAR_STATUSES } from "../actionNames/Auth";
import {
  ADD_PAYMENT_METHOD_FAILED,
  ADD_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAILED,
  GET_PAYMENT_METHOD_SUCCESS,
  POST_PAYMENT,
  REMOVE_PAYMENT_METHOD_FAILED,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  SUCCESS_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAILED,
  UPDATE_PAYMENT_METHOD_SUCCESS,
} from "../actionNames/Payment";

export const initialState = {
  payment: "",
  successPayment: {
    status: "",
    message: "",
  },
  accountPaymentStatus: {
    status: "",
    message: "",
  },
  paymentMethod: null,
};

export function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case POST_PAYMENT: {
      return {
        ...state,
      };
    }
    case SUCCESS_PAYMENT_SUCCESS: {
      return {
        ...state,
        successPayment: {
          status: action.status,
          message: action.message,
        },
      };
    }

    case GET_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        paymentMethod: action.data,
      };
    }
    case GET_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
      };
    }

    case REMOVE_PAYMENT_METHOD_SUCCESS: {
      console.log(action);
      return {
        ...state,
      };
    }
    case REMOVE_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
      };
    }

    case UPDATE_PAYMENT_METHOD_SUCCESS: {
      console.log(action);
      return {
        ...state,
        accountPaymentStatus: {
          status: "",
          message: "",
        },
      };
    }
    case UPDATE_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
        accountPaymentStatus: {
          status: action.status,
          message: action.message,
        },
      };
    }

    case ADD_PAYMENT_METHOD_SUCCESS: {
      console.log(action);
      return {
        ...state,
        accountPaymentStatus: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }
    case ADD_PAYMENT_METHOD_FAILED: {
      return {
        ...state,
        accountPaymentStatus: {
          status: action.status,
          message: action.message,
        },
      };
    }

    case CLEAR_STATUSES: {
      return {
        ...state,
        successPayment: {
          status: "",
          message: "",
        },
        accountPaymentStatus: {
          status: "",
          message: "",
        },
      };
    }

    default:
      return state;
  }
}
export const payment = (state) => state.payment;

import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";

import "./ReviewOrder.css";
import { getLoading, getShippingMethod, getSuccessPayment, getLoggedPaymentSuccess, getBillingMethod } from "../../../../helpers/Selector";
import ModalWindow from "../../../../shared/ModalWindow/ModalWindow";

const ReviewOrder = ({ onBack, onFinish }) => {
  const shipping = useSelector(getShippingMethod);
  const billing = useSelector(getBillingMethod);
  const successPayment = useSelector(getSuccessPayment);
  const loggedSuccessPayment = useSelector(getLoggedPaymentSuccess);
  const isLoading = useSelector(getLoading);

  return (
    <div>
      {/* {(!isLoading && successPayment.status) || (!isLoading && loggedSuccessPayment.status) ? (
        <ModalWindow
          status={successPayment.status || loggedSuccessPayment.status}
          message={successPayment.message || loggedSuccessPayment.message}
        />
      ) : null} */}

      <div>
        <div className="title-order">Thank You for your Order!</div>
      </div>
      <div className="wrap-shipping-payment">
        <div>
          <div className="title-order">Shipping</div>
          <div>
            <div className="shipping-value">
              {shipping.firstName} {shipping.lastName}
            </div>
            <div className="shipping-value">
              {shipping.streetAddress}, {shipping.addressSecond}, {shipping.city}, {shipping.stateProvince}, {shipping.zipCode},{" "}
              {shipping.country}
            </div>
          </div>
        </div>
        <div>
          <div className="title-order">Billing</div>
          <div>
            <div className="shipping-value">
              {billing.firstName} {billing.lastName}
            </div>
            <div className="shipping-value">
              {billing.streetAddress}, {billing.addressSecond}, {billing.city}, {billing.stateProvince}, {billing.zipCode},{" "}
              {billing.country}
            </div>
          </div>
        </div>
      </div>
      <div className="wrap-buttons">
        <Button variant="contained" color="primary" onClick={onFinish}>
          log in / view your account
        </Button>
      </div>
    </div>
  );
};
export default ReviewOrder;

import moment from "moment";
import { put, takeEvery, call, delay } from "redux-saga/effects";

import history from "../../history";
import {
  callAllAccountPlans,
  callEventLogs,
  callProfile,
  callProfileChangeInfo,
} from "../../service/apiService/apiService";
import {
  CANCEL_ACCOUNT_PLAN,
  CANCEL_ACCOUNT_PLAN_FAILED,
  CANCEL_ACCOUNT_PLAN_SUCCESS,
  CHANGE_ACCOUNT_PLAN,
  CHANGE_ACCOUNT_PLAN_FAILED,
  CHANGE_ACCOUNT_PLAN_SUCCESS,
  CHANGE_EMAIL,
  CHANGE_EMAIL_FAILED,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PERSONAL_INFO,
  CHANGE_PERSONAL_INFO_FAILED,
  CHANGE_PERSONAL_INFO_SUCCESS,
  GET_ACCOUNT_INFO,
  GET_ACCOUNT_INFO_FAILED,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_EVENT_LOGS,
  GET_EVENT_LOGS_FAILED,
  GET_EVENT_LOGS_SUCCESS,
  GET_RATES,
  GET_RATES_FAILED,
  GET_RATES_SUCCESS,
} from "../actionNames/Account";
import { LOGIN_USER_SUCCESS } from "../actionNames/Auth";
import { CURRENCY_ACCOUNT_CHANGE } from "../actionNames/Currency";

function* doGetAccountInfo(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);

    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callProfile,
      "POST",
      `getaccinfo`,
      {},
      action.data || sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;
    yield put({ type: "END_LOADING" });
    yield put({
      type: GET_ACCOUNT_INFO_SUCCESS,
      status,
      data,
      sessionId,
    });
    yield put({
      type: LOGIN_USER_SUCCESS,
      status,
      data,
      sessionId,
    });
    yield put({
      type: CURRENCY_ACCOUNT_CHANGE,
      currencyCD: data.acct_currency,
    });
  } catch (error) {
    const historyAction = action.history;
    console.log("ERRROR", error);
    yield put({
      type: GET_ACCOUNT_INFO_FAILED,
    });
    if (historyAction) {
      historyAction.push("/sign-in");
    } else {
      console.log(history);
      history.push("/sign-in");
    }
    yield put({ type: "END_LOADING" });
  }
}

function* doChangeEmail(action) {
  const sessionId = localStorage.getItem("sessionId");
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const answerBackend = yield call(
      callProfileChangeInfo,
      "POST",
      `updateemail`,
      action.data,
      sessionId
    );
    const status = answerBackend.status;
    const message = answerBackend.data.Message;
    yield put({ type: "END_LOADING" });
    yield put({
      type: CHANGE_EMAIL_SUCCESS,
      status,
      message,
    });
  } catch (error) {
    const message = error.response.data.Message;
    const status = error.response.data.Status;
    yield put({ type: "END_LOADING" });
    yield put({
      type: CHANGE_EMAIL_FAILED,
      message,
      status,
    });
  }
}

function* doChangePassword(action) {
  const sessionId = localStorage.getItem("sessionId");
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const answerBackend = yield call(
      callProfileChangeInfo,
      "POST",
      `updatepassword`,
      { password: action.data.newPassword },
      sessionId
    );
    const status = answerBackend.status;
    const message = answerBackend.data.Message;
    yield put({ type: "END_LOADING" });
    yield put({
      type: CHANGE_PASSWORD_SUCCESS,
      status,
      message,
    });
  } catch (error) {
    const message = error.response.data.Message;
    const status = error.response.data.Status;
    yield put({ type: "END_LOADING" });
    yield put({
      type: CHANGE_PASSWORD_FAILED,
      message,
      status,
    });
  }
}

function* doChangePersonalInfo(action) {
  const sessionId = localStorage.getItem("sessionId");
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const data = action.data;
    const dataForResponse = {
      firstName: data.firstName,
      lastName: data.lastName,
      address1: data.streetAddress,
      city: data.city,
      zipCode: data.zipCode,
      country: data.country,
      state: data.stateProvince,
      phone: data.phoneNumber,
      workPhone: data.phoneNumberWork,
      mi: data.middleName || "~",
      address2: data.addressSecond || "~",
      address3: data.addressThird || "~",
      companyName: data.companyName || "~",
      phoneExt: data.phoneExt || "~",
      workPhoneExt: data.phoneWorkExt || "~",
      cellPhone: data.phoneNumberCell || "~",
    };

    const answerBackend = yield call(
      callProfileChangeInfo,
      "POST",
      `updatepersonalinfo`,
      dataForResponse,
      sessionId
    );
    const status = answerBackend.status;
    const message = answerBackend.data.Message;
    yield put({ type: "END_LOADING" });
    yield put({
      type: CHANGE_PERSONAL_INFO_SUCCESS,
      status,
      message,
    });
  } catch (error) {
    const message = error.response.data.Message;
    const status = error.response.data.Status;
    yield put({ type: "END_LOADING" });
    yield put({
      type: CHANGE_PERSONAL_INFO_FAILED,
      message,
      status,
    });
  }
}

function* doCancelAccountPlan(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callAllAccountPlans,
      "POST",
      `cancellplan`,
      { plan_instance_no: action.data },
      sessionId
    );
    const date = moment().format();
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: CANCEL_ACCOUNT_PLAN_SUCCESS,
      status,
      data,
      date,
      stop: true,
    });
    yield delay(7000);
    yield put({
      type: CANCEL_ACCOUNT_PLAN_SUCCESS,
      status,
      data,
      date,
      stop: false,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const date = moment().format();
    const data = error.response.data;
    yield put({
      type: CANCEL_ACCOUNT_PLAN_FAILED,
      data,
      date,
      stop: false,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doChangeAccountPlan(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callAllAccountPlans,
      "POST",
      `updateplan`,
      action.data,
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;
    const date = moment().format();

    yield put({
      type: CHANGE_ACCOUNT_PLAN_SUCCESS,
      status,
      data,
      date,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const date = moment().format();
    const data = error.response.data;
    yield put({
      type: CHANGE_ACCOUNT_PLAN_FAILED,
      data,
      date,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetEventLogs(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callEventLogs,
      "POST",
      `geteventslogs`,
      {},
      sessionId
    );
    const data = answerBackend.data;

    yield put({
      type: GET_EVENT_LOGS_SUCCESS,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: GET_EVENT_LOGS_FAILED,
      data,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetRates(action) {
  const history = action.history;
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);

    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callAllAccountPlans,
      "POST",
      `getallrates`,
      {
        plan_no: action.data,
        currency: action.currency,
        plan_type: action.supplementalIndex,
      },
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_RATES_SUCCESS,
      status,
      data,
    });

    history.push("/account/change-plan", {
      instanceNo: action.instanceNo,
      currentRate: action.currentRate,
    });

    yield put({ type: "END_LOADING" });
  } catch (error) {
    const data = error.response.data;
    yield put({
      type: GET_RATES_FAILED,
      data,
    });
    history.push("/account/settings");

    yield put({ type: "END_LOADING" });
  }
}

export function* accountSaga() {
  yield takeEvery(GET_ACCOUNT_INFO, doGetAccountInfo);
  yield takeEvery(CHANGE_EMAIL, doChangeEmail);
  yield takeEvery(CHANGE_PASSWORD, doChangePassword);
  yield takeEvery(CHANGE_PERSONAL_INFO, doChangePersonalInfo);
  yield takeEvery(CHANGE_ACCOUNT_PLAN, doChangeAccountPlan);
  yield takeEvery(CANCEL_ACCOUNT_PLAN, doCancelAccountPlan);
  yield takeEvery(GET_RATES, doGetRates);
  yield takeEvery(GET_EVENT_LOGS, doGetEventLogs);
}

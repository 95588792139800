import {
  CHANGE_EMAIL_FAILED,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PERSONAL_INFO_SUCCESS,
  CHANGE_PERSONAL_INFO_FAILED,
  GET_ACCOUNT_INFO,
  GET_ACCOUNT_INFO_FAILED,
  GET_ACCOUNT_INFO_SUCCESS,
  CHANGE_ACCOUNT_PLAN_SUCCESS,
  CHANGE_ACCOUNT_PLAN_FAILED,
  CANCEL_ACCOUNT_PLAN_SUCCESS,
  CANCEL_ACCOUNT_PLAN_FAILED,
  CLOSE_MODAL,
  GET_RATES_SUCCESS,
  GET_RATES_FAILED,
  GET_EVENT_LOGS_SUCCESS,
  GET_EVENT_LOGS_FAILED,
} from "../actionNames/Account";
import { CLEAR_STATUSES, LOGOUT_USER } from "../actionNames/Auth";

export const initialState = {
  session_id: "",
  accountInfo: {},
  statusAccountInfo: {
    status: "",
    message: "",
  },
  cancelPlanAccount: {
    status: "",
    message: "",
    date: "",
    stop: true,
  },
  statusAccountChangePlan: {
    status: "",
    message: "",
    date: "",
  },
  modalIsOpen: false,
  currentRatePlan: null,
  countryCode: 1,
  eventLog: null,
};

export function accountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_INFO: {
      return {
        ...state,
      };
    }
    case GET_ACCOUNT_INFO_SUCCESS: {
      const session_id = localStorage.getItem("sessionId");
      const country = action.data.country_cd;
      let countryCode;
      if (country === "US" || country === "CA") {
        countryCode = 1;
      } else if (country === "GB") {
        countryCode = 44;
      } else if (country === "AU") {
        countryCode = 61;
      }
      return {
        ...state,
        accountInfo: action.data,
        session_id: session_id,
        countryCode: countryCode,
      };
    }
    case GET_ACCOUNT_INFO_FAILED: {
      return {
        ...state,
      };
    }

    case CHANGE_EMAIL_SUCCESS: {
      console.log(action);
      return {
        ...state,
        statusAccountInfo: {
          status: action.status,
          message: action.message,
        },
      };
    }
    case CHANGE_EMAIL_FAILED: {
      console.log(action);
      return {
        ...state,
        statusAccountInfo: {
          status: action.status,
          message: action.message,
        },
      };
    }

    case CHANGE_PERSONAL_INFO_SUCCESS: {
      console.log(action);
      return {
        ...state,
        statusAccountInfo: {
          status: action.status,
          message: action.message,
        },
      };
    }
    case CHANGE_PERSONAL_INFO_FAILED: {
      console.log(action);
      return {
        ...state,
        statusAccountInfo: {
          status: action.status,
          message: action.message,
        },
      };
    }

    case CHANGE_PASSWORD_SUCCESS: {
      console.log(action);
      return {
        ...state,
        statusAccountInfo: {
          status: action.status,
          message: action.message,
        },
      };
    }
    case CHANGE_PASSWORD_FAILED: {
      console.log(action);
      return {
        ...state,
        statusAccountInfo: {
          status: action.status,
          message: action.message,
        },
      };
    }

    case CHANGE_ACCOUNT_PLAN_SUCCESS: {
      console.log(action);
      return {
        ...state,
        statusAccountChangePlan: {
          status: action.data.Status,
          message: action.data.Message,
          date: action.date,
        },
      };
    }
    case CHANGE_ACCOUNT_PLAN_FAILED: {
      console.log(action);
      return {
        ...state,
        statusAccountChangePlan: {
          status: action.data.Status,
          message: action.data.Message,
          date: action.date,
        },
      };
    }

    case CANCEL_ACCOUNT_PLAN_SUCCESS: {
      console.log(action);
      return {
        ...state,
        cancelPlanAccount: {
          status: action.data.Status,
          message: action.data.Message,
          date: action.date,
          stop: action.stop,
        },
      };
    }
    case CANCEL_ACCOUNT_PLAN_FAILED: {
      console.log(action);
      return {
        ...state,
        cancelPlanAccount: {
          status: action.data.Status,
          message: action.data.Message,
          date: action.date,
          stop: action.stop,
        },
      };
    }

    case GET_RATES_SUCCESS: {
      console.log(action);
      return {
        ...state,
        currentRatePlan: action.data,
      };
    }
    case GET_RATES_FAILED: {
      console.log(action);
      return {
        ...state,
        statusAccountInfo: {
          status: action.data.Status,
          message: action.data.Message,
        },
      };
    }

    case GET_EVENT_LOGS_SUCCESS: {
      console.log(action);
      const events = action.data;
      if (events.length >= 10) {
        events.splice(10);
      }
      return {
        ...state,
        eventLog: events,
      };
    }
    case GET_EVENT_LOGS_FAILED: {
      console.log(action);
      return {
        ...state,
      };
    }

    case CLEAR_STATUSES: {
      return {
        ...state,
        statusAccountInfo: {
          status: "",
          message: "",
        },
        statusAccountChangePlan: {
          status: "",
          message: "",
          date: "",
        },
        cancelPlanAccount: {
          status: "",
          message: "",
          date: "",
        },
      };
    }

    case LOGOUT_USER: {
      return {
        ...initialState,
      };
    }

    case CLOSE_MODAL: {
      return {
        ...state,
        modalIsOpen: action.data,
        cancelPlanAccount: {
          status: "",
          message: "",
          date: "",
          stop: false,
        },
      };
    }

    default:
      return state;
  }
}
export const account = (state) => state.account;

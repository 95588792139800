export const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 100,
    },
    format: {
      pattern: /^[a-z ,.'-]+$/i,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
      maximum: 100,
    },
    format: {
      pattern: /^[a-z ,.'-]+$/i,
    },
  },

  zipCode: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 5,
      maximum: 15,
    },
    format: {
      pattern: "^[0-9]{5}(?:-[0-9]{4})?$",
    },
  },

  cardNumber: {
    presence: { allowEmpty: false, message: "is required" },
    // format: {
    //   pattern: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
    // },
    length: {
      minimum: 16,
      maximum: 20,
    },
  },
  expirationDate: {},
  securityCode: {
    format: {
      pattern: /^[0-9]{3,4}$/,
    },
    presence: { allowEmpty: false, message: "is required" },
  },
};

import plan1 from "../../images/plan-1.jpg";
import plan2 from "../../images/plan-2.jpg";
import plan3 from "../../images/plan-3.jpg";
import plan4 from "../../images/plan-4.jpg";
import plan5 from "../../images/plan-5.jpg";
import plan6 from "../../images/plan-6.jpg";

export const imagesForPlans = [plan1, plan2, plan3, plan4, plan5, plan6];
// export const images = {
//   Home_Broadband:
//     "https://assets.maccarianagency.com/the-front/illustrations/connected-world.svg",
//   Home_Broadband_1Gbps:
//     "https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg",
//   "Domain_Name_Registration_.SG":
//     "https://assets.maccarianagency.com/the-front/illustrations/learning.svg",
//   "Domain_Name_Registration_.COM":
//     "https://assets.maccarianagency.com/the-front/illustrations/city-driver.svg",
//   Linksys_WRT32X_Lease:
//     "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
//   Mobile_SIM_Only:
//     "https://assets.maccarianagency.com/the-front/illustrations/businesswoman.svg",
//   "Co-Location_42U_Server_Rack":
//     "https://assets.maccarianagency.com/the-front/illustrations/relax-working.svg",
//   Spotify_Premium:
//     "https://assets.maccarianagency.com/the-front/illustrations/connected-world.svg",
//   "Hulu_Live_+":
//     "https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg",
//   Netlifx:
//     "https://assets.maccarianagency.com/the-front/illustrations/learning.svg",
//   Home_Fiber_Premium_Package:
//     "https://assets.maccarianagency.com/the-front/illustrations/city-driver.svg",
//   PlanSubscription:
//     "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
//   "Home_Fiber_Starter_Package-Copy":
//     "https://assets.maccarianagency.com/the-front/illustrations/businesswoman.svg",
//   "Co-Location_27U_Server_Rack":
//     "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
// };

export const images = [
  "https://assets.maccarianagency.com/the-front/illustrations/connected-world.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/learning.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/city-driver.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/businesswoman.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/relax-working.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/connected-world.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/learning.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/city-driver.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/businesswoman.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/connected-world.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/learning.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/city-driver.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/businesswoman.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/relax-working.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/connected-world.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/learning.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/city-driver.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/businesswoman.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/connected-world.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/learning.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/city-driver.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/businesswoman.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/relax-working.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/connected-world.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/relax-in-sofa.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/learning.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/city-driver.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/businesswoman.svg",
  "https://assets.maccarianagency.com/the-front/illustrations/travelers.svg",
];

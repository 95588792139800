import { useState, useEffect } from "react";

import {
  useMediaQuery,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useTheme } from "@material-ui/core/styles";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import validate from "validate.js";

import {
  handleFormState,
  handleFormStateUseEffect,
} from "../../../helpers/HandleFormState";
import "./General.css";
import {
  getAccountSettingsInfo,
  getAllCountries,
  getCountryCode,
} from "../../../helpers/Selector";
import { changePersonalInfo } from "../../../redux/actions/Account";
import { stateSelect } from "../../../shared/data/dataStatesCountries";
import { schema } from "./validationSchema";

const General = (props) => {
  const countrySelect = useSelector(getAllCountries);
  const accountData = useSelector(getAccountSettingsInfo);
  const countryCode = useSelector(getCountryCode);
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const data = {
    firstName: accountData.first_name,
    lastName: accountData.last_name,
    companyName: accountData.company_name,
    streetAddress: accountData.address1,
    addressSecond: accountData.address2,
    addressThird: accountData.address3,
    city: accountData.city,
    stateProvince: accountData.state_prov,
    country: accountData.country_cd,
    zipCode: accountData.postal_cd,
    phoneNumber: accountData.phone,
    phoneExt: accountData.phone_ext,
    phoneNumberCell: accountData.cell_phone,
    phoneNumberWork: accountData.work_phone,
    phoneWorkExt: accountData.work_phone_ext,
    middleName: accountData.middle_initial,
  };

  const [country, setCountry] = useState(data.country ? data.country : "US");
  const [state, setState] = useState(
    data.stateProvince ? data.stateProvince : ""
  );
  const [countryStateCode, setCountryStateCode] = useState(countryCode);

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...data, country: country },
    touched: { country: true, stateProvince: true },
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  const handleSave = () => {
    formState.isValid && state && dispatch(changePersonalInfo(formState.values))
  };

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
    if (event.target.name === "stateProvince") {
      setState(event.target.value);
    }
  };

  const changeState = (event) => {
    setState(event.target.value);
    setFormState(handleFormState(formState, event));
  };
  const changeCountry = (event) => {
    setState("");
    setCountry(event.target.value);
    setCountryStateCode(
      event.target.value === "CA" || event.target.value === "US"
        ? 1
        : event.target.value === "GB"
        ? 44
        : 61
    );
    setFormState(handleFormState(formState, event));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;
  return (
    <div>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            First name *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your first name"
            variant="outlined"
            size="medium"
            name="firstName"
            fullWidth
            type="text"
            helperText={
              hasError("firstName") ? formState.errors.firstName[0] : null
            }
            error={hasError("firstName")}
            value={formState.values.firstName || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Middle initial
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your middle initial"
            variant="outlined"
            size="medium"
            name="middleName"
            fullWidth
            type="text"
            helperText={
              hasError("middleName") ? formState.errors.middleName[0] : null
            }
            error={hasError("middleName")}
            value={formState.values.middleName || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            last name *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your last name"
            variant="outlined"
            size="medium"
            name="lastName"
            fullWidth
            type="text"
            helperText={
              hasError("lastName") ? formState.errors.lastName[0] : null
            }
            error={hasError("lastName")}
            value={formState.values.lastName || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Street Address *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your street address"
            variant="outlined"
            size="medium"
            name="streetAddress"
            fullWidth
            type="text"
            helperText={
              hasError("streetAddress")
                ? formState.errors.streetAddress[0]
                : null
            }
            error={hasError("streetAddress")}
            value={formState.values.streetAddress || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Address 2
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your address 2"
            variant="outlined"
            size="medium"
            name="addressSecond"
            fullWidth
            type="text"
            helperText={
              hasError("addressSecond")
                ? formState.errors.addressSecond[0]
                : null
            }
            error={hasError("addressSecond")}
            value={formState.values.addressSecond || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Address 3
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your address 3"
            variant="outlined"
            size="medium"
            name="addressThird"
            fullWidth
            type="text"
            helperText={
              hasError("addressThird") ? formState.errors.addressThird[0] : null
            }
            error={hasError("addressThird")}
            value={formState.values.addressThird || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Country *
          </Typography>
          <FormControl variant="outlined" className="form-control">
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={country}
              onChange={changeCountry}
              label="Country"
              name="country"
            >
              {countrySelect.map((item, idx) => (
                <MenuItem value={item.country_cd} key={idx + item.country_cd}>
                  {item.country_english}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            State / province *
          </Typography>
          {!!stateSelect[country] ? (
            <FormControl variant="outlined" className="form-control">
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state}
                onChange={changeState}
                label="State / province"
                name="stateProvince"
              >
                {stateSelect[country].map((item, idx) => (
                  <MenuItem value={item.id} key={idx + item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              className="height-for-valid"
              placeholder="State / province"
              variant="outlined"
              size="medium"
              name="stateProvince"
              fullWidth
              type="text"
              helperText={
                hasError("stateProvince")
                  ? formState.errors.stateProvince[0]
                  : null
              }
              error={hasError("stateProvince")}
              value={state}
              onChange={(event) => handleChange(event)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            City *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your city"
            variant="outlined"
            size="medium"
            name="city"
            fullWidth
            type="text"
            helperText={hasError("city") ? formState.errors.city[0] : null}
            error={hasError("city")}
            value={formState.values.city || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Zip Code *
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your zip code"
            variant="outlined"
            size="medium"
            name="zipCode"
            fullWidth
            type="text"
            helperText={
              hasError("zipCode") ? formState.errors.zipCode[0] : null
            }
            error={hasError("zipCode")}
            value={formState.values.zipCode || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Company name
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your company name"
            variant="outlined"
            size="medium"
            name="companyName"
            fullWidth
            type="text"
            helperText={
              hasError("companyName") ? formState.errors.companyName[0] : null
            }
            error={hasError("companyName")}
            value={formState.values.companyName || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Phone number *
          </Typography>
          <InputMask
            mask={`+${countryStateCode || countryCode} (999) 999 9999`}
            maskChar=" "
            value={formState.values.phoneNumber || ""}
            onChange={(event) => handleChange(event)}
          >
            {() => (
              <TextField
                className="height-for-valid"
                placeholder="Your phone number"
                variant="outlined"
                size="medium"
                name="phoneNumber"
                fullWidth
                type="text"
                helperText={
                  hasError("phoneNumber")
                    ? formState.errors.phoneNumber[0]
                    : null
                }
                error={hasError("phoneNumber")}
              />
            )}
          </InputMask>

          {/* <TextField
            className="height-for-valid"
            placeholder="Your phone number"
            variant="outlined"
            size="medium"
            name="phoneNumber"
            fullWidth
            type="text"
            helperText={
              hasError("phoneNumber") ? formState.errors.phoneNumber[0] : null
            }
            error={hasError("phoneNumber")}
            value={formState.values.phoneNumber || ""}
            onChange={(event) => handleChange(event)}
          >
            <InputMask mask="(0)999 999 99 99" maskChar=" " />
          </TextField> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Extension
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your extension phone number"
            variant="outlined"
            size="medium"
            name="phoneExt"
            fullWidth
            type="text"
            helperText={
              hasError("phoneExt") ? formState.errors.phoneExt[0] : null
            }
            error={hasError("phoneExt")}
            value={formState.values.phoneExt || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Work phone number *
          </Typography>
          <InputMask
            mask={`+${countryStateCode || countryCode} (999) 999 9999`}
            maskChar=" "
            value={formState.values.phoneNumberWork || ""}
            onChange={(event) => handleChange(event)}
          >
            {() => (
              <TextField
                className="height-for-valid"
                placeholder="Your work phone number"
                variant="outlined"
                size="medium"
                name="phoneNumberWork"
                fullWidth
                type="text"
                helperText={
                  hasError("phoneNumberWork")
                    ? formState.errors.phoneNumberWork[0]
                    : null
                }
                error={hasError("phoneNumberWork")}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Extension
          </Typography>
          <TextField
            className="height-for-valid"
            placeholder="Your extension work phone number"
            variant="outlined"
            size="medium"
            name="phoneWorkExt"
            fullWidth
            type="text"
            helperText={
              hasError("phoneWorkExt") ? formState.errors.phoneWorkExt[0] : null
            }
            error={hasError("phoneWorkExt")}
            value={formState.values.phoneWorkExt || ""}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className="input-title"
          >
            Cell phone number
          </Typography>
          <InputMask
            mask={`+${countryStateCode || countryCode} (999) 999 9999`}
            maskChar=" "
            value={formState.values.phoneNumberCell || ""}
            onChange={(event) => handleChange(event)}
          >
            {() => (
              <TextField
                className="height-for-valid"
                placeholder="Your cell phone number"
                variant="outlined"
                size="medium"
                name="phoneNumberCell"
                fullWidth
                type="text"
                helperText={
                  hasError("phoneNumberCell")
                    ? formState.errors.phoneNumberCell[0]
                    : null
                }
                error={hasError("phoneNumberCell")}
              />
            )}
          </InputMask>
        </Grid>
        <Grid item container justify="space-between" xs={12}>
          <Button
            type="submit"
            color="secondary"
            size="large"
            onClick={() => history.push("/account/settings")}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            size="large"
            onClick={handleSave}
          >
            save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default General;

import { put, takeEvery, call, select } from "redux-saga/effects";

import {
  callPayment,
  callBothPayment,
  callGetPaymentMethod,
} from "../../service/apiService/apiService";
import {
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_FAILED,
  ADD_PAYMENT_METHOD_SUCCESS,
  CHECK_TAXES,
  CHECK_TAXES_FAILED,
  CHECK_TAXES_SUCCESS,
  FAILED_PAYMENT_FAILED,
  FAILED_PAYMENT_SUCCESS,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_FAILED,
  GET_PAYMENT_METHOD_SUCCESS,
  POST_PAYMENT,
  POST_PAYMENT_FAILED,
  POST_PAYMENT_SUCCESS,
  REMOVE_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD_FAILED,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  SUCCESS_PAYMENT_FAILED,
  SUCCESS_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_FAILED,
  UPDATE_PAYMENT_METHOD_SUCCESS,
} from "../actionNames/Payment";

function* doPostPayment(action) {
  const history = action.history;
  const cardData = action.data;
  try {
    const state = yield select();

    const userInfo = { ...state.auth.user };

    let updatedUserInfoPlans = userInfo.invoice_info.filter((item) => {
      return item.rate !== 0;
    });

    let masterPlans = [];
    let clientPlan = [];
    userInfo.invoice_info.forEach((i) => {
      masterPlans.push(i.plan_no);
      clientPlan.push(i.client_plan_instance_id);
    });

    let formData = new FormData();

    formData.append("cc_no", cardData.cardNumber);
    formData.append("cc_exp_mm", cardData.month);
    formData.append("cc_exp_yyyy", cardData.date);
    formData.append("cvv", cardData.securityCode);
    formData.append("submitPayment", "Submit");
    formData.append("inSessionID", userInfo.session_id);
    formData.append("master_plan_instance_no", masterPlans.join(","));
    formData.append("full_invoice_no", userInfo.full_invoice_num);
    formData.append("client_master_plan_instance_id", clientPlan.join(","));
    formData.append("client_no", userInfo.client_number);
    formData.append("mode", userInfo.mode);
    formData.append("formOfPayment", "CreditCard");

    yield put({ type: "START_LOADING" });

    const answerBackend = yield call(
      callPayment,
      "POST",
      `direct_post_eom.php`,
      formData
    );

    const responseUrl = answerBackend.request.responseURL;
    const goodError = "errors=0";
    const badError = "errors=1";
    let result;
    console.log(responseUrl, answerBackend);
    if (responseUrl.includes(goodError)) {
      try {
        result = goodError;

        const answerBackendSuccessPayment = yield call(
          callBothPayment,
          "POST",
          `successpayment`,
          { error_code: 0 }
        );

        const status = answerBackendSuccessPayment.data.Status;
        const message = answerBackendSuccessPayment.data.Message;
        yield put({
          type: SUCCESS_PAYMENT_SUCCESS,
          status,
          message,
        });
        history.push("/checkout/review");
      } catch (error) {
        yield put({
          type: SUCCESS_PAYMENT_FAILED,
          error: error.response,
        });
      }
    } else {
      try {
        result = badError;

        const answerBackendFailedPayment = yield call(
          callBothPayment,
          "POST",
          `failedpayment`,
          { error_code: 1 }
        );

        yield put({
          type: FAILED_PAYMENT_SUCCESS,
          answerBackendFailedPayment,
        });
      } catch (error) {
        yield put({
          type: FAILED_PAYMENT_FAILED,
          error: error.response,
        });
      }
    }

    yield put({
      type: POST_PAYMENT_SUCCESS,
      result,
    });

    yield put({ type: "END_LOADING" });
  } catch (error) {
    yield put({
      type: POST_PAYMENT_FAILED,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doGetPaymentMethod(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callGetPaymentMethod,
      "POST",
      `getpaymentmethod`,
      {},
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: GET_PAYMENT_METHOD_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    yield put({
      type: GET_PAYMENT_METHOD_FAILED,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doRemovePaymentMethod(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callGetPaymentMethod,
      "POST",
      `removepaymentmethod`,
      { payment_method_no: action.data },
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: REMOVE_PAYMENT_METHOD_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    yield put({
      type: REMOVE_PAYMENT_METHOD_FAILED,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doUpdatePaymentMethod(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);
    const history = action.history;
    const updatedData = {
      payment_method_no: action.data.payment_method_no,
      pay_method_type: action.data.pay_method_type,
      bill_first_name: action.data.firstName,
      bill_last_name: action.data.lastName,
      cc_num: +action.data.cardNumber,
      cc_expire_mm: action.data.month,
      cc_expire_yyyy: action.data.date,
      cvv: +action.data.securityCode,
      bill_postal_cd: +action.data.zipCode,
    };
    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callGetPaymentMethod,
      "POST",
      `updatepaymentmethod`,
      updatedData,
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: UPDATE_PAYMENT_METHOD_SUCCESS,
      status,
      data,
    });
    history.push("/account/paymentInfo");
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const status = error.response.data.Status;
    const message = error.response.data.Message;
    yield put({
      type: UPDATE_PAYMENT_METHOD_FAILED,
      status,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doAddPaymentMethod(action) {
  try {
    yield put({ type: "START_LOADING" });
    console.log(action);

    const updatedData = {
      pay_method_type: 1,
      bill_first_name: action.data.firstName,
      bill_last_name: action.data.lastName,
      cc_num: +action.data.cardNumber,
      cc_expire_mm: action.data.month,
      cc_expire_yyyy: action.data.date,
      cvv: +action.data.securityCode,
      bill_postal_cd: +action.data.zipCode,
    };

    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callGetPaymentMethod,
      "POST",
      `addpaymentmethod`,
      updatedData,
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: ADD_PAYMENT_METHOD_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    yield put({
      type: ADD_PAYMENT_METHOD_FAILED,
    });
    yield put({ type: "END_LOADING" });
  }
}

function* doCheckTaxes(action) {
  try {
    yield put({ type: "START_LOADING" });

    const state = yield select();
    const billingData = { ...state.checkout.billing };
    const shippingData = { ...state.checkout.shipping };
    const itemsInCart = [...state.cart.itemsInCart];
    const masterPlan = itemsInCart.filter((i) => i.schedule_no);
    let plans;
    let objectForReq;
    console.log("MASTER", masterPlan);
    let countForMaster = 0;
    let countForSupplemental = 0;
    if (action.data.billing_group_no) {
      plans = masterPlan.map((i) => {
        if (!i.isSupplemental) {
          countForSupplemental++;
          countForMaster++;
          countForMaster = countForSupplemental;
          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            billing_group_no: action.data.billing_group_no,
            plan_instance_idx: countForSupplemental,
          };
        } else {
          countForSupplemental++;

          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            billing_group_no: action.data.billing_group_no,
            plan_instance_idx: countForSupplemental,
            parent_plan_instance_idx: countForMaster,
          };
        }
      });

      objectForReq = {
        plan_updates: plans,
        address1: billingData.streetAddress,
        address2: billingData.addressSecond,
        city: billingData.city,
        zipCode: billingData.zipCode,
        country: billingData.country,
        phone: shippingData.phoneNumber,
        firstName: shippingData.firstName,
        lastName: shippingData.lastName,
        state: shippingData.stateProvince,
        payMethod: action.data.payMethod,
      };
    } else {
      plans = masterPlan.map((i) => {
        if (!i.isSupplemental) {
          countForSupplemental++;
          countForMaster++;
          countForMaster = countForSupplemental;
          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            plan_instance_idx: countForSupplemental,
          };
        } else {
          countForSupplemental++;

          return {
            alt_rate_schedule_no: i.schedule_no,
            new_plan_no: i.plan_no,
            plan_instance_idx: countForSupplemental,
            parent_plan_instance_idx: countForMaster,
          };
        }
      });

      objectForReq = {
        plan_updates: plans,
        address1: billingData.streetAddress,
        address2: billingData.addressSecond,
        city: billingData.city,
        zipCode: billingData.zipCode,
        country: billingData.country,
        phone: shippingData.phoneNumber,
        firstName: shippingData.firstName,
        lastName: shippingData.lastName,
        state: shippingData.stateProvince,
        firstNameShipping: shippingData.firstName,
        lastNameShipping: shippingData.lastName,
        firstNameBilling: billingData.firstName,
        lastNameBilling: billingData.lastName,
        address1Billing: billingData.streetAddress,
        address2Billing: billingData.addressSecond,
        payMethod: action.data.payMethod,
        countryBilling: billingData.country,
        stateBilling: billingData.stateProvince,
        cityBilling: billingData.city,
        zipCodeBilling: billingData.zipCode,
        phoneBilling: shippingData.phoneNumber,
      };
    }

    const sessionId = localStorage.getItem("sessionId");
    const answerBackend = yield call(
      callGetPaymentMethod,
      "POST",
      `checktaxes`,
      objectForReq,
      sessionId
    );
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: CHECK_TAXES_SUCCESS,
      status,
      data,
      method: action.data.payMethod,
    });

    yield put({ type: "END_LOADING" });
  } catch (error) {
    const status = error.response.data.Status;
    const message = error.response.data.Message;
    yield put({
      type: CHECK_TAXES_FAILED,
      status,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

export function* paymentSaga() {
  yield takeEvery(POST_PAYMENT, doPostPayment);
  yield takeEvery(GET_PAYMENT_METHOD, doGetPaymentMethod);
  yield takeEvery(REMOVE_PAYMENT_METHOD, doRemovePaymentMethod);
  yield takeEvery(UPDATE_PAYMENT_METHOD, doUpdatePaymentMethod);
  yield takeEvery(ADD_PAYMENT_METHOD, doAddPaymentMethod);
  yield takeEvery(CHECK_TAXES, doCheckTaxes);
}

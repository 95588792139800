import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

import "./assets/css/index.css";

import history from "./history";
import Main from "./main";

const App = () => {
  return (
    <Router history={history}>
      <Main />
    </Router>
  );
};

export default App;

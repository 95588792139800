export const ADD_BILLING_ADDRESS = "ADD_BILLING_ADDRESS";
export const ADD_SHIPPING_ADDRESS = "ADD_SHIPPING_ADDRESS";

export const PLACE_ORDER = "PLACE_ORDER";

export const LOGGED_PLACE_ORDER = "LOGGED_PLACE_ORDER";
export const LOGGED_PLACE_ORDER_SUCCESS = "LOGGED_PLACE_ORDER_SUCCESS";
export const LOGGED_PLACE_ORDER_FAILED = "LOGGED_PLACE_ORDER_FAILED";

export const GET_LOGGED_PAYMENT_INFO = "GET_LOGGED_PAYMENT_INFO";
export const GET_LOGGED_PAYMENT_INFO_SUCCESS = "GET_LOGGED_PAYMENT_INFO_SUCCESS";
export const GET_LOGGED_PAYMENT_INFO_FAILED = "GET_LOGGED_PAYMENT_INFO_FAILED";

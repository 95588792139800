export const GET_API_KEYS = "GET_API_KEYS";
export const GET_API_KEYS_SUCCESS = "GET_API_KEYS_SUCCESS";
export const GET_API_KEYS_FAILED = "GET_API_KEYS_FAILED";

export const SYNC_PLANS = "SYNC_PLANS";
export const SYNC_PLANS_SUCCESS = "SYNC_PLANS_SUCCESS";
export const SYNC_PLANS_FAILED = "SYNC_PLANS_FAILED";

export const UPDATE_API_KEYS = "UPDATE_API_KEYS";
export const UPDATE_API_KEYS_SUCCESS = "UPDATE_API_KEYS_SUCCESS";
export const UPDATE_API_KEYS_FAILED = "UPDATE_API_KEYS_FAILED";

export const GET_STYLES = "GET_STYLES";
export const GET_STYLES_SUCCESS = "GET_STYLES_SUCCESS";
export const GET_STYLES_FAILED = "GET_STYLES_FAILED";

export const UPDATE_STYLES = "UPDATE_STYLES";
export const UPDATE_STYLES_SUCCESS = "UPDATE_STYLES_SUCCESS";
export const UPDATE_STYLES_FAILED = "UPDATE_STYLES_FAILED";

export const UPLOAD_BACKGROUND_IMAGE = "UPLOAD_BACKGROUND_IMAGE";
export const UPLOAD_BACKGROUND_IMAGE_SUCCESS = "UPLOAD_BACKGROUND_IMAGE_SUCCESS";
export const UPLOAD_BACKGROUND_IMAGE_FAILED = "UPLOAD_BACKGROUND_IMAGE_FAILED";

export const UPLOAD_LOGO_IMAGE = "UPLOAD_LOGO_IMAGE";
export const UPLOAD_LOGO_IMAGE_SUCCESS = "UPLOAD_LOGO_IMAGE_SUCCESS";
export const UPLOAD_LOGO_IMAGE_FAILED = "UPLOAD_LOGO_IMAGE_FAILED";

export const GET_IMAGES = "GET_IMAGES";
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS";
export const GET_IMAGES_FAILED = "GET_IMAGES_FAILED";

export const UPDATE_POST_METHOD = "UPDATE_POST_METHOD";
export const UPDATE_POST_METHOD_SUCCESS = "UPDATE_POST_METHOD_SUCCESS";
export const UPDATE_POST_METHOD_FAILED = "UPDATE_POST_METHOD_FAILED";

export const GET_POST_METHOD = "GET_POST_METHOD";
export const GET_POST_METHOD_SUCCESS = "GET_POST_METHOD_SUCCESS";
export const GET_POST_METHOD_FAILED = "GET_POST_METHOD_FAILED";

export const UPDATE_EVENT_LISTENER_KEYS = "UPDATE_EVENT_LISTENER_KEYS";
export const UPDATE_EVENT_LISTENER_KEYS_SUCCESS = "UPDATE_EVENT_LISTENER_KEYS_SUCCESS";
export const UPDATE_EVENT_LISTENER_KEYS_FAILED = "UPDATE_EVENT_LISTENER_KEYS_FAILED";

export const GET_EVENT_LISTENER_KEYS = "GET_EVENT_LISTENER_KEYS";
export const GET_EVENT_LISTENER_KEYS_SUCCESS = "GET_EVENT_LISTENER_KEYS_SUCCESS";
export const GET_EVENT_LISTENER_KEYS_FAILED = "GET_EVENT_LISTENER_KEYS_FAILED";

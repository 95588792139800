export const expDate = [
  {
    value: 2020,
  },
  {
    value: 2021,
  },
  {
    value: 2022,
  },
  {
    value: 2023,
  },
  {
    value: 2024,
  },
  {
    value: 2025,
  },
  {
    value: 2026,
  },
  {
    value: 2027,
  },
  {
    value: 2028,
  },
  {
    value: 2029,
  },
  {
    value: 2030,
  },
  {
    value: 2031,
  },
  {
    value: 2032,
  },
  {
    value: 2033,
  },
  {
    value: 2034,
  },
  {
    value: 2035,
  },
  {
    value: 2036,
  },
  {
    value: 2037,
  },
  {
    value: 2038,
  },
  {
    value: 2039,
  },
  {
    value: 2040,
  },
];
export const expMonth = [
  {
    value: 1,
    month: "January",
  },
  {
    value: 2,
    month: "February",
  },
  {
    value: 3,
    month: "March",
  },
  {
    value: 4,
    month: "April",
  },
  {
    value: 5,
    month: "May",
  },
  {
    value: 6,
    month: "June",
  },
  {
    value: 7,
    month: "July",
  },
  {
    value: 8,
    month: "August",
  },
  {
    value: 9,
    month: "September",
  },
  {
    value: 10,
    month: "October",
  },
  {
    value: 11,
    month: "November",
  },
  {
    value: 12,
    month: "December",
  },
];

import { IS_OPEN_CART } from "../actionNames/Notification";

export const initialState = {
  isOpenCartFromNotification: false,
};

export function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case IS_OPEN_CART: {
      return {
        isOpenCartFromNotification: action.data,
      };
    }
    default:
      return state;
  }
}
export const notification = (state) => state.notification;

export const initialState = {
  loading: false,
};

export function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case "START_LOADING": {
      return {
        loading: true,
      };
    }
    case "END_LOADING": {
      return {
        loading: false,
      };
    }
    default:
      return state;
  }
}
export const loading = (state) => state.loading;

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED";

export const PREREGISTER_USER = "PREREGISTER_USER";
export const PREREGISTER_USER_SUCCESS = "PREREGISTER_USER_SUCCESS";
export const PREREGISTER_USER_FAILED = "PREREGISTER_USER_FAILED";

export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";

export const ACTIVATE_PROFILE_USER = "ACTIVATE_PROFILE_USER";
export const ACTIVATE_PROFILE_USER_SUCCESS = "ACTIVATE_PROFILE_USER_SUCCESS";
export const ACTIVATE_PROFILE_USER_FAILED = "ACTIVATE_PROFILE_USER_FAILED";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED";

export const CLEAR_STATUSES = "CLEAR_STATUSES";

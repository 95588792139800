import {
  GET_API_KEYS,
  GET_EVENT_LISTENER_KEYS,
  GET_IMAGES,
  GET_POST_METHOD,
  GET_STYLES,
  SYNC_PLANS,
  UPDATE_API_KEYS,
  UPDATE_EVENT_LISTENER_KEYS,
  UPDATE_POST_METHOD,
  UPDATE_STYLES,
  UPLOAD_BACKGROUND_IMAGE,
  UPLOAD_LOGO_IMAGE,
} from "../actionNames/Configure";

export function getApiKeys(data) {
  return { type: GET_API_KEYS, data };
}
export function updateApiKeys(data) {
  return { type: UPDATE_API_KEYS, data };
}
export function syncPlans(data) {
  return { type: SYNC_PLANS, data };
}
export function getStyles(data) {
  return { type: GET_STYLES, data };
}
export function updateStyles(data) {
  return { type: UPDATE_STYLES, data };
}
export function uploadBackgroundImage(data) {
  return { type: UPLOAD_BACKGROUND_IMAGE, data };
}
export function uploadLogoImage(data) {
  return { type: UPLOAD_LOGO_IMAGE, data };
}
export function getImages(data) {
  return { type: GET_IMAGES, data };
}
export function getPostMethod(data) {
  return { type: GET_POST_METHOD, data };
}
export function updatePostMethod(data) {
  return { type: UPDATE_POST_METHOD, data };
}
export function updateEventListenerKeys(data) {
  return { type: UPDATE_EVENT_LISTENER_KEYS, data };
}
export function getEventListenerKeys(data) {
  return { type: GET_EVENT_LISTENER_KEYS, data };
}

import { useEffect } from "react";

import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { getErrorMessageLogin, getErrorMessageRegister, getLoginUserInfo } from "../../../helpers/Selector";
import { loginUser, preRegisterUser } from "../../../redux/actions/Auth";
import { addBillingAddress, addShippingAddress, getLoggedPaymentInfo, placeOrder } from "../../../redux/actions/Checkout";
import "./StepperCheckout.css";
import { postPayment } from "../../../redux/actions/Payment";
import { clearCart } from "../../../redux/actions/Products";
import CartDetails from "../../Cart/CartDetails/CartDetails";
import { getSteps } from "./helpFunctionality";
import StepperCheckoutContent from "./StepperCheckoutContent";

export const STEPPER_ROUTES = {
  ACCOUNT: "/checkout/account",
  SHIPPING_ADDRESS: "/checkout/shipping-address",
  PAYMENT: "/checkout/payment",
  REVIEW: "/checkout/review",
};

export const STEPS = {
  [STEPPER_ROUTES.ACCOUNT]: 0,
  [STEPPER_ROUTES.SHIPPING_ADDRESS]: 1,
  [STEPPER_ROUTES.PAYMENT]: 2,
  [STEPPER_ROUTES.REVIEW]: 3,
};

export default function StepperCheckout() {
  const errorMessageRegister = useSelector(getErrorMessageRegister);
  const errorMessageLogin = useSelector(getErrorMessageLogin);
  const { session_id } = useSelector(getLoginUserInfo);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const steps = getSteps();

  const handleNext = (step, formState, shippingFormState) => {
    switch (step) {
      case "sign-in": {
        if (formState.isValid) {
          dispatch(loginUser(formState.values, history, { checkout: true }));
        }
        return;
      }
      case "sign-up": {
        if (formState.isValid) {
          dispatch(preRegisterUser(formState.values, history, { checkout: true }));
        }
        return;
      }
      case "shipping": {
        if (formState.isValid) {
          dispatch(
            addShippingAddress({
              ...formState.values,
            })
          );
          dispatch(addBillingAddress({ ...shippingFormState.values }));
          if (session_id) {
            history.push("/checkout/payment");
          } else {
            dispatch(placeOrder(formState.values, history));
          }
        }
        return;
      }
      case "billing-shipping": {
        if (shippingFormState.isValid) {
          dispatch(
            addShippingAddress({
              ...shippingFormState.values,
            })
          );
          dispatch(
            addBillingAddress({
              ...shippingFormState.values,
            })
          );
          if (session_id) {
            history.push("/checkout/payment");
          } else {
            dispatch(placeOrder(formState.values, history));
          }
        }
        return;
      }
      case "payment": {
        if (formState.isValid) {
          dispatch(
            postPayment(
              {
                ...formState.values,
                month: formState.month,
                date: formState.date,
              },
              history
            )
          );
        }
        return;
      }

      default: {
        return;
      }
    }
  };

  const handleFinish = () => {
    dispatch(clearCart());
    if (session_id) {
      history.push("/account/settings");
    } else history.push("/sign-in");
  };

  const handleBack = () => {
    history.goBack();
  };
  useEffect(() => {
    if (session_id) {
      dispatch(getLoggedPaymentInfo(history));
    }
  }, [session_id]);

  return (
    <div className="root-stepper-checkout">
      <div className="wrap-main-stepper">
        <Stepper activeStep={STEPS[location.pathname]} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <StepperCheckoutContent
          handleNext={handleNext}
          errorMessageRegister={errorMessageRegister}
          errorMessageLogin={errorMessageLogin}
          handleFinish={handleFinish}
          handleBack={handleBack}
        />
      </div>

      <div className="cart-details-wrap">
        <CartDetails checkout={true} />
      </div>
    </div>
  );
}

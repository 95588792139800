import {
  CURRENCY_ACCOUNT_CHANGE,
  CURRENCY_CHANGE,
  CURRENCY_GET_FAILED,
  CURRENCY_GET_SUCCESS,
} from "../actionNames/Currency";

export const initialState = {
  currency: "",
  currency_symbol: "",
  currencyList: [],
  currencyAccount: "",
  currencyAccountSymbol: "",
};

export function currencyReducer(state = initialState, action) {
  switch (action.type) {
    case CURRENCY_CHANGE: {
      return {
        ...state,
        currency: action.data,
        currency_symbol: action.currency_symbol,
      };
    }
    case CURRENCY_ACCOUNT_CHANGE: {
      console.log(action);
      console.log(state.currencyList);
      const currencyAccount = state.currencyList.find(
        (i) => i.currency_cd === action.currencyCD
      );
      return {
        ...state,
        currencyAccount: action.currencyCD,
        currencyAccountSymbol: currencyAccount.currency_symbol,
      };
    }
    case CURRENCY_GET_SUCCESS: {
      console.log(action);
      const updatedCurrencyList = action.data.map((item) => {
        return {
          currency_cd: item.currency_cd.toLowerCase(),
          currency_symbol: item.currency_symbol ? item.currency_symbol : "$",
        };
      });
      return {
        ...state,
        currencyList: updatedCurrencyList,
      };
    }
    case CURRENCY_GET_FAILED: {
      console.log(action);
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}
export const currency = (state) => state.currency;

import { colors } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 9px 18px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(2),
  },
  cardMedia: {
    height: 290,
    padding: theme.spacing(3, 3, 0, 3),
    position: "relative",
    background: colors.indigo[50],
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  image: {
    objectFit: "contain",
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  hearIconContainer: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
  },
  ratingContainer: {
    margin: theme.spacing(2, 0),
  },
  ratingIcon: {
    color: colors.yellow[700],
    marginRight: theme.spacing(1 / 2),
  },
  priceCta: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    padding: 20,
  },
  title: {
    fontSize: 40,
    fontWeight: "bold",
    "@media (max-width:540px)": {
      fontSize: 30,
    },
  },
  subtitle: {
    color: "#718096",
    fontSize: 21,
    "@media (max-width:540px)": {
      fontSize: 16,
    },
    "@media (max-width:900px)": {
      fontSize: 18,
    },
  },
  wrapTitleSubtitle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 20,
  },
}));

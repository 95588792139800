import { CLEAR_STATUSES } from "../actionNames/Auth";
import {
  ADD_BILLING_ADDRESS,
  ADD_SHIPPING_ADDRESS,
  GET_LOGGED_PAYMENT_INFO_FAILED,
  GET_LOGGED_PAYMENT_INFO_SUCCESS,
  LOGGED_PLACE_ORDER_FAILED,
  LOGGED_PLACE_ORDER_SUCCESS,
} from "../actionNames/Checkout";
import { CHECK_TAXES_FAILED } from "../actionNames/Payment";

export const initialState = {
  billing: {},
  shipping: {},
  paymentInfo: null,
  successPayment: {
    status: "",
    message: "",
  },
};

export function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_BILLING_ADDRESS: {
      console.log(action);
      return {
        ...state,
        billing: action.data,
      };
    }
    case ADD_SHIPPING_ADDRESS: {
      console.log(action);
      return {
        ...state,
        shipping: action.data,
      };
    }

    case LOGGED_PLACE_ORDER_SUCCESS: {
      return {
        ...state,
        successPayment: {
          message: action.data.Message,
          status: action.data.Status,
        },
      };
    }
    case LOGGED_PLACE_ORDER_FAILED: {
      return {
        ...state,
        successPayment: {
          message: action.data.Message,
          status: action.data.Status,
        },
      };
    }

    case GET_LOGGED_PAYMENT_INFO_SUCCESS: {
      console.log(action);
      return {
        ...state,
        paymentInfo: action.data,
      };
    }
    case GET_LOGGED_PAYMENT_INFO_FAILED: {
      console.log(action);
      return {
        ...state,
      };
    }

    case CHECK_TAXES_FAILED: {
      return {
        ...state,
        successPayment: {
          status: action.status,
          message: action.message,
        },
      };
    }

    case CLEAR_STATUSES: {
      return {
        ...state,
        successPayment: {
          status: "",
          message: "",
        },
      };
    }

    default:
      return state;
  }
}
export const checkout = (state) => state.checkout;

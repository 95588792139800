import { useEffect, useState } from "react";

import { Typography, Grid, Button, TextField } from "@material-ui/core";
import validate from "validate.js";

import {
  handleFormState,
  handleFormStateUseEffect,
} from "../../../../helpers/HandleFormState";
import { LearnMoreLink } from "../../../../shared/atoms";
import { schema } from "./validationSchema";
import "./Form.css";

const FormSignUp = ({ changeStep, checkout, onNext, errorMessage }) => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(handleFormStateUseEffect(formState, errors));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState(handleFormState(formState, event));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formState.isValid && !checkout) {
      window.location.replace("/account/settings");
    }
    if (formState.isValid && checkout) {
      onNext("sign-up", formState);
    }
    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className="root-sign-up">
      {checkout ? (
        <div className="title-checkout-create">Create an Account</div>
      ) : null}
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className="input-title"
            >
              First name *
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="First name"
              variant="outlined"
              size="medium"
              name="firstName"
              fullWidth
              helperText={
                hasError("firstName") ? formState.errors.firstName[0] : null
              }
              error={hasError("firstName")}
              onChange={handleChange}
              type="firstName"
              value={formState.values.firstName || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className="input-title"
            >
              Last name *
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Last name"
              variant="outlined"
              size="medium"
              name="lastName"
              fullWidth
              helperText={
                hasError("lastName") ? formState.errors.lastName[0] : null
              }
              error={hasError("lastName")}
              onChange={handleChange}
              type="lastName"
              value={formState.values.lastName || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className="input-title"
            >
              E-mail *
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="E-mail"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError("email") ? formState.errors.email[0] : null}
              error={hasError("email")}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className="input-title"
            >
              Password *
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Password"
              variant="outlined"
              size="medium"
              name="password"
              fullWidth
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              error={hasError("password")}
              onChange={handleChange}
              type="password"
              value={formState.values.password || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className="input-title"
            >
              Confirm your password *
            </Typography>
            <TextField
              className="height-for-valid"
              placeholder="Confirm your password"
              variant="outlined"
              size="medium"
              name="confirmPassword"
              fullWidth
              helperText={
                hasError("confirmPassword")
                  ? formState.errors.confirmPassword[0]
                  : null
              }
              error={hasError("confirmPassword")}
              onChange={handleChange}
              type="password"
              value={formState.values.confirmPassword || ""}
            />
          </Grid>
          {checkout ? (
            <Grid container item>
              <div className="text-error">{errorMessage}</div>

              <Grid item xs={12} className="title-checkout-have-an-account">
                Have an account?
              </Grid>
              <Grid className="text-sign-in">
                <div className="sign-text" onClick={() => changeStep(2)}>
                  Sign in
                </div>{" "}
                to check out more quickly and easily.
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  submit
                </Button>
              </Grid>
            </Grid>
          ) : (
            <div className="wrap-button-signup-form">
              <Grid item xs={12}>
                <i>
                  <Typography variant="subtitle2">
                    Fields that are marked with * sign are required.
                  </Typography>
                </i>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  Send
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  align="center"
                >
                  Already have an account?{" "}
                  <LearnMoreLink title="Sign in" href="/sign-in" />
                </Typography>
              </Grid>
            </div>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default FormSignUp;

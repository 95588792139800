import { imagesForPlans } from "../../shared/data/imagesForPlans";
import { CURRENCY_CHANGE } from "../actionNames/Currency";
import {
  CLEAR_FILTER_PLAN_GROUPS,
  FILTER_PLAN_GROUPS,
  GET_ALL_PLANS_FAILED,
  GET_ALL_PLANS_SUCCESS,
  GET_GROUPS_SUCCESS,
  GET_SUPPLEMENTAL_PLANS_FAILED,
  GET_SUPPLEMENTAL_PLANS_SUCCESS,
} from "../actionNames/Plan";

export const initialState = {
  currencyOfPlans: "usd",

  plans: null,
  originalPlans: null,
  groups: null,
  supplementalPlans: null,
};

export function planReducer(state = initialState, action) {
  switch (action.type) {
    case FILTER_PLAN_GROUPS: {
      const currentPlanGroups = state.originalPlans;
      const currency = state.currencyOfPlans;
      const filteredItem = action.data;
      const updatedPlans = currentPlanGroups.filter((item) =>
        item.plan_name.toLowerCase().includes(filteredItem.toLowerCase())
      );
      const updatedByCurrencyPlans = updatedPlans.map((item) => {
        let updatedRates = item.rate_sched.details.filter((i) => i.schedule_currency === currency);

        return {
          ...item,
          rate_sched: {
            ...item.rate_sched,
            details: updatedRates,
          },
        };
      });

      return {
        ...state,
        plans: updatedByCurrencyPlans,
      };
    }

    case CLEAR_FILTER_PLAN_GROUPS: {
      const currentPlanGroups = state.originalPlans;
      const currency = state.currencyOfPlans;
      const updatedByCurrencyPlans = currentPlanGroups.map((item) => {
        let updatedRates = item.rate_sched.details.filter((i) => i.schedule_currency === currency);

        return {
          ...item,
          rate_sched: {
            ...item.rate_sched,
            details: updatedRates,
          },
        };
      });

      return {
        ...state,
        plans: updatedByCurrencyPlans,
      };
    }

    case CURRENCY_CHANGE: {
      const currentPlanGroups = state.plans;
      const originalPlanGroups = state.originalPlans;
      let updatedPlanGroups;
      if (currentPlanGroups || originalPlanGroups) {
        const updatedPlans = originalPlanGroups.map((item) => {
          let updatedRates = item.rate_sched.details.filter((i) => i.schedule_currency === action.data);
          return {
            ...item,
            rate_sched: {
              ...item.rate_sched,
              details: updatedRates,
            },
          };
        });

        updatedPlanGroups = updatedPlans;
      }

      return {
        ...state,
        currencyOfPlans: action.data,
        plans: updatedPlanGroups,
        supplementalPlans: null,
      };
    }

    case GET_ALL_PLANS_SUCCESS: {
      console.log(action);
      const currency = state.currencyOfPlans;
      const currentPlanGroups = action.data.map((item) => {
        let max = imagesForPlans.length;
        return {
          ...item,
          img: imagesForPlans[Math.floor(Math.random() * max)],
        };
      });

      const updatedPlans = currentPlanGroups.map((item) => {
        let updatedRates = item.rate_sched.details.filter((i) => {
          return i.schedule_currency === currency;
        });
        return {
          ...item,
          rate_sched: {
            ...item.rate_sched,
            details: updatedRates,
          },
        };
      });

      return {
        ...state,
        plans: updatedPlans,
        originalPlans: currentPlanGroups,
      };
    }

    case GET_ALL_PLANS_FAILED: {
      return {
        ...state,
      };
    }

    case GET_GROUPS_SUCCESS: {
      console.log(action);
      return {
        ...state,
        groups: action.data,
      };
    }

    case GET_SUPPLEMENTAL_PLANS_SUCCESS: {
      console.log(action);
      const supplementalArray = action.data;
      let current = [];
      supplementalArray.forEach((item) => {
        if (item.rate_sched.length) {
          current.push({ ...item.rate_sched, plan_no: item.plan_no });
        }
      });
      return {
        ...state,
        supplementalPlans: current,
      };
    }

    case GET_SUPPLEMENTAL_PLANS_FAILED: {
      console.log(action);
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
export const plan = (state) => state.plan;

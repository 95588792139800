export const api = "https://accountselfservice.com";
// export const api = "https://s1.accountselfservice.com";
// export const api = "https://s2.accountselfservice.com";
// export const api = "https://s3.accountselfservice.com";

// const api = "http://localhost:3050/api";

export const urlBackend = {
  apiUser: `${api}/api/user`,
  apiProfile: `${api}/api/profile`,
  apiPlan: `${api}/api/plans`,
  apiPayment: `https://secure.current.stage.ariasystems.net/api`,
  apitest: `${api}/api`,
  apiBothPayment: `${api}/api/payment`,
  apiGroups: `${api}/api/groups`,
  apiBillingInvoice: `${api}/api/billings`,
  apiAccountPlans: `${api}/api/plans`,
  apiConfigure: `${api}/api/admin`,
  apiEventLogs: `${api}/api/eventlogs`,
  apiBasicInfo: `${api}/api/static`,
};

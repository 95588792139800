import { put, takeEvery, call } from "redux-saga/effects";

import { callBasicInfo } from "../../service/apiService/apiService";
import {
  COUNTRIES_GET,
  COUNTRIES_GET_FAILED,
  COUNTRIES_GET_SUCCESS,
} from "../actionNames/Countries";

function* doGetCountries(action) {
  try {
    yield put({ type: "START_LOADING" });
    const answerBackend = yield call(callBasicInfo, "GET", `getcountries`, {});
    const status = answerBackend.status;
    const data = answerBackend.data;

    yield put({
      type: COUNTRIES_GET_SUCCESS,
      status,
      data,
    });
    yield put({ type: "END_LOADING" });
  } catch (error) {
    const message = error.response.data.Message;
    yield put({
      type: COUNTRIES_GET_FAILED,
      message,
    });
    yield put({ type: "END_LOADING" });
  }
}

export function* countriesSaga() {
  yield takeEvery(COUNTRIES_GET, doGetCountries);
}

import {
  ADD_BILLING_ADDRESS,
  ADD_SHIPPING_ADDRESS,
  GET_LOGGED_PAYMENT_INFO,
  LOGGED_PLACE_ORDER,
  PLACE_ORDER,
} from "../actionNames/Checkout";

export function addBillingAddress(data) {
  return { type: ADD_BILLING_ADDRESS, data };
}
export function addShippingAddress(data) {
  return { type: ADD_SHIPPING_ADDRESS, data };
}
export function placeOrder(data, history) {
  return { type: PLACE_ORDER, data, history };
}
export function loggedPlaceOrder(data, history) {
  return { type: LOGGED_PLACE_ORDER, data, history };
}
export function getLoggedPaymentInfo(history) {
  return { type: GET_LOGGED_PAYMENT_INFO, history };
}

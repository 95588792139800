import {
  COUNTRIES_GET_FAILED,
  COUNTRIES_GET_SUCCESS,
} from "../actionNames/Countries";
export const initialState = {
  countryList: [],
};

export function countriesReducer(state = initialState, action) {
  switch (action.type) {
    //   case CURRENCY_CHANGE: {
    //     return {
    //       ...state,
    //       currency: action.data,
    //       currency_symbol: action.currency_symbol,
    //     };
    //   }
    case COUNTRIES_GET_SUCCESS: {
      console.log(action);
      return {
        ...state,
        countryList: action.data,
        // currency: action.data,
        // currency_symbol: action.currency_symbol,
      };
    }
    case COUNTRIES_GET_FAILED: {
      console.log(action);
      return {
        ...state,
        // currency: action.data,
        // currency_symbol: action.currency_symbol,
      };
    }
    default:
      return state;
  }
}
export const countries = (state) => state.countries;

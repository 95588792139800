export const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 120,
      minimum: 2,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 120,
      minimum: 2,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 16,
      minimum: 8,
    },
    format: {
      pattern: "^[a-zA-Z0-9]+$",
    },
  },
  confirmPassword: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 16,
      minimum: 8,
    },
    format: {
      pattern: "^[a-zA-Z0-9]+$",
    },
    equality: "password",
  },
};

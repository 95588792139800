import { useEffect, useRef } from "react";

import "./App.css";
import { Helmet } from "react-helmet";
import IdleTimer from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import NavigationPanel from "./components/NavigationPanel/NavigationPanel";
import "./assets/css/index.css";
import GlobalStyle from "./globalStyles";
import {
  getImagesSite,
  getLoading,
  getLoginUserInfo,
  getStylesSite,
  getEmailRegister,
  getGroupsPlans
} from "./helpers/Selector";
import { getAccountInfo } from "./redux/actions/Account";
import { logoutUser, refreshToken } from "./redux/actions/Auth";
import { getImages, getStyles } from "./redux/actions/Configure";
import { countriesGet } from "./redux/actions/Countries";
import { currencyGet } from "./redux/actions/Currency";
import { getGroups } from "./redux/actions/Plan";
import RouteContainer from "./route";
import LoaderComponent from "./shared/LoaderComponent/LoaderComponent";
import NotificationContainer from "./shared/Notification/Notification";

const Main = () => {
  const isLoading = useSelector(getLoading);
  const loginUserInfo = useSelector(getLoginUserInfo);
  const emailRegister = useSelector(getEmailRegister);
  const images = useSelector(getImagesSite);
  const styleSite = useSelector(getStylesSite);
  const dispatch = useDispatch();
  const history = useHistory();
  const idleTimerRef = useRef(null);
  const groups = useSelector(getGroupsPlans)

  useEffect(() => {
    dispatch(currencyGet());
    dispatch(countriesGet());
  }, []);

  useEffect(() => {
    if (loginUserInfo.userid) {
      let interval = setInterval(
        () => dispatch(refreshToken(loginUserInfo.session_id, history)),
        8 * 60 * 1000
      );
      return () => {
        clearInterval(interval);
      };
    }
  });

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getImages());
    dispatch(getStyles());
  }, []);
  useEffect(() => {
    if (
      !loginUserInfo.session_id &&
      !history.location.pathname.includes("/checkout") &&
      !history.location.pathname.includes("/sign-in") &&
      localStorage.getItem("sessionId") &&
      !history.location.pathname.includes("/config")
    ) {
      dispatch(getAccountInfo());
    }
  }, [
    history.location.pathname,
    localStorage.getItem("sessionId"),
    loginUserInfo.session_id,
  ]);

  if (
    !loginUserInfo.session_id &&
    !localStorage.getItem("sessionId") &&
    history.location.pathname.includes("/account/")
  ) {
    history.push("/sign-in");
  }
  if (
    (history.location.pathname.includes("/checkout") &&
      localStorage.getItem("sessionId") &&
      !loginUserInfo.session_id) ||
    (!history.location.pathname.includes("/checkout/account") &&
      history.location.pathname.includes("/checkout") &&
      !localStorage.getItem("sessionId") &&
      !loginUserInfo.session_id &&
      !emailRegister)
  ) {
    history.push(`/choose-plan/${groups[0].group_no}`);
  }

  const onIdle = () => {
    if (loginUserInfo.userid) {
      dispatch(logoutUser(loginUserInfo.session_id, history));
    }
  };

  return (
    <IdleTimer ref={idleTimerRef} timeout={8 * 60 * 1000} onIdle={onIdle}>
      {styleSite && styleSite.font_color ? (
        <>
          <GlobalStyle styles={styleSite} images={images} />
          <Helmet>
            <title>{styleSite.site_title || "Aria Self Service (Demo)"} </title>
          </Helmet>
          <div className="wrap-main-main">
            <NavigationPanel />
            <main className="main-main">
              <NotificationContainer />
              <RouteContainer />
            </main>
            {isLoading ? <LoaderComponent /> : null}
          </div>
        </>
      ) : (
        <LoaderComponent />
      )}
    </IdleTimer>
  );
};

export default Main;

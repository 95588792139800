import { ACTIVATE_PROFILE_USER, CLEAR_STATUSES, LOGIN_USER, LOGOUT_USER, PREREGISTER_USER, REFRESH_TOKEN } from "../actionNames/Auth";

export function loginUser(data, history, checkout) {
  return { type: LOGIN_USER, data, history, checkout };
}

export function logoutUser(data, history) {
  return { type: LOGOUT_USER, data, history };
}

export function preRegisterUser(data, history, checkout) {
  return { type: PREREGISTER_USER, data, history, checkout };
}

export function activateProfileUser(data) {
  return { type: ACTIVATE_PROFILE_USER, data };
}

export function clearStatuses(data) {
  return { type: CLEAR_STATUSES, data };
}

export function refreshToken(data, history) {
  return { type: REFRESH_TOKEN, data, history };
}

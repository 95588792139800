export const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
    },
    format: {
      pattern: /^[a-z ,.'-]+$/i,
    },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
    },
    format: {
      pattern: /^[a-z ,.'-]+$/i,
    },
  },
  streetAddress: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 1,
    },
    // format: {
    //   pattern: "[w',-\\/.s]",
    // },
  },
  addressSecond: {
    // presence: { allowEmpty: false, message: "is required" },
    // length: {
    //   minimum: 10,
    // },
  },

  city: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
    },
    format: {
      pattern: "^[a-zA-Z]+(?:(?:\\s+|-)[a-zA-Z]+)*$",
    },
  },
  zipCode: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 5,
    },
    format: {
      pattern: "^[0-9]{5}(?:-[0-9]{4})?$",
    },
  },
  stateProvince: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 2,
    },
    // format: {
    //   pattern: "[w',-\\/.s]",
    // },
  },
  phoneNumber: {
    // presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 9,
    },
    format: {
      pattern: /^[0-9\-\+]{9,15}$/,
    },
  },
};

import { useEffect, useMemo, useState } from "react";

import "./CartDetails.css";
import { Button } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  getItemsInCart,
  getLoginUserInfo,
  getTotalSum,
  getTotalTaxes,
  getCurrencySymbol,
  getPreRegisterUser,
} from "../../../helpers/Selector";
import imgPlan from "../../../images/planBox.png";
import CartItem from "../CartItem/CartItem";

const CartDetails = ({ onClose, checkout, menuCheckout }) => {
  const loginUserInfo = useSelector(getLoginUserInfo);
  const preRegisterUser = useSelector(getPreRegisterUser);
  const itemsInCart = useSelector(getItemsInCart);
  const totalSum = useSelector(getTotalSum);
  const totalTaxes = useSelector(getTotalTaxes);
  const currencySymbol = useSelector(getCurrencySymbol);
  const history = useHistory();

  const [isPlanHere, setIsPlanHere] = useState(false);

  useEffect(() => {
    if (itemsInCart) {
      let plan = itemsInCart.find((item) => item.schedule_no);
      if (plan) {
        setIsPlanHere(true);
      } else {
        setIsPlanHere(false);
      }
    }
  }, [itemsInCart]);

  const handleClose = (event) => {
    if (loginUserInfo.session_id || preRegisterUser.email) {
      history.push("/checkout/shipping-address");
      onClose(event);
    } else {
      history.push("/checkout/account");
      onClose(event);
    }
  };

  const img = {
    src: imgPlan,
    srcSet: imgPlan,
  };

  const isReview = useMemo(() => {
    return history.location.pathname.includes("/checkout/review");
  }, [history.location.pathname]);

  return (
    <div className={checkout ? "wrap-content-cart-checkout" : "wrap-content-cart"}>
      <div>
        <div className="title-cart">
          <div>{isReview ? "Order Summary" : "Shopping Cart"}</div>
          {checkout ? null : (
            <div>
              <CloseOutlinedIcon fontSize="large" onClick={onClose} />
            </div>
          )}
          {menuCheckout ? (
            <div>
              <CloseOutlinedIcon fontSize="large" onClick={onClose} />
            </div>
          ) : null}
        </div>
        <div>
          {itemsInCart.map((item, idx) => (
            <CartItem
              image={item.schedule_no ? img : item.image}
              title={item.title}
              price={item.price}
              quantity={item.quantity}
              isCheckout={checkout}
              item={item}
              key={item.price + item.title + idx}
              description={item.schedule_name}
              currencySymbol={currencySymbol}
              taxes={item.taxes}
            />
          ))}
          {!itemsInCart.length ? <div className="empty-cart-text">You do not have any plans or products in your cart</div> : null}
        </div>
      </div>
      <div className="wrap-button-checkout">
        <div className="total-taxes">
          <div>Total Tax</div>
          <div>
            {" "}
            {currencySymbol}
            {parseFloat(totalTaxes).toFixed(2)}
          </div>
        </div>
        <div className="total-sum">
          <div>{isReview ? "Total Payment" : "Estimated Total"}</div>
          <div>
            {" "}
            {currencySymbol}
            {parseFloat(totalSum).toFixed(2)}
          </div>
        </div>
        {checkout ? null : (
          <Button
            variant="contained"
            color="primary"
            disabled={!!!itemsInCart.length || !isPlanHere}
            className="button-checkout"
            onClick={(event) => handleClose(event)}
          >
            checkout
          </Button>
        )}
      </div>
    </div>
  );
};

export default CartDetails;

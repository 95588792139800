import { Switch, Route, Redirect } from "react-router-dom";

import Account from "./components/Account/Account";
import AccountSettings from "./components/Account/AccountSettings/AccountSettings";
import BillingInvoice from "./components/Account/BillingInvoice/BillingInvoice";
import ChangePlanAccount from "./components/Account/ChangePlan/ChangePlan";
import Email from "./components/Account/Email/Email";
import General from "./components/Account/General/General";
import PaymentAdd from "./components/Account/Payment/PaymentAdd/PaymentAdd";
import PaymentData from "./components/Account/Payment/PaymentData/PaymentData";
import PaymentEdit from "./components/Account/Payment/PaymentEdit/PaymentEdit";
import PaymentInfo from "./components/Account/Payment/PaymentInfo/PaymentInfo";
import Security from "./components/Account/Security/Security";
import PasswordResetSimple from "./components/Auth/PasswordReset/PasswordResetSimple";
import SigninSimple from "./components/Auth/Signin/SigninSimple";
import SignupSimple from "./components/Auth/Signup/SignupSimple";
import CategoriesPlan from "./components/ChangePlan/CategoriesPlan/CategoriesPlan";
import ChangePlan from "./components/ChangePlan/ChangePlan";
import StepperCheckout from "./components/Checkout/StepperCheckout/StepperCheckout";
import Configure from "./components/Configure/Configure";
import Plans from "./components/Plans/Plans";
import Products from "./components/Products/Products";

const RouteContainer = () => {
  return (
    <Switch>
      <Route path="/sign-in" component={SigninSimple} />
      <Route path="/sign-up" component={SignupSimple} />
      <Route path="/password-reset" component={PasswordResetSimple} />
      <Route path="/account/:path?" exact>
        <Account>
          <Switch>
            <Route path="/account/settings" component={AccountSettings} />
            <Route path="/account/general" component={General} />
            <Route path="/account/email" component={Email} />
            <Route path="/account/security" component={Security} />
            <Route path="/account/paymentInfo" component={PaymentInfo} />
            <Route path="/account/paymentAdd" component={PaymentAdd} />
            <Route path="/account/paymentData" component={PaymentData} />
            <Route path="/account/paymentEdit" component={PaymentEdit} />
            <Route path="/account/billing-invoice" component={BillingInvoice} />
            <Route path="/account/change-plan" component={ChangePlanAccount} />
          </Switch>
        </Account>
      </Route>
      {/* <Route path="/products" component={Products} /> */}
      <Route path="/plans" component={Plans} />
      <Route path="/choose-plan/:path/:id" component={CategoriesPlan} />
      <Route path="/choose-plan/:path" component={ChangePlan} />
      <Route path="/checkout/:path" exact component={StepperCheckout} />

      <Route path="/config" component={Configure} />

      <Route path="/">
        <Redirect to="/account/settings" />
      </Route>
    </Switch>
  );
};
export default RouteContainer;

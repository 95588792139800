import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { logoutUser } from "../../redux/actions/Auth";

const Logout = ({ sessionId, handleClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutUser(sessionId.sessionId, history));
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Button color="primary" size="large" onClick={handleLogout}>
      logout
    </Button>
  );
};
export default Logout;

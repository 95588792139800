import {
  CANCEL_ACCOUNT_PLAN,
  CHANGE_ACCOUNT_PLAN,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  CHANGE_PERSONAL_INFO,
  CLOSE_MODAL,
  GET_ACCOUNT_INFO,
  GET_EVENT_LOGS,
  GET_RATES,
} from "../actionNames/Account";

export function getAccountInfo(data, history) {
  return { type: GET_ACCOUNT_INFO, data, history };
}
export function changeEmail(data) {
  return { type: CHANGE_EMAIL, data };
}
export function changePassword(data) {
  return { type: CHANGE_PASSWORD, data };
}
export function changePersonalInfo(data) {
  return { type: CHANGE_PERSONAL_INFO, data };
}
export function changeAccountPlan(data) {
  return { type: CHANGE_ACCOUNT_PLAN, data };
}
export function cancelAccountPlan(data) {
  return { type: CANCEL_ACCOUNT_PLAN, data };
}
export function closeModal(data) {
  return { type: CLOSE_MODAL, data };
}
export function getRates(
  data,
  currency,
  supplementalIndex,
  instanceNo,
  currentRate,
  history
) {
  return {
    type: GET_RATES,
    data,
    currency,
    supplementalIndex,
    instanceNo,
    currentRate,
    history,
  };
}
export function getEventLogs(data) {
  return { type: GET_EVENT_LOGS, data };
}

import { CLEAR_FILTER_PLAN_GROUPS, FILTER_PLAN_GROUPS, GET_ALL_PLANS, GET_GROUPS, GET_SUPPLEMENTAL_PLANS } from "../actionNames/Plan";

export function filterPlanGroups(data) {
  return { type: FILTER_PLAN_GROUPS, data };
}

export function clearFilterPlanGroups(data) {
  return { type: CLEAR_FILTER_PLAN_GROUPS, data };
}

export function getAllPlans(data) {
  return { type: GET_ALL_PLANS, data };
}

export function getGroups(data) {
  return { type: GET_GROUPS, data };
}

export function getSupplementalPlans(data) {
  return { type: GET_SUPPLEMENTAL_PLANS, data };
}
